import { Injectable } from '@angular/core';

const TOKEN_KEY = 'auth-token';
const CUSTOMER_KEY = 'customer';
const CUSTOMERNAME_KEY = 'customer_name';
const USER_KEY = 'auth-user';
const DIFF_KEY = 'onlyDiff';
const FILTERS_KEY = 'filters';

const SHOWZERO_KEY = 'showZero';
const SHOWEPC_KEY = 'showEpc';
const C_KEY = 'c';
const PD_KEY = 'pd';
const POS_KEY = 'pos';
const POS_SHAREDTICKET = 'sharedTicketId';
const POS_PAYMENTSTATUS = 'pos_paystatus';
const POS_MONEYMAP = 'pos_moneymap';
const POS_BROTHERHOOD = 'pos_brotherhood';
const POS_BROTHERHOODID = 'pos_brotherhood_id';

const DFTPRINTER_KEY = 'defaultPrinter';
const DFTPRINTERDEP_KEY = 'defaultPrinterDep';
const DFTTEMPLATE_KEY = 'defaultTemplate';

const QS_KEY = 'queryString';
const TFN_KEY = 'taskFilterNames';
const TFP_KEY = 'taskFilterParams';

const GRID_KEY = 'grid';

@Injectable({
  providedIn: 'root',
})
export class TokenStorageService {
  constructor() {}

  signOut(): void {
    window.sessionStorage.clear();
  }

  public saveToken(token: string): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public saveUser(user: any): void {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public saveCustomer(customer: string): void {
    window.sessionStorage.removeItem(CUSTOMER_KEY);
    window.sessionStorage.setItem(CUSTOMER_KEY, customer);
  }

  public saveCustomerName(customerName: string): void {
    window.sessionStorage.removeItem(CUSTOMERNAME_KEY);
    window.sessionStorage.setItem(CUSTOMERNAME_KEY, customerName);
  }

  public saveOnlyDiff(onlyDiff: boolean): void {
    window.sessionStorage.removeItem(DIFF_KEY);
    window.sessionStorage.setItem(DIFF_KEY, '' + onlyDiff);
  }
  public saveFilters(filters: boolean): void {
    window.sessionStorage.removeItem(FILTERS_KEY);
    window.sessionStorage.setItem(FILTERS_KEY, '' + filters);
  }

  public saveShowZero(showZero: boolean): void {
    window.sessionStorage.removeItem(SHOWZERO_KEY);
    window.sessionStorage.setItem(SHOWZERO_KEY, '' + showZero);
  }

  public saveDefaultPrinter(defaultPrinter: string): void {
    window.sessionStorage.removeItem(DFTPRINTER_KEY);
    window.sessionStorage.setItem(DFTPRINTER_KEY, defaultPrinter);
  }

  public saveDefaultPrinterDep(defaultPrinterDep: string): void {
    window.sessionStorage.removeItem(DFTPRINTERDEP_KEY);
    window.sessionStorage.setItem(DFTPRINTERDEP_KEY, defaultPrinterDep);
  }

  public saveDefaultTemplate(defaultTemplate: string): void {
    window.sessionStorage.removeItem(DFTTEMPLATE_KEY);
    window.sessionStorage.setItem(DFTTEMPLATE_KEY, defaultTemplate);
  }

  public saveShowEpc(showEpc: boolean): void {
    window.sessionStorage.removeItem(SHOWEPC_KEY);
    window.sessionStorage.setItem(SHOWEPC_KEY, '' + showEpc);
  }

  public saveC(c: any): void {
    window.sessionStorage.removeItem(C_KEY);
    window.sessionStorage.setItem(C_KEY, JSON.stringify(c));
  }

  public savePosDepartment(dep: any): void {
    window.sessionStorage.removeItem(PD_KEY);
    window.sessionStorage.setItem(PD_KEY, JSON.stringify(dep));
  }

  public savePointOfSale(pos: any): void {
    window.sessionStorage.removeItem(POS_KEY);
    window.sessionStorage.setItem(POS_KEY, JSON.stringify(pos));
  }
  public saveSharedTicketId(sharedTicketId: string): void {
    window.sessionStorage.removeItem(POS_SHAREDTICKET);
    window.sessionStorage.setItem(POS_SHAREDTICKET, sharedTicketId);
  }
  public savePayStatus(sharedTicketId: string, data) {
    console.log(data);
    var temp = JSON.parse(window.localStorage.getItem(POS_PAYMENTSTATUS));
    if (temp == undefined) {
      var dataNew = {};
      dataNew[sharedTicketId] = data;
      window.localStorage.setItem(POS_PAYMENTSTATUS, JSON.stringify(dataNew));
    } else {
      temp[sharedTicketId] = data;
      window.localStorage.removeItem(POS_PAYMENTSTATUS);
      window.localStorage.setItem(POS_PAYMENTSTATUS, JSON.stringify(temp));
    }
  }
  public saveBrotherHood(brotherhood: any) {
    var email = brotherhood.email;
    var bh = {};
    bh[email] = {
      coupon: brotherhood.coupon,
    };
    window.localStorage.removeItem(POS_BROTHERHOOD);
    window.localStorage.setItem(POS_BROTHERHOOD, JSON.stringify(bh));
  }
  public saveBrotherHoodCouponSet(brotherhoodCoupon: any) {
    window.localStorage.removeItem(POS_BROTHERHOODID);
    window.localStorage.setItem(POS_BROTHERHOODID, JSON.stringify(brotherhoodCoupon));
  }
  public resetBrotherHoodCouponSet() {
    window.localStorage.removeItem(POS_BROTHERHOODID);
  }

  set moneyMap(mp) {
    window.sessionStorage.removeItem(POS_MONEYMAP);
    window.sessionStorage.setItem(POS_MONEYMAP, JSON.stringify(mp));
  }

  public saveTaskFilters(queryString: string): void {
    window.localStorage.removeItem(QS_KEY);
    window.localStorage.setItem(QS_KEY, queryString);
  }

  public getTaskFilters(): string | null {
    return window.localStorage.getItem(QS_KEY);
  }

  public clearTaskFilters(): void {
    window.localStorage.removeItem(QS_KEY);
  }

  public saveTaskFilterNames(filterNames: any): void {
    window.localStorage.removeItem(TFN_KEY);
    window.localStorage.setItem(TFN_KEY, JSON.stringify(filterNames));
  }

  public getTaskFilterNames(): any {
    return JSON.parse(window.localStorage.getItem(TFN_KEY));
  }

  public clearTaskFilterNames(): void {
    window.localStorage.removeItem(TFN_KEY);
  }

  public saveTaskFilterParams(filterParams: any): void {
    window.localStorage.removeItem(TFP_KEY);
    window.localStorage.setItem(TFP_KEY, JSON.stringify(filterParams));
  }

  public getTaskFilterParams(): any {
    return JSON.parse(window.localStorage.getItem(TFP_KEY));
  }

  public clearTaskFilterParams(): void {
    window.localStorage.removeItem(TFP_KEY);
  }

  public getToken(): string | null {
    return window.sessionStorage.getItem(TOKEN_KEY);
  }

  public getUser(): any {
    const user = window.sessionStorage.getItem(USER_KEY);
    if (user) {
      return JSON.parse(user);
    }

    return {};
  }
  public getCustomer(): string {
    return window.sessionStorage.getItem(CUSTOMER_KEY);
  }
  public getCustomerName(): string {
    return window.sessionStorage.getItem(CUSTOMERNAME_KEY);
  }

  public getOnlyDiff(): string | null {
    return window.sessionStorage.getItem(DIFF_KEY);
  }

  public getFilters(): string | null {
    return window.sessionStorage.getItem(FILTERS_KEY);
  }

  public getShowZero(): string | null {
    return window.sessionStorage.getItem(SHOWZERO_KEY);
  }

  public getDefaultPrinter(): string | null {
    return window.sessionStorage.getItem(DFTPRINTER_KEY);
  }

  public getDefaultPrinterDep(): string | null {
    return window.sessionStorage.getItem(DFTPRINTERDEP_KEY);
  }

  public getDefaultTemplate(): string | null {
    return window.sessionStorage.getItem(DFTTEMPLATE_KEY);
  }

  public getShowEpc(): string | null {
    return window.sessionStorage.getItem(SHOWEPC_KEY);
  }

  public getC(): any {
    return JSON.parse(window.sessionStorage.getItem(C_KEY));
  }

  public getPosDepartment(): any {
    return JSON.parse(window.sessionStorage.getItem(PD_KEY));
  }
  public getPointOfSale(): any {
    return JSON.parse(window.sessionStorage.getItem(POS_KEY));
  }
  public getSharedTicketId(): any {
    return window.sessionStorage.getItem(POS_SHAREDTICKET);
  }
  public getPayStatus(sharedTicketId: string) {
    const temp = JSON.parse(window.localStorage.getItem(POS_PAYMENTSTATUS));

    if (temp == undefined) {
      this.savePayStatus(sharedTicketId, []);
      return [];
    }
    if (temp[sharedTicketId] == undefined) {
      this.savePayStatus(sharedTicketId, []);
      return [];
    }
    return temp[sharedTicketId];
  }
  public getBrotherHoodCoupon(clientEmailId: string) {
    const temp = JSON.parse(window.localStorage.getItem(POS_BROTHERHOOD));
    if (!temp) return null;
    return temp[clientEmailId] || null;
  }
  public getBrotherHoodCouponSet() {
    const temp = JSON.parse(window.localStorage.getItem(POS_BROTHERHOODID));
    if (!temp) return null;
    return temp;
  }
  get moneyMap() {
    const mp = window.sessionStorage.getItem(POS_MONEYMAP);
    if (mp) {
      return JSON.parse(mp);
    }
    return [];
  }

  public clearPos() {
    window.sessionStorage.removeItem(POS_KEY);
    window.sessionStorage.removeItem(POS_SHAREDTICKET);
    window.sessionStorage.removeItem(PD_KEY);
  }

  public saveGrid(grid: boolean): void {
    window.sessionStorage.removeItem(GRID_KEY);
    window.sessionStorage.setItem(GRID_KEY, String(grid));
  }

  public getGrid(): boolean | null {
    return JSON.parse(window.sessionStorage.getItem(GRID_KEY));
  }
}
