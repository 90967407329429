export {};

declare global {
  interface String {
    toNumber(): number;
    toCurrency(): string;
    toCurrencySymbol(): string;
    stringify(): string;
    toNumberMoney(): number;
    toInt(): number;
    checkEAN13(): boolean;
    checkEPC(): boolean;
    partialEan13(): boolean;
    partialEPC(): boolean;
    addSpaceEveryXChars(x: number): string;
  }
}

String.prototype.toNumber = function (): number {
  var amountString = this.replace(/[^\d.,-]/g, '');
  const itCurrency = /^(-)?\d{1,3}(.\d{3})*(,\d{1,2})?$/g; // 100.000.000,99
  const it2Currency = /^(-)?\d+(\,\d{1,2})?$/; // 100000000,99
  const enCurrency = /^(-)?\d+(\.\d{1,2})?$/; // 100000000.99
  if (itCurrency.test(amountString) || it2Currency.test(amountString)) {
    amountString = amountString.replace(/[.]/g, '');
  }
  var parsedAmount = parseFloat(amountString.replace(/[,]/g, '.'));
  return isNaN(parsedAmount) ? 0 : parsedAmount;
};
String.prototype.toNumberMoney = function (): number {
  return this.toNumber().toNumberMoney();
};
String.prototype.toCurrency = function (data: { lang: string; currency: string } | any = {}): string {
  return this.toNumber().toCurrency(data);
};
String.prototype.toCurrencySymbol = function (data: { lang: string; currency: string } | any = {}): string {
  return this.toNumber().toCurrencySymbol(data);
};
String.prototype.stringify = function () {
  return this;
};
String.prototype.toInt = function () {
  return this.toNumber().toInt();
};
String.prototype.checkEAN13 = function (): boolean {
  // Verifica il formato con la regex
  const regex = /^\d{13}$/;
  if (!regex.test(this)) {
    return false;
  }

  // // Calcola il checksum
  // let sum = 0;
  // for (let i = 0; i < 12; i++) {
  //   const digit = parseInt(this[i], 10);
  //   sum += i % 2 === 0 ? digit : digit * 3;
  // }
  // const checksum = (10 - (sum % 10)) % 10;

  // // Verifica se la cifra di controllo corrisponde
  // return checksum === parseInt(this[12], 10);
  return true;
};
String.prototype.checkEPC = function (split: string = ","): boolean {
  var splitPart = this.split(split);
  var epc = splitPart[0];
  if(splitPart == 2){
    // Se il formato è EAN[split]EPC
    if(!splitPart[0].checkEAN13()) // Verifico prima l'EAN
      return false;
    epc = splitPart[1];
  }
  // Verifica il formato con la regex
  const regex = /^[0-9A-Fa-f]{24}$/;
  return regex.test(epc);
};
String.prototype.partialEan13 = function(): boolean{
  const regex = /^\d{1,13}$/;
  if (!regex.test(this)) {
    return false;
  }
}
String.prototype.partialEPC = function(separatore = ',') {
  // Divide la stringa in due parti usando il separatore
  const parts = this.split(separatore);

  // Caso 1: Stringa ha sia EAN che EPC
  if (parts.length === 2) {
      const ean = parts[0];
      const epc = parts[1];

      // Verifica che l'EAN sia di 13 cifre
      const eanRegex = /^\d{13}$/;
      if (!eanRegex.test(ean)) {
          return false;
      }

      // Verifica che l'EPC sia composto da 1 a 24 caratteri esadecimali
      const epcRegex = /^[0-9A-Fa-f]{0,24}$/;
      return epcRegex.test(epc);
  }
  
  // Caso 2: Stringa contiene solo l'EAN
  if (parts.length === 1) {
      const ean = parts[0];

      // Verifica che l'intera stringa sia composta da 1 a 24 caratteri esadecimali
      const epcRegex = /^[0-9]{1,13}$/;
      return epcRegex.test(ean);
  }

  // Se la stringa non corrisponde a nessuno dei formati sopra, non è valida
  return false;
};
String.prototype.addSpaceEveryXChars = function(x: number) {
  const regex = new RegExp(`(.{${x}})`, 'g');
  return this.replace(regex, "$1 ");
}