
<div style="background-color: #fff;">
    <div class="modal-header">
        <h4 class="modal-title pull-left">Server Config</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">

        <div class="row" #container>

          <qrcode [qrdata]="sConfig" [width]="container.offsetWidth-25" [errorCorrectionLevel]="'M'"></qrcode>

        </div>

    </div>

  </div>
