import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

/**
 * data | momentPipe : "l"
 */
@Pipe({
  name: 'momentPipe',
})
export class MomentPipe implements PipeTransform {
  constructor(private translate: TranslateService,){}
  transform(value: string | Date | moment.Moment, format: string, specific: string): any {
    if(format == undefined)
      format = 'lll';
    else if(format === 'specific'){
      return(this.translate.instant("MOMENT." + specific).substring(0, 6) === 'MOMENT')
      ?moment(value).format('lll')
      :moment(value).format(this.translate.instant("MOMENT." + specific));
    }
    return moment(value).format(format);
  }
}
