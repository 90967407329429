import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-okta-logout',
    template: `

<ng-container *ngIf="authenticated" >
      <a (click)="auth.logout({ logoutParams: { returnTo: document.location.origin } })" dropdownItem >
          <div class="d-flex align-items-center" >
              <i class="font-size-lg ms-2 feather icon-power"></i> <span>{{'NAV.SIGNOUT'|translate}} OKTA</span>
          </div>
      </a>
</ng-container>
    `,
})
export class OktaLogoutComponent {

  authenticated:boolean;

  constructor(@Inject(DOCUMENT) public document: Document,
  public auth: AuthService, private ref: ChangeDetectorRef) {}

  async ngOnInit(): Promise<void> {

    await this.auth.isAuthenticated$.subscribe (ret =>{
      console.log(ret)
      this.authenticated = ret;
      this.ref.detectChanges();
    })
   }

}
