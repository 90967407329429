<dropdown dropdownClass="header-nav-item-select nav-profile" (click)="navProfile.toggle($event)">
    <div class="toggle-wrapper d-flex align-items-center">
        <avatar class="bg-danger" [size]="35" [text]="user.name.substring(0,1)"></avatar>
        <span class="fw-bold mx-1">{{user.name}}</span>
        <i class="feather icon-chevron-down"></i>
    </div>
    <dropdown-menu #navProfile alignment="right">
        <div class="nav-profile-header">
            <div class="d-flex align-items-center">
              <avatar class="bg-danger" [size]="35" [text]="user.name.substring(0,1)"></avatar>
                <div class="d-flex flex-column ms-1">
                    <span class="fw-bold text-dark">{{user.name}}</span>
                    <span class="font-size-sm">{{user.email}}</span>
                </div>
            </div>
        </div>
        <a href="/profile" dropdownItem >
          <div class="d-flex align-items-center" >
              <i class="font-size-lg ms-2 feather feather icon-user"></i> <span>{{'NAV.PROFILE'|translate}}</span>
          </div>
      </a>
        <a (click)="logout()" dropdownItem >
            <div class="d-flex align-items-center" >
                <i class="font-size-lg ms-2 feather icon-power"></i> <span>{{'NAV.SIGNOUT'|translate}}</span>
            </div>
        </a>


        <!--
        <a *ngIf="auth.user$ | async as user">
          <div class="d-flex align-items-center" >{{ user.name }} {{ user.email }}</div>
        </a>

        <app-okta-logout></app-okta-logout>
        -->

    </dropdown-menu>
</dropdown>



