import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[validAddress]',
  providers: [{ provide: NG_VALIDATORS, useExisting: ValidAddressDirective, multi: true }]
})
export class ValidAddressDirective implements Validator {
  @Input('validAddress') address: string;

  validate(control: AbstractControl): ValidationErrors | null {
    console.log(this.address)
    return this.address && this.address.trim() !== '' ? null : { 'invalidAddress': true };
  }
}
