import { Component, Input, OnInit } from '@angular/core';
import { PrinterTemplateRowConfig } from '@app/views/pos/detail/printerTemplate/printerTemplate-line.component';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';


@Component({
  selector: 'ticket-preview',
  templateUrl: './ticket-preview.component.html',
  styleUrls: ['./ticket-preview.component.scss'],
})
export class TicketPreviewComponent implements OnInit {
  @Input() fiscalCode;
  @Input() fiscalPrinter;
  @Input() line;
  @Input() paymentMethod;
  @Input() header: PrinterTemplateRowConfig[];
  @Input() footer: PrinterTemplateRowConfig[];
  @Input() status: string;
  @Input() date: Date;
  @Input() number: string;
  @Input() cashback
  @Input() length: number = 46;
  extra: any;
  lineLength

  total:number = 0;
  vat: number = 0;
  paymentMethodGroup = [];
  
  lang: string;

  constructor(private translate: TranslateService){}
  ngOnInit(): void {
    this.lang = this.translate.currentLang;
  }

  get ticketType(){
    switch (this.status) {
      case "S":
          return 'SALE';
      case "R":
          return 'RETURN';
      case "C":
      case "A":
          return 'CANCEL';
      case "G":
          return 'GIFT';
    }
  }
}

@Component({
  selector: 'ticket-preview-line',
  templateUrl: './ticket-preview-line.component.html',
  styleUrls: ['./ticket-preview.component.scss'],
})
export class TicketPreviewLineComponent implements OnInit {
  @Input() extra;
  @Input() item;
  @Input() length;

  line: any = {
    method: 'text',
    valueL: '',
    valueR: ''
  };

  ngOnInit(): void {
    console.log(this);
    let text = this.analisiText(this.item.text);
    if(this.line.method == 'text'){
      this.line.valueL = text;
      this.line.valueR = this.analisiText(this.item.textRight);
    }
  }
  

  customMethod(method, value){
    switch(method){
      case "DATE":
        return moment(this.extra[value]).format('l');
      case "EUR":
        return this.extra[value].toNumberMoney().toCurrencySymbol()
      case "SPLITLINE":
        console.log(this.length);
        console.log(value, method);
        console.log(value.repeat(this.length));
        return value.repeat(this.length);
    }
  }
  analisiText(text){
    var line = text;
    let match;
    const regex: RegExp = /\{\{([^}]+)\}\}/g;
    while ((match = regex.exec(text)) !== null) {
      const content = match[1];
      // Dividi il contenuto rispetto al carattere |
      const parts = content.split('|');
      var temp: string;
      if (parts.length === 2) {
          const firstPart = parts[0].trim();
          const secondPart = parts[1].trim();
          if(firstPart == 'BAR_CODE13'){
            this.line = {
              method: 'BAR_CODE13',
              valueL: this.extra[secondPart]
            };
            return;
          }
          else
            temp = this.customMethod(firstPart, secondPart);
      } else
        temp = this.extra[content];
      line = line.replace('{{'+content+'}}', temp);
    }
    
    return line;
  }



}

@Component({
  selector: 'ticket-preview-it',
  templateUrl: './ticket-preview-it.component.html',
  styleUrls: ['./ticket-preview.component.scss'],
})
export class TicketPreviewITComponent implements OnInit {
  @Input() ticketType;
  @Input() line;
  @Input() paymentMethod;
  @Input() fiscalCode;
  @Input() fiscalPrinter;
  @Input() date;
  @Input() cashback;

  giftRefund = null;
  
  total = 0;
  vat = 0;
  paymentMethodGroup = [];

  ngOnInit(): void {
    for (let item of this.line) {
      this.total += item.discountPrice * 10000;
      this.vat += (item.discountPrice * 10000).getIvaFromTotal();
    }
    for (let item of this.paymentMethod) {
      var f = this.paymentMethodGroup.find((value) => value.group === item.paymentType.paymentGroup);
      if (f == undefined) {
        this.paymentMethodGroup.push({
          total: item.amount * (item.refund?-1:1),
          group: item.paymentType.paymentGroup,
        });
      } else {
        f.total += item.amount * (item.refund?-1:1);
      }
      if(item.refund && item.paymentType.paymentGroup == 'GIFT'){
        this.giftRefund = item;
      }
    }
  }
}




@Component({
  selector: 'gift-preview-it',
  templateUrl: './gift-preview-it.component.html',
  styleUrls: ['./ticket-preview.component.scss'],
})
export class GiftPreviewITComponent implements OnInit {
  ngOnInit(): void {
    
  }
}

// @Component({
//   selector: 'ticket-preview-en',
//   templateUrl: './ticket-preview-en.component.html',
//   styleUrls: ['./ticket-preview.component.scss'],
// })
// export class TicketPreviewENComponent{
//   @Input() ticketType;
//   @Input() line;
//   ngOnInit(): void {
//     console.log(this.line)
//   }
// }


export var TicketPreviewLangComponent = [
  TicketPreviewComponent,
  TicketPreviewLineComponent,
  TicketPreviewITComponent,
  GiftPreviewITComponent,
  // TicketPreviewENComponent
];