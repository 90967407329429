export const custom = {
    // customer node
    'DEMO': {
      // Translations Override
      'MODEL': 'MyModel',
      'PART': 'MyPart',
      'VARIANT': 'MyVariant',
      'GENDER': 'MyGender',
      'OUTLINE': 'MyOutline',
      'TARGET': 'MyTarget',
      'COLOUR': 'MyColour',
      'BRAND': 'MyBrand',
      'SEASON': 'MySeason',
      'MATERIAL': 'MyMaterial',
  },
  // customer node
  'TEST': {
      // Translations Override
      'MODEL': 'MyModel',
      'PART': 'MyPart',
      'VARIANT': 'MyVariant',
      'GENDER': 'MyGender',
      'OUTLINE': 'MyOutline',
      'TARGET': 'MyTarget',
      'COLOUR': 'MyColour',
      'BRAND': 'MyBrand',
      'SEASON': 'MySeason',
      'MATERIAL': 'MyMaterial',
  },
  'DEG': {
      // Translations Override
      'MODEL': 'Model',
      'PART': 'Part',
      'VARIANT': 'Variant',
      'GENDER': 'Gender Line',
      'OUTLINE': 'Merchelogical Class',
      'TARGET': 'Line',
      'COLOUR': 'Color',
      'BRAND': 'Brand',
      'SEASON': 'Season',
      'MATERIAL': 'Technical Class',
  }

}
