import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { MomentPipe } from "./pipe/momentPipe"
import { SvgIconComponent } from './components/svg-icon/app-svg-icon.component';
import { PasteListenerDirective } from './directive/paste-listner.directive';
import { SafeUrlPipe } from './pipe/safe-urlPipe';
import { TicketPreviewLangComponent } from './components/ticket-preview/ticket-preview.component';
import { NgxBarcode6Module } from 'ngx-barcode6';
import { AutocompleteComponent } from './components/google-places/google-places.component';
import { BrowserModule } from '@angular/platform-browser';
import { ValidAddressDirective } from './directive/valid-address.directive';

@NgModule({
    declarations: [
        MomentPipe,
        SvgIconComponent,
        PasteListenerDirective,
        ValidAddressDirective,
        SafeUrlPipe,
        TicketPreviewLangComponent,
        AutocompleteComponent
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        TranslateModule,
        MomentPipe,
        ValidAddressDirective,
        SvgIconComponent,
        PasteListenerDirective,
        TicketPreviewLangComponent,
        AutocompleteComponent
    ],
    imports: [
        FormsModule,
        CommonModule,
        HttpClientModule,
        NgxBarcode6Module
    ]
})

export class SharedModule { }
