import { Component, OnInit, ChangeDetectionStrategy, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalDirection } from '@app/shared/types/modal-direction';
import { TokenStorageService } from '@app/shared/auth/token-storage.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule} from "@angular/forms";


import { CustomerConfigService } from './nav-config.service';


@Component({
    selector: 'customer-config',
    templateUrl: './customer-config.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class.header-nav-item]': 'true'
    },
    providers: [
      CustomerConfigService
    ],
})
export class CustomerConfigComponent implements OnInit {
    modalRef: BsModalRef;

    user: any;
    customer: string;
    customerName: string;

    rows = [];
    showDetail: boolean = false;

    row:any;
    types = [
      { id: 'EMAIL', name: 'Email'},
      { id: 'FTP', name : 'FTP'},
      { id: 'SFTP', name : 'sFTP'},
      { id: 'REST', name : 'Rest Push'}
    ];

    intervals = [
      { id: 1, name: 'Every 1 Minute'},
      { id: 5, name: 'Every 5 Minutes'},
      { id: 10, name: 'Every 10 Minutes'},
      { id: 30, name: 'Every 30 Minutes'},
      { id: 60, name: 'Every 60 Minutes'},
      { id: 1440, name: 'Every Day'},
    ];
    files = [];

    selectedType: string;
    formGroup: FormGroup;



    showPort: boolean
    showUsername: boolean
    showPassword: boolean
    showName: boolean
    showFileType: boolean


    constructor(
      private modalService: BsModalService,
      public bsModalRef: BsModalRef,

      private tokenStorage: TokenStorageService,
      private router: Router,
      private ccService: CustomerConfigService,
      private ref: ChangeDetectorRef,
      private formBuilder: FormBuilder,

    ) {

    }
    ngOnInit () {

      this.user=this.tokenStorage.getUser();
      this.customer=this.tokenStorage.getCustomer();
      this.customerName=this.tokenStorage.getCustomerName();

      this.showDetail=false;


      this.fetch();

    }

    fetch() {

      this.ccService.getList(this.customer).subscribe(data => {
        //alert(JSON.stringify(data))

        this.rows = data;
        this.ref.detectChanges();
      },
      err => {
          alert(err.error.message);
          if(err.error.message=='Error: Unauthorized') {
              window.sessionStorage.clear();
              window.location.reload();
          }
      });

      this.ccService.getFileTypes(this.customer).subscribe(data => {
        //alert(JSON.stringify(data))

        this.files = data;
        this.ref.detectChanges();
      },
      err => {
          alert(err.error.message);
          if(err.error.message=='Error: Unauthorized') {
              window.sessionStorage.clear();
              window.location.reload();
          }
      });

    }

    onChange(id) {

      this.selectedType=id;


      if(id==='EMAIL') {
        this.showPort=false;
        this.showUsername=false;
        this.showPassword=false;
        this.showName=false;
        this.showFileType=true;



      }
      if(id==='FTP'||id==='SFTP') {
        this.showPort=true;
        this.showUsername=true;
        this.showPassword=true;
        this.showName=true;
        this.showFileType=true;


      }
      if(id==='REST') {
        this.showPort=true;
        this.showUsername=true;
        this.showPassword=true;
        this.showName=true;
        this.showFileType=false;


      }

    }

    getBadgeColor(status: boolean) {
      switch (status) {
          case true:
              return '#00c569';
          case false:
              return '#f46363'
          default:
              return '#edf4f9';
      }
    }

    add() {
      this.detail();

      this.formGroup = this.formBuilder.group({
        id: null,
        enabled: true,
        exportType: [null, [Validators.required]],
        address: [null, [Validators.required]],
        port: [null],
        username: [null],
        password: [null],
        name: [null],
        fileTypeId: [null],
        timeInterval: [null, [Validators.required]]
      });
    }

    edit(row:any) {


      this.detail();
      this.row = row;
      this.onChange(row.exportType);

      this.formGroup = this.formBuilder.group({
        id: [this.row.id],
        enabled: [this.row.enabled],
        exportType: [this.row.exportType, [Validators.required]],
        address: [this.row.address, [Validators.required]],
        port: [this.row.port],
        username: [this.row.username],
        password: [this.row.password],
        name: [this.row.name],
        fileTypeId: [this.row.fileTypeId],
        timeInterval: [this.row.timeInterval, [Validators.required]]
      });


      //alert(JSON.stringify(row));
    }

    save() {


      console.log(this.formGroup);



      const { id, exportType, address, port, username, password, name, fileTypeId, timeInterval, enabled} = this.formGroup.value;


      const config = {
        exportType: exportType,
        address: address,
        port: port,
        username: username,
        password: password,
        name: name,
        fileTypeId: fileTypeId,
        timeInterval: timeInterval,
        enabled: enabled,
        customerId: this.customer
      }



      if(id==null) {
        this.ccService.insert(config).subscribe(
          data => {
            //this.bsModalRef.hide();
            this.fetch();
            this.detail();
          },
          err => {

          }
        );
      }
      else {
        this.ccService.update(id,config).subscribe(
          data => {
            this.fetch();
            this.detail();
          },
          err => {

          }
        );
      }



    }

    detail() {


      this.showDetail= this.showDetail==false?true:false;

      // alert(this.showDetail);
    }


}
