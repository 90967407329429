import { Component, OnInit, ChangeDetectionStrategy, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalDirection } from '@app/shared/types/modal-direction';
import { TokenStorageService } from '@app/shared/auth/token-storage.service';
import { Router } from '@angular/router';

import { CustomerConfigComponent } from './customer-config.component';
import { ServerConfigComponent } from './server-config.component';
import { CustomerConfigService } from './nav-config.service';
import { PointOfSalesService } from '@app/views/pointOfSales/pointOfSales.service';
import { posUrl } from '@app/configs/app.config';

@Component({
    selector: 'nav-config',
    templateUrl: './nav-config.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class.header-nav-item]': 'true'
    },
    providers: [
      CustomerConfigService
    ],
})
export class NavConfigComponent implements OnInit {
    modalRef: BsModalRef;


    user: any;
    customer: string;
    customerName: string;
    c: any;
    /*
    rows = [];
    showDetail: string;
    */


    constructor(
      private modalService: BsModalService,
      private tokenStorage: TokenStorageService,
      private router: Router,
      private ccService: CustomerConfigService,
      private pService: PointOfSalesService

      ) {

      }

    ngOnInit () {

      console.log(this.router.url);
      this.user=this.tokenStorage.getUser();
      this.customer=this.tokenStorage.getCustomer();
      this.customerName=this.tokenStorage.getCustomerName();
      this.c=this.tokenStorage.getC();

    }

    customMenuView(pageLink: string): boolean{
      switch(this.router.url){
        case posUrl:
          return ['superset'].includes(pageLink);
        default:
          return true;
      }
    }

    /*
    fetch() {

      this.ccService.getList(this.customer).subscribe(data => {
        alert(JSON.stringify(data))

        this.rows = data;
      },
      err => {
          alert(err.error.message);
          if(err.error.message=='Error: Unauthorized') {
              window.sessionStorage.clear();
              window.location.reload();
          }
      });

    }
    */

    openQuickPanel() {
        this.modalRef = this.modalService.show( CustomerConfigComponent, Object.assign({}, { class: `${ModalDirection.Right}` }));

        //this.showDetail='false';
        //this.fetch();
    }

    openQRcode() {

      this.modalRef = this.modalService.show(ServerConfigComponent,  {class: 'modal-sm'});

      //this.showDetail='false';
      //this.fetch();
  }

    selectCustomer() {

      this.router.navigate(['/select-customer']);
    }

    security() {
      this.router.navigate(['/eas-events']);
    }

    bi() {

      this.router.navigate(['/bi']);
    }

    superset() {

      this.router.navigate(['/superset']);
    }

    pos() {
      if(this.tokenStorage.getUser().roles[0]==='ROLE_USER') {
        if(this.tokenStorage.getUser().departments.length===1) {
          this.tokenStorage.savePosDepartment(this.tokenStorage.getUser().departments[0])

          this.pService.getList(this.tokenStorage.getUser().departments[0].id).subscribe(data => {
            if(data.length==1) {
              this.tokenStorage.savePointOfSale(data[0])
              this.router.navigate([posUrl]);
            }
            else {
              this.router.navigate(['/select-department']);
            }
          });
        }
        else {
          this.router.navigate(['/select-department']);
        }
      }
      else {
        this.router.navigate(['/select-department']);
      }
    }

    getBadgeColor(status: boolean) {
      switch (status) {
          case true:
              return '#00c569';
          case false:
              return '#f46363'
          default:
              return '#edf4f9';
      }
    }

    detail() {


      //this.showDetail=='false'?'true':'false';

      //alert(this.showDetail);
    }


}
