<span class="style-fiscalprinter row">
  <ng-container [ngSwitch]="line.method">
    <div
      *ngSwitchCase="'text'"
      [ngClass]="{
        right: item.align === 'right',
        center: item.align === 'center',
        'col-12': item.align !== 'leftRight',
        'col-6': item.align === 'leftRight',
        stretch: item.stretch,
        bold: item.bold,
        underline: item.underline
      }"
    >
      {{ line.valueL }}
    </div>
    <div
      *ngIf="item.align === 'leftRight'"
      class="col-6 right"
      [ngClass]="{
        stretch: item.stretch,
        bold: item.bold,
        underline: item.underline
      }"
    >
      {{ line.valueR }}
    </div>
    <ngx-barcode6 *ngSwitchCase="'BAR_CODE13'" [bc-value]="line.valueL" [bc-display-value]="true" [bc-line-color]="'var(--bs-body-color)'" [bc-width]="2" [bc-height]="70">></ngx-barcode6>
  </ng-container>
</span>
