
<div style="background-color: #fff;">
    <div class="modal-header">
        <h4 class="modal-title pull-left">{{customerName}}</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">

        <div class="row mb-4">






          <!--
          <div class="col-3 text-center">
            <a class="btn btn-light btn-lg" style="width:100%"routerLink="/customers/blacklist/{{customer}}" (click)="bsModalRef.hide()">
              <i class="feather icon-tag"></i>
              <br/>
              <span style="font-size:12px" class="text-muted">Black</span>

            </a>
          </div>
          <div class="col-3 text-center">
            <a class="btn btn-light btn-lg" style="width:100%" routerLink="/customers/filters/{{customer}}" (click)="bsModalRef.hide()">
              <i class="feather icon-filter"></i><br/>
              <span style="font-size:12px" class="text-muted">Filters</span>
            </a>
          </div>
          <div class="col-3 text-center">
            <a class="btn btn-light btn-lg" style="width:100%" routerLink="/customers/status/{{customer}}" (click)="bsModalRef.hide()">
              <i class="feather icon-paperclip"></i><br/>
              <span style="font-size:12px" class="text-muted">status</span>
            </a>
          </div>
          <div class="col-3 text-center">
            <a class="btn btn-light btn-lg" style="width:100%" routerLink="/customers/fileformat/{{customer}}" (click)="bsModalRef.hide()">
              <i class="feather icon-file"></i><br/>
              <span style="font-size:12px" class="text-muted">Files</span>

            </a>
          </div>
          -->

          <div class="col-12 text-center">
            <a class="btn btn-light btn-lg" style="width:100%"routerLink="/customers/detail/{{customer}}"(click)="bsModalRef.hide()">
              <i class="feather icon-edit"></i>
              <br/>
              <span style="font-size:12px" class="text-muted">Edit Customer</span>

            </a>
          </div>

        </div>

        <div class="d-flex align-items-center justify-content-between mb-4">
          <h4 class="modal-title pull-left">{{ 'NAV.EXPORT_MODULE' | translate }}</h4>
        </div>

        <div class="d-flex align-items-center justify-content-between mb-4">
            <div class="fw-bold text-dark">{{ 'NAV.ENABLE_EXPORT' | translate }}</div>
            <switch name="basicSwitch"></switch>
        </div>

        <div *ngIf="!showDetail">
          <div class="d-flex align-items-center justify-content-between mb-4">
            <h4 class="mb-0">{{ 'NAV.CONFIGURED_EXPORT' | translate }}</h4>
            <button class="btn btn-outline-secondary btn-sm" (click)="add()">{{ 'NAV.ADD' | translate }}</button>
          </div>
          <div style="min-height: 300px;">
              <table class="table table-hover">
                  <thead>
                      <tr>
                          <th>{{ 'NAV.TYPE' | translate }}</th>
                          <th>{{ 'NAV.STS' | translate }}</th>
                          <th></th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr class="cursor-pointer" *ngFor="let item of rows">
                          <td>
                              <div class="d-flex align-items-center">
                                  <avatar [size]="35" [icon]="'la la-mail-bulk'" *ngIf="item.exportType==='EMAIL'"></avatar>
                                  <avatar [size]="35" [icon]="'la la-folder'" *ngIf="item.exportType==='FTP'"></avatar>
                                  <avatar [size]="35" [icon]="'la la-folder'" *ngIf="item.exportType==='SFTP'"></avatar>
                                  <avatar [size]="35" [icon]="'la la-file-download'" *ngIf="item.exportType==='REST'"></avatar>
                                  <div class="ms-2">
                                      <div class="fw-bolder text-dark">{{item.address}}</div>
                                      <div class="text-muted fw-semibold">{{item.exportType}}</div>
                                  </div>
                              </div>
                          </td>
                          <td>
                              <div class="badge-dot" [ngStyle]="{'background-color': getBadgeColor(item.enabled)}"></div>
                          </td>
                          <td>
                              <a class="btn btn-light btn-sm" style="margin-right:10px" (click)='edit(item)'>
                                <i class="feather icon-edit"></i>
                              </a>
                          </td>

                      </tr>
                  </tbody>
              </table>
          </div>
        </div>

        <div *ngIf="showDetail">

          <form [formGroup]="formGroup" (validSubmit)="save()">

          <div class="d-flex align-items-center justify-content-between mb-4">
            <h4 class="mb-0">{{ 'NAV.EDIT_EXPORT' | translate }}</h4>

            <div class="btn-group" btnRadioGroup>

              <button class="btn btn-primary btn-sm" type="submit">{{ 'NAV.SAVE' | translate }}</button>
              <label class="btn btn-outline-secondary btn-sm" (click)='detail()'>&times;</label>
            </div>


          </div>


          <div class="form-group mb-3">
            <switch formControlName="enabled" [inline]="true">{{ 'NAV.EXPORT_ENABLED_SINGLE' | translate }}</switch>
          </div>
          <div class="form-group mb-3">
            <label class="form-label">Id</label>
            <input
                type="text"
                class="form-control no-validation-icon no-success-validation" placeholder="id"
                formControlName="id" readonly
            >
          </div>

          <div class="form-group mb-3">
            <label class="form-label">{{ 'NAV.EXPORT_TYPE' | translate }}</label>

            <ng-select formControlName="exportType"
                [items]="types"
                bindLabel="name"
                bindValue="id"
                (change)="onChange($event.id)"
            >
            </ng-select>
          </div>

          <div class="form-group mb-3">
            <label class="form-label">{{ 'NAV.ADDRESS' | translate }}</label>
            <input
                type="text"
                class="form-control no-validation-icon no-success-validation" placeholder="address"
                formControlName="address"
            >
          </div>


          <div class="form-group mb-3" *ngIf="showPort">
            <label class="form-label">{{ 'NAV.PORT' | translate }}</label>
            <input
                type="number"
                class="form-control no-validation-icon no-success-validation" placeholder="port"
                formControlName="port"
            >
          </div>


          <div class="form-group mb-3" *ngIf="showUsername">
            <label class="form-label">{{ 'NAV.USERNAME' | translate }}</label>
            <input
                type="text"
                class="form-control no-validation-icon no-success-validation" placeholder="username"
                formControlName="username"
            >
          </div>


          <div class="form-group mb-3" *ngIf="showPassword">
            <label class="form-label">{{ 'NAV.PASSWORD' | translate }}</label>
            <input
                type="text"
                class="form-control no-validation-icon no-success-validation" placeholder="password"
                formControlName="password"
            >
          </div>

          <div class="form-group mb-3" *ngIf="showName">
            <label class="form-label">{{ 'NAV.SERVICE_NAME' | translate }}</label>
            <input
                type="text"
                class="form-control no-validation-icon no-success-validation" placeholder="name"
                formControlName="name"
            >
          </div>

          <div class="form-group mb-3" *ngIf="showFileType">
            <label class="form-label">{{ 'NAV.FILE_TYPE' | translate }}</label>

            <ng-select formControlName="fileTypeId"
                [items]="files"
                bindLabel="name"
                bindValue="id"
            >
            </ng-select>
          </div>
          <div class="form-group mb-3">
            <label class="form-label">{{ 'NAV.INTERVAL' | translate }}</label>

            <ng-select formControlName="timeInterval"
                [items]="intervals"
                bindLabel="name"
                bindValue="id"
            >
            </ng-select>
          </div>


          </form>

        </div>



    </div>

  </div>
