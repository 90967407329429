import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_ENDPOINT } from '@app/configs/app.config';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
    providedIn: 'root'
})
export class PointOfSalesService {
    constructor(private http: HttpClient) {}

    getList(departmentId:string): Observable<any[]> {
      return this.http.get<any[]>(`${API_ENDPOINT}api/v1/posList/${departmentId}`, httpOptions);
    }

    insert(pos:any): Observable<any> {
      return this.http.post(`${API_ENDPOINT}api/v1/pos`, pos, httpOptions);
    }

    update(id:string, pos:any): Observable<any> {
      return this.http.put(`${API_ENDPOINT}api/v1/pos/${id}`, pos, httpOptions);
    }

    delete(id:string): Observable<any> {
      return this.http.delete(`${API_ENDPOINT}api/v1/pos/${id}`, httpOptions);
    }


}
