var windowAny = window as any;

windowAny.convertCurrencyString = function (amountString: string): number {
  amountString = amountString.replace(/[^\d.,-]/g, '');
  const itCurrency = /^(-)?\d{1,3}(.\d{3})*(,\d{1,2})?$/g; // 100.000.000,99
  const it2Currency = /^(-)?\d+(\,\d{1,2})?$/; // 100000000,99
  const enCurrency = /^(-)?\d+(\.\d{1,2})?$/; // 100000000.99

  if (itCurrency.test(amountString) || it2Currency.test(amountString)) {
    amountString = amountString.replace(/[.]/g, '');
    return parseFloat(amountString.replace(/[,]/g, '.'));
  }
  if (enCurrency.test(amountString)) {
    return parseFloat(amountString.replace(/[,]/g, '.'));
  }
};

windowAny.formattedNumber = function (numberToFormat: number, lang: string, currency: string = ''): string {
  return currency == ''
    ? numberToFormat.toLocaleString(lang, {
        style: 'decimal',
        minimumFractionDigits: 2,
      })
    : numberToFormat.toLocaleString(lang, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 2,
      });
};
