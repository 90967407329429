<div class="container">
  <div class="row style-fiscalprinter">
    <ng-container *ngFor="let item of header">
      <ticket-preview-line [item]="item" [extra]="extra" [length]="length"></ticket-preview-line>
    </ng-container>
  </div>
</div>


<ng-container [ngSwitch]="lang">
  <ng-container *ngSwitchDefault [ngSwitch]="ticketType">
    <!-- Lingua italian (default) -->
    <gift-preview-it *ngSwitchCase="'GIFT'"></gift-preview-it>
    <!-- Tipo Gift -->
    <ticket-preview-it *ngSwitchDefault [ticketType]="ticketType" [line]="line" [total]="total" [vat]="vat" [date]="date" [fiscalCode]="fiscalCode" [fiscalPrinter]="fiscalPrinter" [paymentMethod]="paymentMethod" [paymentMethodGroup]="paymentMethodGroup" [cashback]="cashback"></ticket-preview-it>
    <!-- Tipo Ticket (default) -->
  </ng-container>
</ng-container>
<div class="container">
  <div class="row style-fiscalprinter">
    <ng-container *ngFor="let item of footer">
      <ticket-preview-line [item]="item" [extra]="extra" [length]="length"></ticket-preview-line>
    </ng-container>
  </div>
</div>
