import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appPasteListener]'
})
export class PasteListenerDirective {

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent): void {
    this.handlePaste(event);

    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedText = clipboardData.getData('text');
    console.log(pastedText)
  }

  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    // Puoi gestire anche l'evento di input se necessario
  }

  private handlePaste(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedText = clipboardData.getData('text');

    // Richiama la funzione di gestione del carattere per gestire il testo incollato
    this.inputCharacter(pastedText);
  }

  private inputCharacter(character: string): void {
    // Implementa la logica della tua funzione inputCharacter
  }
}
