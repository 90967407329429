import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss']
})
export class SvgIconComponent implements OnInit {
  svgContent: string;

  @Input() path: string;
  @Output() click: EventEmitter<any> = new EventEmitter();

  @Input()
  public set size(size: {
    dim: number;
    meter: string;
  }) {
    var _size: number = (size.meter == 'rem')?this.remToPx(size.dim):size.dim;
    this.width = _size;
    this.height = _size;
  }
  width = 32;
  height = 32;
  constructor(private http: HttpClient) { }


  ngOnInit(): void {
   
  }

  loadSvg(): void {
    this.http.get(this.path, { responseType: 'text' }).subscribe(
      (data) => {
        console.log(data)
        this.svgContent = data;
      },
      (error) => {
        console.error('Failed to load SVG:', error);
      }
    );
  }
  remToPx(rem: number): number {
    const fontSize = parseFloat(window.getComputedStyle(document.documentElement).fontSize);
    const pxValue = rem * fontSize;
    return pxValue;
  }
  clickEvt(e){
    this.click.emit(e);
  }
}