<div class="auth-full-height d-flex flex-row align-items-center">
    <div class="container">
       <div class="d-flex justify-content-center mx-auto" style="max-width: 360px;">
           <img class="img-fluid" src="assets/images/others/img-2.png" alt="" />
       </div>
       <div class="text-center mt-5">
           <h1 class=" fw-bolder mb-3">Opss... PAGE NOT FOUND</h1>
           <p class="mb-4 font-size-lg">The page you're looking for isn't exist. We suggest you back to home.</p>
           <a routerLink=".." class="btn btn-info">Back to previous page</a>
       </div>
    </div>
</div>
