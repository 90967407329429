import { Injectable } from "@angular/core";
import { BehaviorSubject } from 'rxjs';
import { NavMenu } from '@app/shared/types/nav-menu.interface';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {

  private navbarSubject = new BehaviorSubject<NavMenu[]>([]);
  navbar$ = this.navbarSubject.asObservable();
  menu : NavMenu[] = []

  getNavbar(newMenu: NavMenu[]) {
    this.navbarSubject.next(newMenu);
  }
}
