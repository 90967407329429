/// <reference types="@types/googlemaps" />
import { Component, ViewChild, EventEmitter, Output, OnInit, AfterViewInit, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
//declare const google: any;

@Component({
  selector: 'AutocompleteComponent',
  template: ` <input class="input" class="form-control" type="text" [(ngModel)]="value" (input)="onInput($event)" (focus)="touched=true" #addresstext /> `,
  host: {
    '[class.conversation]': 'true',
  },
  styleUrls: ['./google-places.component.css'],
})
export class AutocompleteComponent implements OnInit, AfterViewInit {
  @Input() adressType: string;
  @Input() value: string;

  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @Output() changeStr: EventEmitter<any> = new EventEmitter();
  @ViewChild('addresstext') addresstext: any;

  autocompleteInput: string;
  queryWait: boolean;
  clickAddress: boolean = false;

  constructor() {}

  ngOnInit() {
    this.autocompleteInput = this.value;
  }

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }
  setValue(value){
    this.autocompleteInput = value;
    this.invalid = false;
  }

  private getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement, {
      types: [this.adressType], // 'establishment' / 'address' / 'geocode'
    });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      this.invalid = false;
      this.invokeEvent(place);
      this.value = this.autocompleteInput;
    });
  }

  invokeEvent(place: Object) {
    this.setAddress.emit(place);
  }
  touched = false
  invalid = true;
  onInput(evt: Event){
    this.invalid = true;
  }
}
