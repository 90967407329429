import { Component, OnInit, ChangeDetectionStrategy, Input, AfterViewInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'logo',
  templateUrl: './logo.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.logo]': 'true',
  },
})
export class LogoComponent implements OnInit, AfterViewInit {
  constructor() {}

  @Input() logoType: 'logo' | 'fold' = 'logo';
  @Input() white = false;
  private _height: number | string = 70;

  logoPath: string = 'assets/images/logo/';

  @Input() set height(val) {
    this._height = val;
  }

  get height(): string {
    return this._height + 'px';
  }
  ngAfterViewInit(): void {
    //ngAfterViewInit(): void {
    //throw new Error('Method not implemented.');
  }
  ngOnInit(): void {
    //ngOnInit(): void {
    switch (window.location.hostname) {
      case 'localhost':
        this.logoPath = 'assets/images/logo/';
        document.getElementById('appFavicon').setAttribute('href', 'favicon.ico');
        document.title = 'SmartRfid';
        break;

      case 'campionario.azurewebsites.net':
        this.logoPath = 'assets/images/logo/';
        document.getElementById('appFavicon').setAttribute('href', 'favicon.ico');
        document.title = 'SmartRfid';
        break;
      default:
        this.logoPath = 'assets/images/cus1/';
        document.getElementById('appFavicon').setAttribute('href', 'favicon-temera.ico');
        if (document.getElementById('loginbg') != null) {
          document.getElementById('loginbg').style.backgroundImage = "url('assets/images/cus1/bg-1.jpg')";
        }
        document.title = 'Stylewhere';
        break;

      case 'liujo.smartrfid.it':
        this.logoPath = 'assets/images/logo/';
        document.getElementById('appFavicon').setAttribute('href', 'favicon.ico');
        document.title = 'SmartRfid';
        break;
      case 'teddy.smartrfid.it':
        this.logoPath = 'assets/images/logo/';
        document.getElementById('appFavicon').setAttribute('href', 'favicon.ico');
        document.title = 'SmartRfid';
        break;
      case 'velasca.smartrfid.it':
        this.logoPath = 'assets/images/logo/';
        document.getElementById('appFavicon').setAttribute('href', 'favicon.ico');
        document.title = 'SmartRfid';
        break;
      case 'gutteridge.smartrfid.it':
        this.logoPath = 'assets/images/logo/';
        document.getElementById('appFavicon').setAttribute('href', 'favicon.ico');
        document.title = 'SmartRfid';
        break;
      case 'demo.smartrfid.it':
        this.logoPath = 'assets/images/logo/';
        document.getElementById('appFavicon').setAttribute('href', 'favicon.ico');
        document.title = 'SmartRfid';
        break;
      case 'test.smartrfid.it':
        this.logoPath = 'assets/images/logo/';
        document.getElementById('appFavicon').setAttribute('href', 'favicon.ico');
        document.title = 'SmartRfid';
        break;
      case 'liujotest.azurewebsites.net':
        this.logoPath = 'assets/images/logo/';
        document.getElementById('appFavicon').setAttribute('href', 'favicon.ico');
        document.title = 'SmartRfid';
        break;
      case 'liujoretail.smartrfid.it':
        this.logoPath = 'assets/images/logo/';
        document.getElementById('appFavicon').setAttribute('href', 'favicon.ico');
        document.title = 'SmartRfid';
        break;

      case 'antonymorato.smartrfid.it':
        this.logoPath = 'assets/images/logo/';
        document.getElementById('appFavicon').setAttribute('href', 'favicon.ico');
        document.title = 'SmartRfid';

        break;

      case 'smartrfid2.azurewebsites.net':
        this.logoPath = 'assets/images/logo/';
        document.getElementById('appFavicon').setAttribute('href', 'favicon.ico');
        document.title = 'SmartRfid';

        break;

      case 'temera.smartrfid.it':
        this.logoPath = 'assets/images/cus1/';
        document.getElementById('appFavicon').setAttribute('href', 'favicon-temera.ico');
        document.getElementById('loginbg').style.backgroundImage = "url('assets/images/cus1/bg-1.jpg')";

        document.title = 'Stylewhere';

        break;
      case 'dgrfid.dolcegabbana.it':
        this.logoPath = 'assets/images/cus1/';
        document.getElementById('appFavicon').setAttribute('href', 'favicon-temera.ico');
        document.getElementById('loginbg').style.backgroundImage = "url('assets/images/cus1/bg-1.jpg')";

        document.title = 'Stylewhere';

        break;

      case 'dgrfid-test.dolcegabbana.it':
        this.logoPath = 'assets/images/cus1/';
        document.getElementById('appFavicon').setAttribute('href', 'favicon-temera.ico');
        document.getElementById('loginbg').style.backgroundImage = "url('assets/images/cus1/bg-1.jpg')";

        document.title = 'Stylewhere';

        break;

      case 'rfid.boggi.com':
        this.logoPath = 'assets/images/cus1/';
        document.getElementById('appFavicon').setAttribute('href', 'favicon-temera.ico');
        document.getElementById('loginbg').style.backgroundImage = "url('assets/images/cus1/bg-1.jpg')";

        document.title = 'Stylewhere';

        break;

      case 'rfidtest.boggi.com':
        this.logoPath = 'assets/images/cus1/';
        document.getElementById('appFavicon').setAttribute('href', 'favicon-temera.ico');
        document.getElementById('loginbg').style.backgroundImage = "url('assets/images/cus1/bg-1.jpg')";

        document.title = 'Stylewhere';

        break;

      case 'moncler.temera.it':
        this.logoPath = 'assets/images/cus1/';
        document.getElementById('appFavicon').setAttribute('href', 'favicon-temera.ico');
        document.getElementById('loginbg').style.backgroundImage = "url('assets/images/cus1/bg-1.jpg')";

        document.title = 'Stylewhere';

        break;

      case 'moncler-qty.temera.it':
        this.logoPath = 'assets/images/cus1/';
        document.getElementById('appFavicon').setAttribute('href', 'favicon-temera.ico');
        document.getElementById('loginbg').style.backgroundImage = "url('assets/images/cus1/bg-1.jpg')";

        document.title = 'Stylewhere';

        break;

      case 'tmrgucci.azurewebsites.net':
        this.logoPath = 'assets/images/cus1/';
        document.getElementById('appFavicon').setAttribute('href', 'favicon-temera.ico');
        document.getElementById('loginbg').style.backgroundImage = "url('assets/images/cus1/bg-1.jpg')";

        document.title = 'Gestione Campionario';

        break;

      case 'tmrguccitest.azurewebsites.net':
        this.logoPath = 'assets/images/cus1/';
        document.getElementById('appFavicon').setAttribute('href', 'favicon-temera.ico');
        document.getElementById('loginbg').style.backgroundImage = "url('assets/images/cus1/bg-1.jpg')";

        document.title = 'Gestione Campionario';
        break;

      case 'tmrrfid.azurewebsites.net':
        this.logoPath = 'assets/images/cus1/';
        document.getElementById('appFavicon').setAttribute('href', 'favicon-temera.ico');
        document.getElementById('loginbg').style.backgroundImage = "url('assets/images/cus1/bg-1.jpg')";

        document.title = 'Stylewhere';

        break;

      case 'tmrrfidtest.azurewebsites.net':
        this.logoPath = 'assets/images/cus1/';
        document.getElementById('appFavicon').setAttribute('href', 'favicon-temera.ico');
        document.getElementById('loginbg').style.backgroundImage = "url('assets/images/cus1/bg-1.jpg')";

        document.title = 'Stylewhere';

        break;
      case '13.94.141.15':
        this.logoPath = 'assets/images/cus1/';
        document.getElementById('appFavicon').setAttribute('href', 'favicon-temera.ico');
        document.getElementById('loginbg').style.backgroundImage = "url('assets/images/cus1/bg-1.jpg')";

        document.title = 'Gestione Campionario';

        break;
      case 'gestionecampionario.temera.it':
        this.logoPath = 'assets/images/cus1/';
        document.getElementById('appFavicon').setAttribute('href', 'favicon-temera.ico');
        document.getElementById('loginbg').style.backgroundImage = "url('assets/images/cus1/bg-1.jpg')";

        document.title = 'Gestione Campionario';
        break;
      case 'dgtest.temera.it':
        this.logoPath = 'assets/images/cus1/';
        document.getElementById('appFavicon').setAttribute('href', 'favicon-temera.ico');
        document.getElementById('loginbg').style.backgroundImage = "url('assets/images/cus1/bg-1.jpg')";

        document.title = 'Stylewhere';
        break;
      case 'gestionecampionario-test.temera.it':
        this.logoPath = 'assets/images/cus1/';
        document.getElementById('appFavicon').setAttribute('href', 'favicon-temera.ico');
        document.getElementById('loginbg').style.backgroundImage = "url('assets/images/cus1/bg-1.jpg')";

        document.title = 'Gestione Campionario';
        break;
      case 'tracking.gestionecampionario.it':
        this.logoPath = 'assets/images/cus1/';
        document.getElementById('appFavicon').setAttribute('href', 'favicon-temera.ico');
        document.getElementById('loginbg').style.backgroundImage = "url('assets/images/cus1/bg-1.jpg')";

        document.title = 'Gestione Campionario';
        break;
      case 'checkpoint.smartrfid.it':
        this.logoPath = 'assets/images/checkpoint/';
        document.getElementById('appFavicon').setAttribute('href', 'favicon-checkpoint.ico');

        document.title = 'SmartRfid';
        break;
      case 'mcsistemi.smartrfid.it':
        this.logoPath = 'assets/images/logo/';
        document.getElementById('appFavicon').setAttribute('href', 'favicon.ico');

        document.title = 'SmartRfid';
        break;
    }
  }

  getLogoTypeUrl() {
    const chain = ['logo'];
    const urlPrefix = this.logoPath;
    if (this.logoType === 'fold') {
      chain.push('fold');
    }
    if (this.white) {
      chain.push('white');
    }
    let logoUrl = `${urlPrefix}${chain.join('-')}.png`;
    return logoUrl;
  }
}
