export const nav = {
  'NAV': {
      'DASHBOARD': 'ダッシュボード',
      'TASKS': 'タスク',
      'PLANNER': 'プランナー',
      'STOCK': 'ストック',
      'STOCKZONE': '在庫ゾーン',
      'CUSTOMER_CONFIG_MENU': '顧客設定メニュー',
      'CLIENTS': 'クライアント',
      'DEPARTMENTS': '部門',
      'LOCATIONTYPES': 'ロケーションタイプ',
      'DOCUMENTTYPES': 'ドキュメントタイプ',
      'PRODUCTS': '製品',
      'ADMIN_CONFIG_MENU': '管理者設定メニュー',
      'CUSTOMERS': '顧客',
      'USERS': 'ユーザー',
      'MENU': 'メニュー',
      'REPORT': 'レポート',
      'GROUPS': 'グループ',

      'PRINTER_SETTINGS': 'プリンター設定',
      'PRINTERS_AND_TEMPLATES': 'プリンターとテンプレート',
      'PRINTERS': 'プリンター',
      'ZPL_TEMPLATES': 'ZPLテンプレート',

      'SECURITY': 'セキュリティ',
      'EVENTS_DASHBOARD': 'イベントダッシュボード',

      'EXPORT_MODULE': 'エクスポートモジュール',
      'ENABLE_EXPORT': 'エクスポートを有効にする:',
      'CONFIGURED_EXPORT': '設定されたエクスポート',
      'ADD': '新規追加',
      'TYPE': 'タイプ',
      'STS': 'ステータス',
      'EXPORT_ENABLED': 'エクスポート有効',
      'EXPORT_ENABLED_SINGLE': 'エクスポート有効',
      'EXPORT_TYPE': 'エクスポートタイプ',
      'ADDRESS': 'アドレス',
      'PORT': 'ポート',
      'USERNAME': 'ユーザー名',
      'PASSWORD': 'パスワード',
      'SERVICE_NAME': 'サービス名/フォルダー',
      'FILE_TYPE': 'ファイルタイプ',
      'INTERVAL': '間隔',
      'EDIT_EXPORT': 'エクスポートを編集',
      'SAVE': '保存',
      'PROFILE': 'プロファイル',
      'SIGNOUT': 'サインアウト',

      'SIGNIN': 'サインイン',
      'SIGNIN_MESSAGE': '続けるにはアカウントにサインインしてください',
      'FORGET_PASSWORD': 'パスワードを忘れた場合?',
      'NO_ACCOUNT': 'アカウントを持っていない?',
      'SIGNUP': 'サインアップ',
      'EMAIL': 'メール',

      'SIGNUP_MESSAGE': 'サインアップして新しいアカウントを作成！',
      'NAME_SURNAME': '名前と姓',
      'CONFIRM_PASSWORD': 'パスワードを確認',
      'ALREADY_ACCOUNT': '既にアカウントを持っていますか?',

      'USER': 'ユーザー',
      'NO_CUSTOMER': '顧客がいません',
      'CONTACT_ADMIN': 'ユーザーを設定するには管理者に連絡してください。',

      'SETPASSWORD': 'パスワードを設定',
      'SETPASSWORD_MESSAGE': 'アカウントを有効にするためのパスワードを設定してください',

      'CHANGEEXPIREDPASSWORD': 'パスワードの有効期限が切れています',
      'CHANGEEXPIREDPASSWORD_MESSAGE': 'パスワードの有効期限が切れています。ログオンするには変更してください',

      'CHANGEPASSWORD': 'パスワードを変更',
      'CHANGEPASSWORD_MESSAGE': '新しい安全なパスワードを作成'
  },
  'APP': {
    'SELECT_CUSTOMER': '顧客を選択',
    'SELECT_CUSTOMER_TO_CONTINUE': '続行するには顧客を選択してください',
    'CUSTOMER': '顧客',
    'NO_CHOOSE': '選択しない',
    'SIGN_OUT': 'サインアウト',
    'INCOMING': '着信タスク',
    'OUTGOING': '発信タスク',
    'FILTER_DATA': 'データをフィルタリングするために入力してください...',
    'ALL_TASKS': 'すべてのタスク',
    'PAST_7': '過去7日間',
    'PAST_30': '過去30日間',
    'PAST_60': '過去60日間',
    'PAST': '過去',
    'DAYS': '日',

    'LIST_OF': 'のリスト',
    'CLOSED': '閉じる',
    'OPEN': '開く',
    'TASKS': 'タスク',
    'OPEN_TASKS': '開いているタスク',
    'CLOSED_TASKS': '閉じているタスク',
    'PENDING_TASKS': '保留中のタスク',
    'COPY_TASKS': 'タスクをコピー',
    'EXCEL_EXPORT': 'Excelエクスポート',
    'REFERENCE': '参照',
    'STS': 'ステータス',
    'DEADLINE': '締切',
    'DEPARTMENT': '部門',
    'TASK_TYPE': 'タスクタイプ',
    'PROGRESSIVE': '進行',
    'EXPECTED': '予測',
    'READ': '読み取り',
    'ACTIONS': 'アクション',

    'CLOSE': '閉じる',
    'SAVE': '保存',
    'DOCUMENT_FILTER': 'ドキュメントフィルター',
    'DESTINATION_LOCATION': '目的地',
    'DESTINATION': '目的地',
    'DEADLINE_DATE': '締切日',
    'LOCATION_FILTER': 'ロケーションフィルター',

    'WARNING': '警告!',
    'SELECT2': 'コピー機能を使用するには、少なくとも1つのタスクを選択してください。',
    'SELECT1': '少なくとも1行を選択してください。',

    'DELETE': '削除',
    'CANNOT_UNDONE': 'この操作は取り消しできません',

    'COPY_EXPECTED_DATA': '予想データをコピー',
    'COPY_READ_DATA': '読み取りデータをコピー',
    'COPY_OPTIONS': 'コピーオプション',
    'CLOSE_COPIED_TASKS': 'コピーしたタスクを閉じる',
    'NEW_TASK_ATTRIBUTES': '新しいタスク属性',

    'ONLY_DIFFERENCE': '差異のみ',
    'BACK': '戻る',
    'IMPORT_EXPECTED': '予想をインポート',
    'DELETE_SELECTED_READ': '選択した読み取りを削除',
    'CLOSE_TASK': 'タスクを閉じる',
    'STATUS': 'ステータス',
    'PACKAGE_CODE': 'パッケージコード',
    'DESCRIPTION': '説明',
    'DIFFERENCE': '差異',

    'DELETE_READ_FOR REFERNCE': '参照用に読み取りを削除',
    'DELETE_READ_FOR REFERNCE2': 'アイテムはまだ移動します。<br/>アイテムの読み取り差異のみが削除されます ',
    'CONFIRM': '確認',

    'CONFIRM_DELETE': '削除を確認',
    'ROWS': '行',
    'DELETE_SELECTED': '選択したものを削除',
    'CONFIRM_CLOSE': '閉じることを確認',
    'CLOSE_SELECTED': '選択したものを閉じる',
    'TASK': 'タスク',

    'IMPORT_TYPE': 'インポートタイプ',
    'CHOOSE_A_FILE': 'ファイルを選択',
    'SEARCH': '検索',
    'SELECT_A_TAG': 'タグを選択',
    'SELECT_A_TAG_TO_CONTINUE': '続行するにはタグを選択してください',

    'SELECT_A_DEPARTMENT': '部門を選択',
    'SELECT_A_DEPARTMENT_TO_CONTINUE': '続行するには部門を選択してください',
    'STOCK_DETAIL': '在庫詳細',
    'LOCATION': '位置',
    'UNITS': '単位',

    'LIST_OF_CLIENTS': 'クライアントのリスト',
    'SHOW_INACTIVE_CLIENTS': '非アクティブなクライアントを表示',
    'SHOW_CUSTOMER_CLIENTS': '顧客クライアントを表示',
    'NAME': '名前',
    'ENABLED': '有効',
    'DELETE_CLIENT': 'クライアントを削除',
    'CLIENT_ENABLED': 'クライアントが有効',
    'DOCUMENT_TYPES': 'ドキュメントタイプ',

    'LIST_OF_DEPARTMENTS': '部門のリスト',
    'DELETE_DEPARTMENT': '部門を削除',
    'CODE': 'コード',
    'ADD_LOCATIONS': 'ロケーションを追加',
    'LOCATION_TYPES': 'ロケーションタイプ',

    'BLACK_LIST': 'ブラックリスト',
    'DELETE_BLACK': 'ブラックを削除',

    'LIST_OF_CUSTOMERS': '顧客のリスト',
    'EPC_ENCODE_TYPE': 'EPCエンコードタイプ',
    'DELETE_CUSTOMER': '顧客を削除',
    'BLACK_START': 'ブラック開始',
    'BLACK_END': 'ブラック終了',

    'LIST_OF_DOCUMENT_TYPES': 'タスクタイプのリスト',
    'MASTER': 'マスター',
    'CAN_OVERLOAD': 'オーバーロード可能',
    'AS_EXPECTED': '予定どおり',
    'CAN_CHANGE_LOCATION': 'ロケーション変更可能',
    'DELETE_TASK_TYPE': 'タスクタイプを削除',

    'SELECT_TASKTYPE_TEMPLATE': 'タスクタイプテンプレートを選択',
    'SIGN': 'サイン',
    'AUTO_ACCEPT_TASK': 'タスクを自動承認',
    'CHECK_PACKAGE': 'パッケージをチェック',
    'RFID_POWER': 'RFIDパワー',
    'TASK_HAS_DEADLINE': 'タスクには締切があります',
    'IS_DEADLINE_EDITABLE': '締切は編集可能ですか',
    'DEADLINE_DAYS': '締切日数',
    'FILTERS': 'フィルター',
    'STOCK_FILTER_TYPE': '在庫フィルタータイプ',
    'LOCATION_TYPE_FILTER': 'ロケーションタイプフィルター',

    'FILE_FORMAT': 'ファイル形式',

    'COLUMN_LIST': 'カラムリスト',
    'SEPARATOR': '区切り文字',
    'HEADER': 'ヘッダーがあります',

    'IMPORT_FROM_CSV': 'CSVからインポート',

    'VALUE': '値',
    'START': '開始',
    'END': '終了',

    'LIST_OF_LOCATIONS': 'ロケーションのリスト',
    'LOCATION_TYPE': 'ロケーションタイプ',

    'LIST_OF_LOCATION_TYPES': 'ロケーションタイプのリスト',
    'SELECTABLE': '選択可能',
    'LOCATION_TYPE_IS_SELECTABLE': 'ロケーションタイプは選択可能です',

    'LIST_OF_PRODUCTS': '製品のリスト',
    'SHORT_DESCRIPTION': '短い説明',
    'LONG_DESCRIPTION': '長い説明',

    'UPLOAD': 'アップロード',

    'STATUS_LIST': 'ステータスリスト',
    'DEFAULT': 'デフォルト',
    'STATUS_TYPE': 'ステータスタイ',

    'STATUS_WILL_BE_SET_AS_DEFAULT': 'ステータスがデフォルトに設定されます',

      'LIST_OF_USERS': 'ユーザーのリスト',
      'ROLE': '役割',
      'DEPARTMENTS': '部門',

      'TASK_TYPE_DESTINATION': 'タスクタイプの目的地',
      'TASKTYPE_INFO': 'タスクタイプ情報',

      'MAX_SERIAL': '最大シリアル許可',
      'CAN_ADD_PRODUCT': '顧客はウェブおよびモバイルから製品を追加できます',
      'PRODUCT_PROPERTIES': '製品のプロパティ',
      'BARCODE_MASK': 'バーコードマスク',
      'REPLACE_MASK': 'マスクを置き換える',

      'PRODUCT_DEFAULT_DESCRIPTION': 'デフォルトの説明',

      'DEPARTMENT_STOCK': '部門の在庫',
      'GOTOSTOCK': '在庫へ行く',

      'SHOW_SERIALS': '製品のシリアルを表示',
      'PRODUCT_DETAILS': '製品のシリアル',
      'EPC': 'EPC',
      'LOADWAREHOUSE': 'EPCを書き込むか、シリアルを照合して倉庫に追加',

      'TASKS_TOTAL': 'タスクの合計',

      'SELECT_A_REPORT': 'レポートを選択',
      'SELECT_A_REPORT_TO_CONTINUE': '続行するにはレポートを選択',
      'AVG_STAY': '平均滞在期間',
      'TREND': 'トレンド',
      'STOCK': '在庫',
      'EXPIRED': '期限切れ',

      'EXPIRATION_DATE': '有効期限',
      'DOC_REF': 'タスクの参照',
      'ITEM_REFERENCE': 'アイテムの参照',
      'ARRIVAL_DATE': '到着日',

      'GROUPS': 'グループ',
      'GROUP': 'グループ',

      'PRINT': '印刷',
      'PRINTABLE': '印刷可能なタスク',
      'ALLOW_MULTIPLE_PRINT': '複数印刷を許可',
      'ORDER_BY_FIELDS': 'フィールドによる並び替え',
      'EXPORT': 'エクスポート',
      'EXPORT_TO_CUSTOMER': '顧客へエクスポート',

      'PRINT_SELECTED': '選択したものを印刷',

      'GENERATE_INVENTORY': '在庫を生成',
      'EXPECTED_TYPE': '予想されるタイプ',

      'PRINTERS': 'プリンター',
      'IP': 'IP',
      'PORT': 'ポート',
      'PRINTER_TYPES': 'プリンタータイプ',

      'LIST_OF_TEMPLATES': 'ZPLテンプレートのリスト',
      'BREAKPOINT_LABEL': 'ブレークポイントラベル',
      'ZPL': 'ZPL',

      'PRINTER': 'プリンター',
      'TEMPLATE': 'ZPLテンプレート',
      'GENERAT_ZPL_AND_DOWNLOAD': 'ZPLを生成してダウンロード',

      'SHOW_ZERO': '在庫がないアイテムを表示',
      'IMAGES': '画像',

      'UNKNOWN_IMAGE': '不明なアイテムの画像',
      'DEFAULT_IMAGE': 'デフォルト画像',

      'UNKNOWN_TEXT_COLOR': '不明なアイテムのテキスト色',
      'ENABLE_KILL': 'タグ管理でKillを有効にする（モバイル）',
      'CAN_ADD_DEPARTMENT': 'モバイルアプリから部門を追加できる',

      'GROUPBY': 'グループ化',
      'GROUPBY_REF': '参照でグループ化',
      'GROUPBY_PACK': 'パッケージコードでグループ化',
      'GROUPBY_EPC': 'EPCでグループ化',

      'CUSTOMER_DETAIL': '顧客詳細',
      'PERSONAL': '個人データ',
      'ENCODING': 'エンコーディング',
      'TAG_MANAGMENT': 'タグ管理',
      'PRODUCTS': '製品',

      'SUCCESS': '成功',
      'SAVED': '全ての変更が保存されました',

      'EAN13': 'バーコード',
      'COMPANY_PREFIX': '会社プレフィックスの長さ（アイテムのバーコードがEAN13でない場合は0）',

      'SELECT_AN_EVENT': 'イベントを選択',
      'SELECT_AN EVENT_TO_CONTINUE': '続行するにはイベントを選択してください',
      'EPC_TIMELINE': 'EPCタイムライン',

      'NO_EAS_ALARM': '表示するイベントがありません',

      'EAS_SERVER': 'Easサーバー',
      'ENABLE_SECURITY': 'セキュリティダッシュボードを有効にする',

      'GOTO_DASH': 'ダッシュボードに戻る',

      'IMAGE': '画像',
      'SECURITY_LASTHOURS': '最後の*n時間からのセキュリティアラームを表示',
      'ENABLE_QUALITY': '品質から製品をダウンロード',
      'QUALITY_ENDPOINT': '品質エンドポイント',
      'QUALITY_USERNAME': '品質ユーザー名',
      'QUALITY_PASSWORD': '品質パスワード',

      'ITEM': 'アイテム',
      'POE': '出口点',
      'TIMESTAMP': 'タイムスタンプ',

      'PASSWORD_RECOVERY': 'パスワード回復',
      'PASSWORD_RECOVERY_MESSAGE': 'パスワードを回復するためにメールを挿入してください',

      'TOTAL': '合計',
      'TOTAL_READ': '読み取り合計',
      'TOTAL_EXP': '予想合計',
      'TOTAL_DIFF': '差分合計',

      'SEND_TO_EAS': 'EASサーバーにデータを送信',
      'EAS_TOPIC': 'EASサーバーMQTTトピック',
      'CUSTOMER_EAS_WARNING': '全ての部門のデータがこのトピックに送られます。',

      'CLEAR_EVENTS': '全てのイベントをクリア',
      'CLEAR': 'クリア',

      'LIST_OF_REASONS': '理由のリスト',
      'REASON': '理由',
      'REASONS': '理由',

      'ENABLE_MQTT_RESPONSE': 'MQTTレスポンスを有効にする',

      'REQUIRED': '必須',
      'REASON_MESSAGE': '理由を選択する必要があります',

      'CUSTOMER_ROLES': '顧客ロール',
      'ROLE_AUTH': 'ロール認証',
      'ENABLE_WRITE': 'タグ管理で書き込み/一致を有効にする（モバイル）',
      'ENABLE_STATUS': 'タグ管理でステータス変更を有効にする（モバイル）',
      'ENABLE_ZONE': 'タグ管理でゾーン変更を有効にする（モバイル）',

      'ENABLE_PRINT': '印刷を有効にする（ウェブ/モバイル）',
      'ENABLE_ADDTASK': '新しいタスクを追加を有効にする（ウェブ/モバイル）',
      'ENABLE_CLOSETASK': 'タスクを閉じるを有効にする（ウェブ/モバイル）',
      'ENABLE_DELETETASK': 'タスクを削除を有効にする（ウェブ）',
      'ENABLE_COPYTASK': 'タスクのコピー/マージタスクを有効にする（ウェブ/モバイル）',
      'ENABLE_CHANGEFILTER': 'フィルターとオプションの変更を有効にする（モバイル）',

      'LOGIN_IS_MANDATORY': 'ログインは必須です',
      'CUSTOMER_ROLE': '顧客の役割',

      'CANNOT_PERFORM_ACTION': 'あなたの役割ではこのアクションを実行できません',

      'DOC_REFERENCE': 'ドキュメント参照',

      'DISABLE_PARTIAL_MOVEMENT': '部分的な動きを無効にする',
      'AUTOMATIC_PARTIAL_MOVEMENT': '部分的な動きは自動的です',

      'MOVED': '移動しました',

      'ENABLE_SCANPRINT': 'ScanPrintおよびScanScanWriteを有効にする',

      'USE_SENDER_RECIPENT': 'タスクでは送信者/受信者を使用します',

      'TYPES': 'タイプ',
      'TIMEZONE': 'タイムゾーン',
      'WORKING_DEPARTMENT': '勤務部門',

      'SENDER': '送信者',
      'RECIPIENT': '受信者',

      'CHECK_PACKAGE_MANDATORY': 'パッケージの確認は必須です',
      'SEND_SNAPSHOT': 'スナップショットを送信',

      'WAITING_FOR_EXPECTED': '予想を待っています',

      'PENDING_MESSAGE': 'このタスクは保留中のステータスです。間もなく利用可能になります。',

      'TASK_PENDING': '保留中のタスク',

      'NOTE': 'ノート',

      'SELECTED_PRODUCTS': '選択されたアイテム',
      'SHOW_HIDE_PRODUCTS': 'アイテムの表示 / 非表示',
      'ADVANCED_SEARCH': '高度な検索',

      'MODEL': 'モデル',
      'PART': 'パート',
      'VARIANT': 'バリアント',
      'BRAND': 'ブランド',
      'GENDER': '性別',
      'OUTLINE': '概要',
      'TARGET': 'ターゲット',
      'SEASON': 'シーズン',
      'MATERIAL': '素材',
      'COLOUR': '色',
      'SIZE': 'サイズ',
      'SHOW_OTHERS': 'その他の表示 / 非表示',

      'CANNOT_ADD': 'ウェブからタスクを追加することはできません',
      'CANNOT_ADD_MOBILE': 'モバイルからタスクを追加することはできません',



      'VIEW_TYPE': 'ビュータイプ',

      'MAIN': 'メイン',

      'SECONDARY_BARCODES': '二次バーコード',

      'SHOW_HIDE_MORE': 'さらにオプションを表示/非表示',

      'CERT_PATH': '証明書のパス',
      'KEY_PATH': 'プライベートキーのパス',
      'MQTT_TYPE': '使用されるMQTT SDK',

      'FROM_DATE': '開始日',
      'TO_DATE': '終了日',
      'DATE_RANGE': '日付範囲',

      'STARTUP_SNAPSHOT': 'モバイル起動時にスナップショットを実行',

      'ENABLE_POOLING': 'ダッシュボード通知のためのプーリングを有効にする',
      'POOLING_INTERVAL': 'プーリング間隔（秒）',

      'LOCTYPE_FILTER_MANDATORY': 'ロケーションタイプフィルターは必須です',

      'CAN_REMOVE_READ': 'RFIDリーダーで読み取りを削除できる',

      'LIST_OF_ZONES': 'ゾーンリスト',
      'ZONE_WILL_BE_SET_AS_DEFAULT': 'ゾーンがデフォルトとして設定されます',

      'BARCODE': 'バーコード',
      'ZONE': 'ゾーン',
      'ZONE_DETAIL': 'ゾーン詳細',

      'NOTE_MANDATORY': 'メモは必須です',
      'SHOW_TASK_DETAIL': 'タスク詳細を表示',
      'DOC_DATE': 'ドキュメント日付',
      'EDIT_NOTE': 'メモを編集',
      'LIST_OF_RELATIONS': '関連リスト',
      'RELATION': '関係',
      'ALL': '全て',

      'DEPARTMENT_TYPES': '部門グループ',
      'DEPARTMENT_TYPE': '部門グループ',

      'DEPARTMENT_CATEGORY': '部門カテゴリー',

      'DEPARTMENT_CATEGORIES': '部門カテゴリー',
      'PREVIOUS': '最初のカテゴリーへ',

      'INVENTORY_ADJUSTMENT': '在庫調整',

      'PACK_COMPLIANCE': 'パッケージ名はコンプライアンスでなければならない',
      'DOC_DATE_EDITABLE': 'ドキュメント日付は編集可能',

      'PASSWORD_POLICIES': 'パスワードポリシー',
      'EXPIRATION_DAYS': '有効期限の日数',
      'FAILED_ATTEMPTS': '失敗した試行',
      'MIN_LENGTH': '最小の長さ',
      'MAX_LENGTH': '最大の長さ',
      'MIN_PASSWORD_AGE': 'パスワードの最小年齢',
      'MASK': 'マスク',

      'MUST_HAVE_SMALLCASE': '小文字を少なくとも1つ含む必要があります',
      'MUST_HAVE_CAPITALCASE': '大文字を少なくとも1つ含む必要があります',
      'MUST_HAVE_NUMBER': '数字を少なくとも1つ含む必要があります',
      'MUST_HAVE_SPECIAL_CHARACTER': '特殊文字を少なくとも1つ含む必要があります',

      'ENABLE_CEGID': 'Cegidの機能を有効にする',

      'CEGID_EXPORT': 'Cegidエクスポート',

      'ACTIVEDIRECTORY': 'アクティブディレクトリ',

      'PACKAGE': 'パッケージ設定',
      'PACKAGE_CHECK_TYPE': '期待される/読み取られたパッケージ数に基づくチェックタイプ',

      'INVERTED_DIFFERENCES': 'タスク上の逆差分（期待される-読まれた --> 読まれた-期待される）',

      'FLOW_CONFIG': 'タスクフロー設定',
      'TASK_TYPE_TO_CREATE': 'タスク終了時に作成する新しいタスクタイプ',
      'CREATE_NEWDOC_WITH_ONLY_DIFF': '差分のみで新しいタスクを作成',

      'ASKCLOSE_TASK': 'モバイルから読み取りを要求する',

      'ASK4READ': 'タスクに対してモバイルから読み取りを要求する',
      'ASK4READ_BUTTON': '読み取りを要求する',

      'SAVED_PASSWORD': 'ユーザーに保存されたパスワードの数',

      'RESET': 'フィルターをリセット',

      'PLAY_SOUND': 'デバイスで音を鳴らす',

      'VERSION': 'リリース',

      'PLAN_TASK': 'タスクを計画する',
      'ALL_DAY': '終日タスク',
      'TASK_DATE': '開始日',

      'REPEAT': '繰り返し',

      'DISABLE_SORT': 'モバイルでの"パワーによるリストのソート"を無効にする',
      'DISABLE_CUSEPC': 'モバイルで"顧客EPCのみ読み取り"を無効にする',
      'DISABLE_ENTER': 'モバイルで"正規表現マッチ時に自動で\'Enter\'を送信"を無効にする',
      'DEFAULT_VALUE': 'デフォルト値',

      'SETTINGS': '設定',

      'UNIQUE_REFERENCE': 'タスク参照はユニークキーです(APIのみ)',
      'DOC_EXCLUSIVE': '1人のクライアントからしか作業できないタスク',
      'SEND_START': 'タスクに入る時にメッセージを送る',
      'SEND_END': 'タスクから出る時にメッセージを送る',

      'SHOW_STOCK': '製品詳細で在庫を表示',

      'TASK_TYPE_CATEGORY': 'タスクタイプカテゴリー',

      'COUNTRY': '国',
      'STATE': '州',
      'DISTRICT': '地区',
      'CITY': '市',
      'ADDRESS': '完全な住所',

      'SHOW_PIVOT': 'ピボットテーブルを表示',
      'SIZE_GROUPS': 'サイズグループ',
      'SELECT_SIZE_GROUP': 'サイズグループを選択',

      'SEND_UNLOCK': 'アンロックを送信',

      'EXPORT_ENVIRONMENTS': 'エクスポート環境',
      'EXPORT_ENVIRTMENT': 'エクスポート環境',

      'BUSINESS_INTELLIGENCE': 'ビジネスインテリジェンス',
      'ENABLE_POWERBI_EMBEDDED': 'PowerBi Embeddedを有効にする',
      'POWERBI_ENDPOINT': 'PowerBi エンドポイント',
      'POWERBI_APIKEY': 'PowerBi ApiKey',
      'POWERBI_REPORTID': 'PowerBi レポートID',

      'DIFFERENCE_TYPE': '差異のタイプ',
      'POSITIVE': '正',
      'NEGATIVE': '負',

      'ENABLE_COPY': 'コピーを有効にする',

      'GENERAL': '一般情報',
      'CONFIGURATION': '設定',
      'TUNNEL': 'トンネル設定',

      'ENABLE_PENDING_IMPORT': '保留中のインポートを有効にする',

      'SELECT_MAX_REACHED': '30000行を超える選択はできません。',

      'SEND_COMPRESSED_REQUEST': '外部期待されるリクエストを求める際に圧縮リクエストを送信',

      'ENABLE_CLOSE_PACKAGE': 'パッケージが閉じている場合は、その中にピースを追加することができないようにパッケージを閉じるを有効にする',

      'SCANNER_LOCATION_MANDATORY': '目的地のロケーションは必須です',

      'DOC_REF_AUTO': '自動タスク参照',

      'ZONE_FILTERS': 'ゾーンフィルター',
      'USE_ZONE': 'タスクタイプでゾーンフィルターを使用',

      'STATUS_FILTERS': 'ステータスフィルター',
      'USE_STATUS': 'タスクタイプでステータスフィルターを使用',

      'ZONE_FILTER_TYPE': 'ゾーンフィルタータイプ',
      'STATUS_FILTER_TYPE': 'ステータスフィルタータイプ',
      'STATUS_FILTER': 'ステータスフィルター',

      'ZONE_TYPES': 'ゾーンタイプ',

      'ZONE_TYPE': 'ゾーンタイプ',

      'SHOW_EPC': 'EPCを表示',

      'GET_TASKS': 'タスクを取得',
      'DATE': '日付',

      'ENABLE_GET_TASKS': 'タスクの取得を有効にする',

      'ENABLE_CONNECT_PREVIOUS_TASK': '前のタスクとの接続を有効にする',

      'ZONE_FILTER': 'ゾーンフィルター',
      'EDIT_TASK': 'タスクを編集',

      'ENABLE_CLOSE_FROM_SCANNER': 'スキャナービューからタスクを閉じるを有効にする',

      'DEFAULT_STATUS': 'タスクが閉じられた時のデフォルトステータス',

      'POINT_OF_SALE': '販売時点情報管理',
      'POS': 'POS',
      'ENABLE_POS': 'POSを有効にする',
      'CAN_SELL': '販売可能',

      "EAS_ALARM": "EASアラーム",
      "SELECT_AN_EVENT_TO_CONTINUE": "続行するイベントを選択",
      "CHECKOUT": "チェックアウト",
      "POS_CART": "POSカート",
      "LOGOUT_POS": "POSからログアウト",
      "PRICE_LIST": "価格表",
      "DISCOUNT_PRICE": "割引価格",
      "PAY": "支払う",
      "DIFF": "差分",
      "CHANGE": "変更",
      "GIFT_CARD": "ギフトカード",
      "CONFIRM_CHECKOUT": "チェックアウトの確認",
      "SUM": "合計",
      "GIFT_NUMBER": "ギフト番号",
      "GIFT_NOT_FOUND": "ギフトが見つかりません",
      "NUMBER": "番号",
      "CLIENT": "クライアント",
      "IMPORT": "インポート",
      "CREATE_DATE": "作成日",
      "EXPIRE_DATE": "有効期限",
      "GIFT_IN_LIST": "リスト内のギフト",
      "USE": "使用",
      "SELECT_A_PAYMENT_TYPE": "支払いタイプを選択",
      "ITEM_INSERT": "アイテムを挿入",
      "NEW_TICKET": "新しいチケット",
      "SHARED_TICKET": "共有チケット",
      "DELETE_TICKET": "チケットを削除",
      "SELECT_DEPARTMENT_AND_POS": "部門とPOSを選択",
      "SELECT_DEPARTMENT_AND_POS_TO_CONTINUE": "続行するには部門とPOSを選択",
      "SURNAME": "姓",
      "PHONE": "電話",
      "FISCAL": "財務",
      "PERSON_COMPANY": "個人/会社",
      "COMPANY_NAME": "会社名",
      "COMPANY_VAT": "会社VAT",
      "COMPANY_FISCAL": "会社の財務コード",
      "CANCEL": "キャンセル",
      "CANCELLED": "キャンセル済み",
      "REMNANT": "残余",
      "TICKET_DATE": "チケットの日付",
      "GRAND_TOTAL": "総合計",
      "REPRINT": "再印刷",
      "REFUND": "払い戻し",
      "EDIT_TICKET": "チケットを編集",
      "FISCAL_CODE": "財務コード",
      "USER": "ユーザー",
      "PERSON": "人",
      "TICKET_REF": "チケット参照",
      "TICKET_NUMBER": "チケット番号",
      "FROM": "から",
      "GOTO_POS": "POSへ行く",
      "DESELECT_CUSTOMER": "顧客を削除",

      "SEND_DELTA": "部分的なクローズタスクでDELTAを送信してください。",

      "SEND_STOCK_ONCLOSE": "クローズタスクで在庫を送信",

      "GROUPBY_BARCODE": "バーコードによるグループ化",

      "SEND_EXPECTED_ONCLOSE": "タスクを終了するときに待機中のデータを送信する",

      "SEND_URL_ONCLOSE": "タスク終了時にURLを送信（タスクの詳細は送信されません）",

      "SEARCH_A_PRODUCT_TO_CONTINUE": "Search a Product to Continue"


  }
}
