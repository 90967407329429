export const nav = {
    'NAV': {
        'DASHBOARD': 'Dashboard',
        'TASKS': 'Tasks',
        'PLANNER': 'Planner',
        'STOCK': 'Stock',
        'STOCKZONE': 'Stock Zoning',
        'CUSTOMER_CONFIG_MENU': 'Customer Config',
        'CLIENTS': 'Clients',
        'DEPARTMENTS': 'Departments',
        'LOCATIONTYPES': 'Location Types',
        'DOCUMENTTYPES': 'Task Types',
        'PRODUCTS': 'Products',
        'ADMIN_CONFIG_MENU': 'Admin Config',
        'CUSTOMERS': 'Customers',
        'USERS': 'Users',
        'MENU': 'Menu',
        'REPORT': 'Report',
        'GROUPS': 'Groups',

        'PRINTER_SETTINGS': 'Printer Settings',
        'PRINTERS_AND_TEMPLATES': 'Printers and Templates',
        'PRINTERS': 'Printers',
        'ZPL_TEMPLATES': 'ZPL Templates',

        'SECURITY': 'Security',
        'EVENTS_DASHBOARD': 'Events Dashboard',

        'EXPORT_MODULE': 'Export module',
        'ENABLE_EXPORT': 'Enable Export:',
        'CONFIGURED_EXPORT': 'Configured Exports',
        'ADD': 'Add New',
        'TYPE': 'Type',
        'STS': 'Sts',
        'EXPORT_ENABLED': 'Export Enabled',
        'EXPORT_ENABLED_SINGLE': 'Export Enabled',
        'EXPORT_TYPE': 'Export Type',
        'ADDRESS': 'Address',
        'PORT': 'Port',
        'USERNAME': 'Username',
        'PASSWORD': 'Password',
        'SERVICE_NAME': 'ServiceName/Folder',
        'FILE_TYPE': 'File Type',
        'INTERVAL': 'Interval',
        'EDIT_EXPORT': 'Edit Export',
        'SAVE': 'Save',
        'PROFILE': 'Profile',
        'SIGNOUT': 'Sign Out',

        'SIGNIN': 'Sign In',
        'SIGNIN_MESSAGE': 'Sign in your account to continue',
        'FORGET_PASSWORD': 'Forget Password?',
        'NO_ACCOUNT': 'don\'t have an account?',
        'SIGNUP': 'Sign Up',
        'EMAIL': 'Email',

        'SIGNUP_MESSAGE': 'Sign up and create a new Account!',
        'NAME_SURNAME': 'Name and Surname',
        'CONFIRM_PASSWORD': 'Confirm Password',
        'ALREADY_ACCOUNT': 'Already have an account?',

        'USER': 'User',
        'NO_CUSTOMER': 'has no Customer',
        'CONTACT_ADMIN': 'Please contact your Administrator to configure your user.',



        'SETPASSWORD': 'Set Password',
        'SETPASSWORD_MESSAGE': 'Set a Password to enable your account',


        'CHANGEEXPIREDPASSWORD': 'Password Expired',
        'CHANGEEXPIREDPASSWORD_MESSAGE': 'Your Password is Expired. Please change it to logon',

        'CHANGEPASSWORD': 'Change Password',
        'CHANGEPASSWORD_MESSAGE': 'Create a new secure password'

    },
    'APP': {
      'SELECT_CUSTOMER': 'Select Customer',
      'SELECT_CUSTOMER_TO_CONTINUE': 'Select a Customer to continue',
      'CUSTOMER': 'Customer',
      'NO_CHOOSE': 'don\'t want to choose?',
      'SIGN_OUT': 'Sign Out',
      'INCOMING': 'Incoming Tasks',
      'OUTGOING': 'Outgoing Tasks',
      'FILTER_DATA': 'Type to filter data...',
      'ALL_TASKS': 'All Tasks',
      'PAST_7': 'Past 7 Days',
      'PAST_30': 'Past 30 Days',
      'PAST_60': 'Past 60 Days',
      'PAST': 'Past',
      'DAYS': 'Days',

      'LIST_OF': 'List of',
      'CLOSED': 'Closed',
      'OPEN': 'Open',
      'TASKS': 'Tasks',
      'OPEN_TASKS': 'Open Tasks',
      'CLOSED_TASKS': 'Closed Tasks',
      'PENDING_TASKS': 'Pending Tasks',
      'COPY_TASKS': 'Copy Tasks',
      'EXCEL_EXPORT': 'Excel Export',
      'REFERENCE': 'Reference',
      'STS': 'STS',
      'DEADLINE': 'Deadline',
      'DEPARTMENT': 'Department',
      'TASK_TYPE': 'Task Type',
      'PROGRESSIVE': 'Progressive',
      'EXPECTED': 'Expected',
      'READ': 'Read',
      'ACTIONS': 'Actions',

      'CLOSE': 'Close',
      'SAVE': 'Save',
      'DOCUMENT_FILTER': 'Document Filter',
      'DESTINATION_LOCATION': 'Destination Location',
      'DESTINATION': 'Destination',
      'DEADLINE_DATE': 'Deadline Date',
      'LOCATION_FILTER': 'Location Filter',

      'WARNING': 'Warning!',
      'SELECT2': 'Please select almost 1 task to use copy utility.',
      'SELECT1': 'Please select almost 1 row.',

      'DELETE': 'Delete',
      'CANNOT_UNDONE': 'This cannot be undone',

      'COPY_EXPECTED_DATA': 'Copy Expected Data',
      'COPY_READ_DATA': 'Copy Read Data',
      'COPY_OPTIONS': 'Copy Options',
      'CLOSE_COPIED_TASKS': 'Close Copied Tasks',
      'NEW_TASK_ATTRIBUTES': 'New Task Attributes',

      'ONLY_DIFFERENCE': 'Only Difference',
      'BACK': 'Back',
      'IMPORT_EXPECTED': 'Import Expected',
      'DELETE_SELECTED_READ': 'Delete Selected Read',
      'CLOSE_TASK': 'Close Task',
      'STATUS': 'Status',
      'PACKAGE_CODE': 'Package Code',
      'DESCRIPTION': 'Description',
      'DIFFERENCE': 'Difference',

      'DELETE_READ_FOR REFERNCE': 'Delete Read for Reference',
      'DELETE_READ_FOR REFERNCE2': 'Item still moved.<br/>Only Read difference will be deleted for Item ',
      'CONFIRM': 'Confirm',

      'CONFIRM_DELETE': 'Confirm Delete',
      'ROWS': 'Rows',
      'DELETE_SELECTED': 'Delete Selected',
      'CONFIRM_CLOSE': 'Confirm Close',
      'CLOSE_SELECTED': 'Close Selected',
      'TASK': 'Task',

      'IMPORT_TYPE': 'Import Type',
      'CHOOSE_A_FILE': 'Choose a File',

      'SEARCH': 'Search',
      'SELECT_A_TAG': 'Select a Tag',
      'SELECT_A_TAG_TO_CONTINUE': 'Select a Tag to Continue',

      'SELECT_A_DEPARTMENT': 'Select a Department',
      'SELECT_A_DEPARTMENT_TO_CONTINUE': 'Select a Department to Continue',
      'STOCK_DETAIL': 'Stock Detail',
      'LOCATION': 'Location',
      'UNITS': 'Units',

      'LIST_OF_CLIENTS': 'List of Clients',
      'SHOW_INACTIVE_CLIENTS': 'Show Inactive Clients',
      'SHOW_CUSTOMER_CLIENTS': 'Show Customer Clients',
      'NAME': 'Name',
      'ENABLED': 'Enabled',
      'DELETE_CLIENT': 'Delete Client',
      'CLIENT_ENABLED': 'Client Enabled',
      'DOCUMENT_TYPES': 'Document Types',

      'LIST_OF_DEPARTMENTS': 'List of Departments',
      'DELETE_DEPARTMENT': 'Delete Department',
      'CODE': 'Code',
      'ADD_LOCATIONS': 'Add Locations',
      'LOCATION_TYPES': 'Location Types',

      'BLACK_LIST': 'Black List',
      'DELETE_BLACK': 'Delete Black',

      'LIST_OF_CUSTOMERS': 'List of Customers',
      'EPC_ENCODE_TYPE': 'Epc Encode Type',
      'DELETE_CUSTOMER': 'Delete Customer',
      'BLACK_START': 'Black Start',
      'BLACK_END': 'Black End',

      'LIST_OF_DOCUMENT_TYPES': 'List of Task Types',
      'MASTER': 'Master',
      'CAN_OVERLOAD': 'Can Overload',
      'AS_EXPECTED': 'As Expected',
      'CAN_CHANGE_LOCATION': 'Can Change Location',
      'DELETE_TASK_TYPE': 'Delete task Type',

      'SELECT_TASKTYPE_TEMPLATE': 'Select Task Type Template',
      'SIGN': 'Sign',
      'AUTO_ACCEPT_TASK': 'Auto Accept Task',
      'CHECK_PACKAGE': 'Check Package',
      'RFID_POWER': 'RFID Power',
      'TASK_HAS_DEADLINE': 'Task has a Deadline',
      'IS_DEADLINE_EDITABLE': 'Is Deadline editable',
      'DEADLINE_DAYS': 'Deadline Days',
      'FILTERS': 'Filters',
      'STOCK_FILTER_TYPE': 'Stock Filter Type',
      'LOCATION_TYPE_FILTER': 'Location Types Filter',

      'FILE_FORMAT': 'File Format',

      'COLUMN_LIST': 'Column List',
      'SEPARATOR': 'Separator',
      'HEADER': 'File has Header',

      'IMPORT_FROM_CSV': 'Import From CSV',

      'VALUE': 'Value',
      'START': 'Start',
      'END': 'End',

      'LIST_OF_LOCATIONS': 'List of Locations',
      'LOCATION_TYPE': 'Location Type',

      'LIST_OF_LOCATION_TYPES': 'List of Location Types',
      'SELECTABLE': 'Selectable',
      'LOCATION_TYPE_IS_SELECTABLE': 'Location Type is Selectable',

      'LIST_OF_PRODUCTS': 'list of Products',
      'SHORT_DESCRIPTION': 'Short Description',
      'LONG_DESCRIPTION': 'Long Description',

      'UPLOAD': 'Upload',

      'STATUS_LIST': 'Status List',
      'DEFAULT': 'Default',
      'STATUS_TYPE': 'Status Type',
      'STATUS_WILL_BE_SET_AS_DEFAULT': 'Status will be set as Default',

      'LIST_OF_USERS': 'List of Users',
      'ROLE': 'Role',
      'DEPARTMENTS': 'Departments',

      'TASK_TYPE_DESTINATION': 'Task Type Destination',
      'TASKTYPE_INFO': 'Task Type Info',

      'MAX_SERIAL': 'Max Serial allowed',
      'CAN_ADD_PRODUCT': 'Customer can add products from Web and Mobile',
      'PRODUCT_PROPERTIES': 'Products Properties',
      'BARCODE_MASK': 'Barcode Mask',
      'REPLACE_MASK': 'Replace Mask',

      'PRODUCT_DEFAULT_DESCRIPTION': 'Default Description',

      'DEPARTMENT_STOCK': 'Department Stock',
      'GOTOSTOCK': 'Go to Stock',

      'SHOW_SERIALS': 'Show Product Serials',
      'PRODUCT_DETAILS': 'Product Serials',
      'EPC': 'Epc',
      'LOADWAREHOUSE': 'Write EPC or Match Serial add in Warehouse',

      'TASKS_TOTAL': 'Total Tasks',

      'SELECT_A_REPORT': 'Select a Report',
      'SELECT_A_REPORT_TO_CONTINUE': 'Select a Report to Continue',
      'AVG_STAY': 'Average Stay',
      'TREND': 'Trend',
      'STOCK': 'Stock',
      'EXPIRED': 'Expired',

      'EXPIRATION_DATE': 'Expiration Date',
      'DOC_REF': 'Task Reference',
      'ITEM_REFERENCE': 'Item Reference',
      'ARRIVAL_DATE': 'Arrival Date',


      'GROUPS': 'Groups',
      'GROUP': 'Group',

      'PRINT': 'Print',
      'PRINTABLE': 'Task is Printable',
      'ALLOW_MULTIPLE_PRINT': 'Allow Multiple Print',
      'ORDER_BY_FIELDS': 'Order By Fields',
      'EXPORT': 'Export',
      'EXPORT_TO_CUSTOMER': 'Export to Customer',

      'PRINT_SELECTED': 'Print Selected',

      'GENERATE_INVENTORY': 'Generate Inventory',
      'EXPECTED_TYPE': 'Expected Type',

      'PRINTERS': 'Printers',
      'IP': 'Ip',
      'PORT': 'Port',
      'PRINTER_TYPES': 'Printer Type',

      'LIST_OF_TEMPLATES': 'ZPL Templates',
      'BREAKPOINT_LABEL': 'Breakpoint Label',
      'ZPL': 'ZPL',

      'PRINTER': 'Printer',
      'TEMPLATE': 'ZPL Template',
      'GENERAT_ZPL_AND_DOWNLOAD': 'Generate ZPL and Download',

      'SHOW_ZERO': 'Show not in Stock Items',
      'IMAGES': 'Images',

      'UNKNOWN_IMAGE': 'Image for Unknown Items',
      'DEFAULT_IMAGE': 'Default Image',


      'UNKNOWN_TEXT_COLOR': 'Text Color for Unknown Items',
      'ENABLE_KILL': 'Enable Kill in Tag Management (Mobile)',
      'CAN_ADD_DEPARTMENT': 'Can Add Department from Mobile App',

      'GROUPBY': 'Group By',
      'GROUPBY_REF': 'Group By Reference',
      'GROUPBY_PACK': 'Group By Package Code',
      'GROUPBY_EPC': 'Group By Epc',

      'CUSTOMER_DETAIL': 'Customer Detail',
      'PERSONAL': 'Personal Data',
      'ENCODING': 'Encoding',
      'TAG_MANAGMENT': 'Tag Management',
      'PRODUCTS': 'Products',

      'SUCCESS': 'Success',
      'SAVED': 'All changes have been saved',

      'EAN13': 'Barcode',
      'COMPANY_PREFIX': 'Company Prefix Length (0 if item\'s barcode is not EAN13)',

      'SELECT_AN_EVENT': 'Select Event',
      'SELECT_AN EVENT_TO_CONTINUE': 'Select Event to Continue',
      'EPC_TIMELINE': 'EPC Timeline',

      'NO_EAS_ALARM': 'No Events to show',

      'EAS_SERVER': 'Eas Server',
      'ENABLE_SECURITY': 'Enable Security Dashboard',

      'GOTO_DASH': 'Back to Dashboard',

      'IMAGE': 'Image',
      'SECURITY_LASTHOURS': 'Show Security Alarms from last *n hours',
      'ENABLE_QUALITY': 'Download Products from Quality',
      'QUALITY_ENDPOINT': 'Quality Endpoint',
      'QUALITY_USERNAME': 'Quality Username',
      'QUALITY_PASSWORD': 'Quality Password',
      'ENABLE_GET_IMAGE': 'Download GetImage products',
      'GET_IMAGE_ENDPOINT': 'Get image Endpoint',
      'GET_IMAGE_USERNAME': 'Get image Username',
      'GET_IMAGE_PASSWORD': 'Get image Password',

      'ITEM': 'Item',
      'POE': 'Point of Exit',
      'TIMESTAMP': 'Time Stamp',

      'PASSWORD_RECOVERY': 'Password Recovery',
      'PASSWORD_RECOVERY_MESSAGE': 'Insert your Email to recover your password',

      'TOTAL': 'Totals',
      'TOTAL_READ': 'Read Totals',
      'TOTAL_EXP': 'Expected Totals',
      'TOTAL_DIFF': 'Difference Totals',

      'SEND_TO_EAS': 'Send data to EAS Server',
      'EAS_TOPIC': 'EAS Server MQTT Topic',
      'CUSTOMER_EAS_WARNING': 'All Departments data will be sent to this Topic.',

      'CLEAR_EVENTS': 'Clear All Events',
      'CLEAR': 'Clear',

      'LIST_OF_REASONS': 'List of Reasons',
      'REASON': 'Reason',
      'REASONS': 'Reasons',

      'ENABLE_MQTT_RESPONSE': 'Enable MQTT Response',


      'REQUIRED': 'Required',
      'REASON_MESSAGE': 'You need to select a Reason',

      'CUSTOMER_ROLES': 'Customer Roles',
      'ROLE_AUTH': 'Role Authorization',
      'ENABLE_WRITE': 'Enable Write/Match in Tag Management (Mobile)',
      'ENABLE_STATUS': 'Enable Change Status in Tag Management (Mobile)',
      'ENABLE_ZONE': 'Enable Change Zone in Tag Management (Mobile)',

      'ENABLE_PRINT': 'Enable Print (Web / Mobile)',
      'ENABLE_ADDTASK': 'Enable Add New Task (Web / Mobile)',
      'ENABLE_CLOSETASK': 'Enable Close Task (Web / Mobile)',
      'ENABLE_DELETETASK': 'Enable Delete Task (Web)',
      'ENABLE_COPYTASK': 'Enable Copy Task / Merge Task (Web / Mobile)',
      'ENABLE_CHANGEFILTER': 'Enable Change Filters and Options (Mobile)',

      'LOGIN_IS_MANDATORY': 'Login is Mandatory',
      'CUSTOMER_ROLE': 'Customer Role',

      'CANNOT_PERFORM_ACTION': 'Your Role cannot perform this Action',

      'DOC_REFERENCE': 'Document Reference',

      'DISABLE_PARTIAL_MOVEMENT': 'Disable Partial Movements',
      'AUTOMATIC_PARTIAL_MOVEMENT': 'Partial Movements is Automatic',

      'MOVED': 'Moved',

      'ENABLE_SCANPRINT': 'Enable ScanPrint and ScanScanWrite',

      'USE_SENDER_RECIPENT': 'Task use Sender/Recipient',

      'TYPES': 'Type',
      'TIMEZONE': 'Timezone',
      'WORKING_DEPARTMENT': 'Working Department',

      'SENDER': 'Sender',
      'RECIPIENT': 'Recipient',

      'CHECK_PACKAGE_MANDATORY': 'Check Package is Mandatory',
      'SEND_SNAPSHOT': 'Send Snapshot to ',

      'WAITING_FOR_EXPECTED': 'Waiting for Expected',


      'PENDING_MESSAGE': 'This Task is in Pending Status. It will be avaiable soon.',

      'TASK_PENDING': 'Task in Pending',

      'NOTE': 'Notes',

      'SELECTED_PRODUCTS': 'Selected Items',
      'SHOW_HIDE_PRODUCTS': 'Show / Hide Items',
      'ADVANCED_SEARCH': 'Advanced Search',

      'MODEL': 'Model',
      'PART': 'Part',
      'VARIANT': 'Variant',
      'BRAND': 'Brand',
      'GENDER': 'Gender',
      'OUTLINE': 'Outline',
      'TARGET': 'Target',
      'SEASON': 'Season',
      'MATERIAL': 'Material',
      'COLOUR': 'Colour',
      'SIZE': 'Size',
      'SHOW_OTHERS': 'Show / Hide others...',

      'CANNOT_ADD': 'Cannot Add Task from Web',
      'CANNOT_ADD_MOBILE': 'Cannot Add Task from Mobile',


      'VIEW_TYPE': 'View Type',

      'MAIN': 'Main',

      'SECONDARY_BARCODES': 'Secondary Barcodes',

      'SHOW_HIDE_MORE': 'Show/Hide more options',

      'CERT_PATH': 'Certificate Path',
      'KEY_PATH': 'Private Key Path',
      'MQTT_TYPE': 'MQTT SDK Used',

      'FROM_DATE': 'From Date',
      'TO_DATE': 'To Date',
      'DATE_RANGE': 'Date Range',

      'STARTUP_SNAPSHOT': 'Execute Snapshot on Mobile Startup',

      'ENABLE_POOLING': 'Enable Pooling for Dashboard Notification',
      'POOLING_INTERVAL': 'Pooling Interval in Seconds',

      'LOCTYPE_FILTER_MANDATORY': 'Location Type Filter is Mandatory',

      'CAN_REMOVE_READ': 'Can Remove Read with RFID Reader',

      'LIST_OF_ZONES': 'Zones List',
      'ZONE_WILL_BE_SET_AS_DEFAULT': 'Zone will be set as Default',

      'BARCODE': 'Barcode',
      'ZONE': 'Zone',
      'ZONE_DETAIL': 'Zone Detail',

      'NOTE_MANDATORY': 'Notes are mandatory',
      'SHOW_TASK_DETAIL': 'Show Task Detail',
      'DOC_DATE': 'Task Date',
      'EDIT_NOTE': 'Edit Note',
      'LIST_OF_RELATIONS': 'Relations List',
      'RELATION': 'Relation',
      'ALL': 'All',

      'DEPARTMENT_TYPES': 'Department Groups',
      'DEPARTMENT_TYPE': 'Department Group',

      'DEPARTMENT_CATEGORY': 'Department Category',

      'DEPARTMENT_CATEGORIES': 'Department Categories',
      'PREVIOUS': 'To First Category',

      'INVENTORY_ADJUSTMENT': 'Inventory Adjustment',

      'PACK_COMPLIANCE': 'Package Name must be compliance',
      'DOC_DATE_EDITABLE': 'Doc Date is Editable',

      'PASSWORD_POLICIES': 'Password Policies',
      'EXPIRATION_DAYS': 'Expiration Days',
      'FAILED_ATTEMPTS': 'Failed Attempts',
      'MIN_LENGTH': 'Min length',
      'MAX_LENGTH': 'Max length',
      'MIN_PASSWORD_AGE': 'Minimum Password Age',
      'MASK': 'Mask',

      'MUST_HAVE_SMALLCASE': 'Must contain at least 1 Letter in Small Case',
      'MUST_HAVE_CAPITALCASE': 'Must contain at least 1 in Capital Case',
      'MUST_HAVE_NUMBER': 'Must contain at least 1 number',
      'MUST_HAVE_SPECIAL_CHARACTER': 'Must contain at least 1 Special Character',

      'ENABLE_CEGID': 'Enable Cegid Features',

      'CEGID_EXPORT': 'Cegid Export',

      'ACTIVEDIRECTORY': 'Active Directory',

      'PACKAGE': 'Package Config',
      'PACKAGE_CHECK_TYPE': 'Check Type on Expected/Read Package Count',

      'INVERTED_DIFFERENCES': 'Inverted Differences on Tasks (Expected-Read --> Read-Expected)',

      'FLOW_CONFIG': 'Task Flow Config',
      'TASK_TYPE_TO_CREATE': 'New Task Type to create on Task Close',
      'CREATE_NEWDOC_WITH_ONLY_DIFF': 'Create New Task with Only Difference',

      'ASKCLOSE_TASK': 'Ask for Reading from Mobile',

      'ASK4READ': 'Ask for Reading from Mobile for Task ',
      'ASK4READ_BUTTON': 'Ask for Reading',

      'SAVED_PASSWORD': 'Number of Password saved for User',

      'RESET': 'Reset Filters',

      'PLAY_SOUND': 'Play Sound on Device ',

      'VERSION': 'Release',

      'PLAN_TASK': 'Plan Task',
      'ALL_DAY': 'All Day Task',
      'TASK_DATE': 'Start Date',

      'REPEAT': 'Repeat',

      'DISABLE_SORT': 'Disable "Sort List by Power" on Mobile',
      'DISABLE_CUSEPC': 'Disable "Read only Customer EPC" on Mobile',
      'DISABLE_ENTER': 'Disable "Automatic \'Enter\' send when Regex Match" on Mobile',
      'DEFAULT_VALUE': 'Default Value',

      'SETTINGS': 'Settings',

      'UNIQUE_REFERENCE': 'Task Reference is Unique Key (only API)',
      'DOC_EXCLUSIVE': 'Task cannot be worked from more than 1 client',
      'SEND_START': 'Send Message when enter in Task',
      'SEND_END': 'Send Message when exit from Task',

      'SHOW_STOCK': 'Show Stock in Product Detail',

      'TASK_TYPE_CATEGORY': 'Task Type Category',

      'COUNTRY': 'Country',
      'STATE': 'State',
      'DISTRICT': 'District',
      'CITY': 'City',
      'ADDRESS': 'Complete Address',

      'SHOW_PIVOT': 'Show Pivot Table',
      'SIZE_GROUPS': 'Size Groups',
      'SELECT_SIZE_GROUP': 'Select Size Group',

      'SEND_UNLOCK': 'Send Unlock',

      'EXPORT_ENVIRONMENTS': 'Export Environments',

      'EXPORT_ENVIRTMENT': 'Export Environment',

      'BUSINESS_INTELLIGENCE': 'T-Insight BI',
      'ENABLE_POWERBI_EMBEDDED': 'Enable T-Insight PowerBi Embedded',
      'POWERBI_ENDPOINT': 'T-Insight PowerBi Endpoint',
      'POWERBI_APIKEY': 'T-Insight PowerBi ApiKey',
      'POWERBI_REPORTID': 'T-Insight PowerBi ReportId',

      'DIFFERENCE_TYPE': 'Difference Type',
      'POSITIVE': 'Positive',
      'NEGATIVE': 'Negative',

      'ENABLE_COPY': 'Enable Copy',

      'GENERAL': 'General Information',
      'CONFIGURATION': 'Configuration',
      'TUNNEL': 'Tunnel Configuration',

      'ENABLE_PENDING_IMPORT': 'Enable Pending Import',

      'SELECT_MAX_REACHED': 'Could not select more than 30000 rows.',

      'SEND_COMPRESSED_REQUEST': 'Send Compressed Request when Asking External Expected',

      'ENABLE_CLOSE_PACKAGE': 'Enable Close Package (When Package is closed is not possible to add pieces in it)',

      'SCANNER_LOCATION_MANDATORY': 'Destination Location is Mandatory',

      'DOC_REF_AUTO': 'Automatic Task Reference',

      'ZONE_FILTERS': 'Zone Filters',
      'USE_ZONE': 'Task Type use Zone Filters',


      'STATUS_FILTERS': 'Status Filters',
      'USE_STATUS': 'Task Type use Status Filters',

      'ZONE_FILTER_TYPE': 'Zone Filter Type',
      'STATUS_FILTER_TYPE': 'Status Filter Type',
      'STATUS_FILTER': 'Status Filter',

      'ZONE_TYPES': 'Zone Types',

      'ZONE_TYPE': 'Zone Type',

      'SHOW_EPC': 'Show Epc',

      'GET_TASKS': 'Get Tasks',
      'DATE': 'Date',

      'ENABLE_GET_TASKS': 'Enable Get Tasks',

      'ENABLE_CONNECT_PREVIOUS_TASK': 'Enable Connect Previous Task',

      'ZONE_FILTER': 'Zone Filter',
      'EDIT_TASK': 'Edit Task',

      'ENABLE_CLOSE_FROM_SCANNER': 'Enable Close Task from Scanner View',

      'DEFAULT_STATUS': 'Default Status when Task is closed',

      'POINT_OF_SALE': 'Point of Sale',
      'POS': 'PoS',
      'ENABLE_POS': 'Enable PoS',
      'CAN_SELL': 'Can Sell',

      "EAS_ALARM": "EAS Alarm",
      "SELECT_AN_EVENT_TO_CONTINUE": "Select an event to continue",
      "CHECKOUT": "Check-out",
      "POS_CART": "POS Cart",
      "LOGOUT_POS": "Logout POS",
      "PRICE_LIST": "Price list",
      "DISCOUNT_PRICE": "Discount price",
      "PAY": "Pay",
      "DIFF": "Diff",
      "CHANGE": "Change",
      "GIFT_CARD": "Gift card",
      "CONFIRM_CHECKOUT": "Confirm check-out",
      "SUM": "Sum",
      "GIFT_NUMBER": "Gift number",
      "GIFT_NOT_FOUND": "Gift not found",
      "NUMBER": "Number",
      "CLIENT": "Client",
      "IMPORT": "Import",
      "CREATE_DATE": "Create date",
      "EXPIRE_DATE": "Expire date",
      "GIFT_IN_LIST": "Gift in list",
      "USE": "Use",
      "SELECT_A_PAYMENT_TYPE": "Select a payment type",
      "ITEM_INSERT": "Insert item",
      "NEW_TICKET": "New ticket",
      "SHARED_TICKET": "Shared ticket",
      "DELETE_TICKET": "Delete ticket",
      "SELECT_DEPARTMENT_AND POS": "Select department and POS",
      "SELECT_DEPARTMENT_AND_POS_TO_CONTINUE": "Select department and POS to continue",
      "SURNAME": "Surname",
      "PHONE": "Phone",
      "FISCAL": "Fiscal",
      "PERSON_COMPANY": "Person/Company",
      "COMPANY_NAME": "Company name",
      "COMPANY_VAT": "Company VAT",
      "COMPANY_FISCAL": "Company fiscal code",
      "CANCEL": "Cancel",
      "CANCELLED": "Cancelled",
      "REMNANT": "Remnant",
      "TICKET_DATE": "Ticket date",
      "GRAND_TOTAL": "Grand total",
      "REPRINT": "Reprint",
      "REFUND": "Refund",
      "EDIT_TICKET": "Edit ticket",
      "FISCAL_CODE": "Fiscal code",
      "USER": "User",
      "PERSON": "Person",
      "TICKET_REF": "Ticket ref",
      "TICKET_NUMBER": "Ticket number",
      "FROM": "From",
      "GOTO_POS": "Go to POS",
      "DESELECT_CUSTOMER": "Remove Customer",

      "SEND_DELTA": "Send DELTA in partial close task",

      "SEND_STOCK_ONCLOSE": "Send Stock Data on Task Close",
      "GROUPBY_BARCODE": "Group by Barcode",
      "SEND_EXPECTED_ONCLOSE": "Send Expected Data on Task Close",

      "SEND_URL_ONCLOSE": "Send Url on Task Close (Task Detail will not be sent)",

      "SEARCH_A_PRODUCT_TO_CONTINUE": "Search a Product to Continue",

      "POS_CONFIG": "PoS Config",
      "JASPER_REPORT": "Jasper Report",
      "PRINT_TYPE": "Print Type",
      "POSITIONAL_INVENTORY": "Positional Inventory",
      "USE_POSITIONAL_SEPARATOR": "Use Positional Inventory",
      "POSITION_SIZE": "Position Size",
      "POSITION_SEPARATOR": "Position Separator",
      "TASK_IS_NOT_INVENTORY": "Task Type is not Inventory",

      "USE_PACK_GENERATOR": "Use Package Generator",
      "PACK_MASK": "Pack Mask",

      "NO_CUSTOMER_SELECTED": "No Customer Selected",
      "STATUS_PRINTER": "Printer Status",
      "RESET_PRINTER": "Reset Printer",
      "SELECT_DEPARTMENT_AND_POS": "Select Department and PoS",
      "PRINTER_STATUS_OK": "Printer Status OK",
      "PRINTER_PAPER_EMPTY": "Printer Paper Empty",
      "PRINTER_PAPER_NEAR_EMPTY": "Printer Paper Near Empty",
      "PRINTER_COVER_OPEN": "Printer Cover Open",


      "MOVEMENT_DETAIL": "Movement Detail",
      "MOVEMENT_INCOMING": "Movement Incoming",
      "ANALYSIS": "Analysis",
      "CLOSE_CASH": "Close Cash",
      "SELECT_A_TICKET": "Select a Ticket",
      "HISTORY": "History",
      "CHANGE_ITEM": "Change Item",
      "PARTIAL_REFUND": "Partial refund",

      "PAYMENT": "Payment",

      "SCAN": "Scan",

      "GIFT_NOT_VALID": "Gift not Valid",




        "ABORT": "Abort",
        "ADVANCED": "Advanced",
        "AMOUNT": "Amount",
        "AMOUNT_AVERAGE": "Average Amount",
        "AMOUNT_MONEY": "Amount Money",
        "AVERAGE": "Average",
        "BALANCE": "Balance",
        "BREAK_FIELDS": "Break Fields",
        "CANCEL_TICKET": "Cancel Ticket",
        "CAN_BE_USED_ON_DISCOUNT": "Can Be Used on Discount",
        "CAN_PRINT_PRODUCT": "Can Print Product",
        "CARD": "Card",
        "CASH": "Cash",
        "CASHIER": "Cashier",
        "CASH_CLOSING": "Cash Closing",
        "CASH_CLOSING_SEARCH": "Cash Closing Search",
        "CHECKOUT_NO_GIFT": "Checkout No Gift",
        "CHECKOUT_NO_PRINTER": "Checkout No Printer",
        "CLOSED_CASH_OLD_LABEL": "Closed Cash Old Label",
        "CLOSED_CASH_OLD_TITLE": "Closed Cash Old Title",
        "CONFIG": "Config",
        "CONFIRM_CHECKOUT_MONEY": "Confirm Checkout Money",
        "COUNT": "Count",
        "CREATE_GIFT": "Create Gift",
        "CREATE_TIME": "Create Time",
        "CURRENCY": "Currency",
        "CUSTOM_ACTION": "Custom Action",
        "CUSTOM_ACTION_URL": "Custom Action URL",
        "CUSTOM_VALUE": "Custom Value",
        "DATA_OPERATION": "Data Operation",
        "DEFAULT_LABEL": "Default Label",
        "DELETE_READ_FOR": "Delete Read For",
        "DELETE_TICKET_CONFIRM": "Delete Ticket Confirm",
        "DISCOUNTS": "Discounts",
        "EAN": "EAN",
        "ENABLE_CUSFILTER": "Enable Customer Filter",
        "ENABLE_DEFAULTSEARCH": "Enable Default Search",
        "ENABLE_FILTER": "Enable Filter",
        "ENABLE_GIFTLIST": "Enable Gift List",
        "ENABLE_KB_KEYBOARD": "Enable KB Keyboard",
        "ENABLE_LISTSELECT": "Enable List Select",
        "ENABLE_PIECHART": "Enable Pie Chart",
        "ENABLE_READ": "Enable Read",
        "ENABLE_RESEND": "Enable Resend",
        "ENABLE_ROBOT": "Enable Robot",
        "ENABLE_SUSPEND": "Enable Suspend",
        "ENABLE_TASKLIST": "Enable Task List",
        "ENABLE_USERFILTER": "Enable User Filter",
        "ENABLE_VISIBILITYFILTER": "Enable Visibility Filter",
        "END_DATE": "End Date",
        "END_TIME": "End Time",
        "ERROR": "Error",
        "ESTIMATED": "Estimated",
        "ESTIMATED_DATE": "Estimated Date",
        "ESTIMATED_TIME": "Estimated Time",
        "ESTIMATION": "Estimation",
        "ETD": "ETD",
        "EVENTS": "Events",
        "EXCEPTION": "Exception",
        "EXCLUDE_PARTIAL": "Exclude Partial",
        "EXPECTED_DATE": "Expected Date",
        "EXPECTED_END": "Expected End",
        "EXPECTED_EVENT": "Expected Event",
        "EXPECTED_TIME": "Expected Time",
        "EXPORT_ENABLED": "Export Enabled",
        "EXPORT_LABEL": "Export Label",
        "EXPORT_PATH": "Export Path",
        "EXPORT_TYPE": "Export Type",
        "EXTERNAL_REFERENCE": "External Reference",
        "EXTRACTION_ERROR": "Extraction Error",
        "FILTER": "Filter",
        "FILTER_ENABLED": "Filter Enabled",
        "FILTER_LIST": "Filter List",
        "FILTER_REQUIRED": "Filter Required",
        "FILTER_SELECT": "Filter Select",
        "FINISHED": "Finished",
        "FLAGGED": "Flagged",
        "FLUSH": "Flush",
        "FOOTER": "Footer",
        "FORMAT": "Format",
        "FROM_LOCATION": "From Location",
        "FROM_TASK": "From Task",
        "FULL_TEXT_SEARCH": "Full Text Search",
        "GENERATE": "Generate",
        "GENERATE_DOC": "Generate Document",
        "GENERATE_NEW": "Generate New",
        "GENERATE_REPORT": "Generate Report",
        "GENERATE_SELECTED": "Generate Selected",
        "GIFT": "Gift",
        "GIFT_ID": "Gift ID",
        "GIFT_LABEL": "Gift Label",
        "GIFT_LIST": "Gift List",
        "GIFT_REPORT": "Gift Report",
        "GIFT_TYPE": "Gift Type",
        "GO": "Go",
        "GOAL": "Goal",
        "GROUP_LIST": "Group List",
        "GROUP_TYPE": "Group Type",
        "HISTORY_LOG": "History Log",
        "HOUR": "Hour",
        "HOURS": "Hours",
        "IMPORT_CONFIG": "Import Config",
        "INCLUDE_PARTIAL": "Include Partial",
        "INCOMPLETE": "Incomplete",
        "INCORRECT_DATA": "Incorrect Data",
        "INDIVIDUAL": "Individual",
        "INDIVIDUAL_TASKS": "Individual Tasks",
        "INFO": "Info",
        "INFORMATION": "Information",
        "INSERT": "Insert",
        "INSERT_GIFT": "Insert Gift",
        "INSERT_TASK": "Insert Task",
        "INSERT_TICKET": "Insert Ticket",
        "INTELLIGENCE": "Intelligence",
        "ITEMS": "Items",
        "ITEM_ID": "Item ID",
        "ITEM_NAME": "Item Name",
        "ITEM_PRICE": "Item Price",
        "ITEMS_LIST": "Items List",
        "ITEMS_SELECTED": "Items Selected",
        "ITEM_SELECT": "Item Select",
        "JOB": "Job",
        "JOB_LIST": "Job List",
        "JOB_TYPE": "Job Type",
        "KEYBOARD": "Keyboard",
        "KEYBOARD_BUTTON": "Keyboard Button",
        "KNOWLEDGE": "Knowledge",
        "LABEL": "Label",
        "LABELS": "Labels",
        "LANGUAGE": "Language",
        "LANGUAGE_LIST": "Language List",
        "LAST_CHANGE": "Last Change",
        "LAST_EVENT": "Last Event",
        "LAST_GENERATED": "Last Generated",
        "LAST_MODIFIED": "Last Modified",
        "LAST_STATUS": "Last Status",
        "LATENCY": "Latency",
        "LATEST": "Latest",
        "LEVEL": "Level",
        "LIMIT": "Limit",
        "LIMITED": "Limited",
        "LINK": "Link",
        "LIST": "List",
        "LIST_DATE": "List Date",
        "LIST_MODE": "List Mode",
        "LOCAL": "Local",
        "LOCATION_LIST": "Location List",
        "LOG": "Log",
        "LOGS": "Logs",
        "LOG_LIST": "Log List",
        "LOGIN": "Login",
        "LOGOUT": "Logout",
        "MANAGE": "Manage",
        "MANAGER": "Manager",
        "MANUAL": "Manual",
        "MARK_AS_READ": "Mark as Read",
        "MAX": "Max",
        "MAX_ITEMS": "Max Items",
        "MAX_PRICE": "Max Price",
        "MEASURE": "Measure",
        "MEASURES": "Measures",
        "MEMBERS": "Members",
        "MENU": "Menu",
        "MESSAGE": "Message",
        "MESSAGE_CENTER": "Message Center",
        "MESSAGE_LIST": "Message List",
        "MESSAGE_TYPE": "Message Type",
        "MESSAGES": "Messages",
        "MESSAGES_LIST": "Messages List",
        "MESSAGE_TYPE_LIST": "Message Type List",
        "MIN": "Min",
        "MIN_ITEMS": "Min Items",
        "MIN_PRICE": "Min Price",
        "MINUTES": "Minutes",
        "MODE": "Mode",
        "MODIFY": "Modify",
        "MONTH": "Month",
        "MONTHS": "Months",
        "MORE": "More",
        "MOVE": "Move",
        "MOVE_DOWN": "Move Down",
        "MOVE_ITEM": "Move Item",
        "MOVE_UP": "Move Up",
        "MULTIPLE": "Multiple",
        "NAMES": "Names",
        "NAVIGATE": "Navigate",
        "NEW": "New",
        "NEW_ITEM": "New Item",
        "NEW_LIST": "New List",
        "NEW_MESSAGE": "New Message",
        "NEW_REPORT": "New Report",
        "NEW_TASK": "New Task",
        "NO": "No",
        "NO_ITEMS": "No Items",
        "NO_RESULTS": "No Results",
        "NOTES": "Notes",
        "NOTIFICATION": "Notification",
        "NOTIFICATION_LIST": "Notification List",
        "NOTIFY": "Notify",
        "NUMBER_ITEMS": "Number of Items",
        "NUMBER_SELECTED": "Number Selected",
        "OFF": "Off",
        "OFFLINE": "Offline",
        "OFFSET": "Offset",
        "OLD": "Old",
        "OLD_DATE": "Old Date",
        "OLD_LIST": "Old List",
        "OLD_MODE": "Old Mode",
        "OLD_TASK": "Old Task",
        "OLD_TICKET": "Old Ticket",
        "ON": "On",
        "ONLINE": "Online",
        "OPENED": "Opened",
        "OPEN_ITEM": "Open Item",
        "OPEN_LIST": "Open List",
        "OPEN_MESSAGE": "Open Message",
        "OPEN_REPORT": "Open Report",
        "OPEN_TASK": "Open Task",
        "OPEN_TICKET": "Open Ticket",
        "OPERATION": "Operation",
        "OPTION": "Option",
        "OPTIONS": "Options",
        "ORDER": "Order",
        "ORDERS": "Orders",
        "ORDER_DATE": "Order Date",
        "ORDER_LIST": "Order List",
        "ORDER_MODE": "Order Mode",
        "ORDER_NUMBER": "Order Number",
        "ORDER_STATUS": "Order Status",
        "ORDER_TYPE": "Order Type",
        "OVERWRITE": "Overwrite",
        "PACKAGE_LIST": "Package List",
        "PACKAGE_TYPE": "Package Type",
        "PACKAGE_WEIGHT": "Package Weight",
        "PAGE": "Page",
        "PAGES": "Pages",
        "PARAMETER": "Parameter",
        "PARAMETERS": "Parameters",
        "PARAM_LIST": "Parameter List",
        "PASSWORD": "Password",
        "PAUSE": "Pause",
        "PAUSE_TASK": "Pause Task",
        "PAYMENTS": "Payments",
        "PAYMENT_METHOD": "Payment Method",
        "PAYMENT_MODE": "Payment Mode",
        "PAYMENT_TYPE": "Payment Type",
        "PDF": "PDF",
        "PDF_TEMPLATE": "PDF Template",
        "PDF_TEMPLATES": "PDF Templates",
        "PENDING": "Pending",
        "PERFORM": "Perform",
        "PERFORM_ACTION": "Perform Action",
        "PERFORM_TASK": "Perform Task",
        "PERIOD": "Period",
        "PERIODS": "Periods",
        "PHASE": "Phase",
        "PHASES": "Phases",
        "PHONE_NUMBER": "Phone Number",
        "PIE_CHART": "Pie Chart",
        "PIN": "Pin",
        "PIN_CODE": "Pin Code",
        "POPUP": "Popup",
        "POSITION": "Position",
        "POSITION_LIST": "Position List",
        "PRICE": "Price",
        "PRICE_TYPE": "Price Type",
        "PRIMARY": "Primary",
        "PRINTED": "Printed",
        "PRINTER_CONFIG": "Printer Config",
        "PRINTER_LIST": "Printer List",
        "PRINTER_TYPE": "Printer Type",
        "PRINT_ITEM": "Print Item",
        "PRINT_LABEL": "Print Label",
        "PRINT_TICKET": "Print Ticket",
        "PRIVATE": "Private",
        "PROCESS": "Process",
        "PROCESSED": "Processed",
        "PRODUCT": "Product",
        "PRODUCT_ID": "Product ID",
        "PRODUCT_NAME": "Product Name",
        "PRODUCT_NUMBER": "Product Number",
        "PRODUCT_PRICE": "Product Price",
        "PRODUCT_REFERENCE": "Product Reference",
        "PRODUCTS_LIST": "Products List",
        "PRODUCT_LIST": "Product List",
        "PROGRESS": "Progress",
        "PROJECT": "Project",
        "PROJECT_LIST": "Project List",
        "PROJECT_NAME": "Project Name",
        "PROMOTIONS": "Promotions",
        "PUBLIC": "Public",
        "QUANTITY": "Quantity",
        "QUANTITY_TOTAL": "Quantity Total",
        "RATE": "Rate",
        "READ_DATE": "Read Date",
        "READ_STATUS": "Read Status",
        "READ_TIME": "Read Time",
        "REAL": "Real",
        "REASON_LIST": "Reason List",
        "REF": "Reference",
        "REFERENCE_ID": "Reference ID",
        "REF_ID": "Reference ID",
        "REF_TYPE": "Reference Type",
        "REGION": "Region",
        "REJECT": "Reject",
        "REJECTED": "Rejected",
        "REMARK": "Remark",
        "REMARKS": "Remarks",
        "REMINDER": "Reminder",
        "REMOVE": "Remove",
        "REPETITIONS": "Repetitions",
        "REPORT": "Report",
        "REPORTS": "Reports",
        "REPORT_DATE": "Report Date",
        "REPORT_NAME": "Report Name",
        "REPORT_TYPE": "Report Type",
        "RESOURCE": "Resource",
        "RESOURCE_LIST": "Resource List",
        "RESPONSIBLE": "Responsible",
        "RESTART": "Restart",
        "RESTORE": "Restore",
        "RESUME": "Resume",
        "RESUME_TASK": "Resume Task",
        "RESULT": "Result",
        "RESULTS": "Results",
        "RESULT_DATE": "Result Date",
        "RESULT_LIST": "Result List",
        "RESULT_TYPE": "Result Type",
        "RETURN": "Return",
        "RETURNED": "Returned",
        "RETURN_DATE": "Return Date",
        "RETURN_ITEM": "Return Item",
        "RETURN_STATUS": "Return Status",
        "RETURN_TASK": "Return Task",
        "REVIEW": "Review",
        "REVIEWS": "Reviews",
        "ROLES": "Roles",
        "ROLE_LIST": "Role List",
        "ROW": "Row",
        "SAVE_AS": "Save As",
        "SAVE_DATE": "Save Date",
        "SAVE_LIST": "Save List",
        "SAVE_TASK": "Save Task",
        "SAVE_TICKET": "Save Ticket",
        "SAVE_TYPE": "Save Type",
        "SCHEDULE": "Schedule",
        "SCHEDULED": "Scheduled",
        "SCHEDULE_DATE": "Schedule Date",
        "SCHEDULE_TASK": "Schedule Task",
        "SEARCH_ACTION": "Search Action",
        "SEARCH_DATE": "Search Date",
        "SEARCH_END_DATE": "Search End Date",
        "SEARCH_FIELDS": "Search Fields",
        "SEARCH_FILTER": "Search Filter",
        "SEARCH_LIST": "Search List",
        "SEARCH_RESULT": "Search Result",
        "SEARCH_START_DATE": "Search Start Date",
        "SEARCH_TERM": "Search Term",
        "SECOND": "Second",
        "SECONDS": "Seconds",
        "SELECT": "Select",
        "SELECTED": "Selected",
        "SELECTED_ITEMS": "Selected Items",
        "SELECT_ITEM": "Select Item",
        "SELECT_LIST": "Select List",
        "SELECT_TASK": "Select Task",
        "SEND": "Send",
        "SENT": "Sent",
        "SERVER": "Server",
        "SESSION": "Session",
        "SESSION_ID": "Session ID",
        "SETTING": "Setting",
        "SETTINGS_LIST": "Settings List",
        "SETUP": "Setup",
        "SHORTCUT": "Shortcut",
        "SHORTCUTS": "Shortcuts",
        "SHORTCUT_LIST": "Shortcut List",
        "SHOW": "Show",
        "SHOW_ALL": "Show All",
        "SHOW_AS_LIST": "Show as List",
        "SHOW_LIST": "Show List",
        "SHOW_TASK": "Show Task",
        "SHOW_TICKET": "Show Ticket",
        "SIMPLE": "Simple",
        "SKIP": "Skip",
        "SKIPPED": "Skipped",
        "SKIP_TASK": "Skip Task",
        "SORT": "Sort",
        "SORTED": "Sorted",
        "SORTING": "Sorting",
        "SOURCE": "Source",
        "SOURCE_LIST": "Source List",
        "SOURCE_NAME": "Source Name",
        "SOURCE_TYPE": "Source Type",
        "SPECIAL": "Special",
        "SPECIFICATION": "Specification",
        "SPECIFICATIONS": "Specifications",
        "SPEC_LIST": "Specification List",
        "SPLIT": "Split",
        "SPLIT_TASK": "Split Task",
        "SPLIT_TICKET": "Split Ticket",
        "START_DATE": "Start Date",
        "START_TASK": "Start Task",
        "START_TIME": "Start Time",
        "STATUS_DATE": "Status Date",
        "STOP": "Stop",
        "STOP_TASK": "Stop Task",
        "SUBMIT": "Submit",
        "SUBMITTED": "Submitted",
        "SUBTASK": "Subtask",
        "SUBTASKS": "Subtasks",
        "SUCCESS_DATE": "Success Date",
        "SUCCESS_TASK": "Success Task",
        "SUSPEND": "Suspend",
        "SUSPENDED": "Suspended",
        "SUSPEND_TASK": "Suspend Task",
        "SYSTEM": "System",
        "TAB": "Tab",
        "TABS": "Tabs",
        "TAG": "Tag",
        "TAGS": "Tags",
        "TAG_LIST": "Tag List",
        "TASKS_LIST": "Tasks List",
        "TASKS_SETTINGS": "Tasks Settings",
        "TASK_END": "Task End",
        "TASK_LIST": "Task List",
        "TASK_MODE": "Task Mode",
        "TASK_NAME": "Task Name",
        "TASK_PATH": "Task Path",
        "TASK_SETTINGS": "Task Settings",
        "TASK_START": "Task Start",
        "TASK_STATUS": "Task Status",
        "TAX": "Tax",
        "TAXES": "Taxes",
        "TAX_LIST": "Tax List",
        "TAX_RATE": "Tax Rate",
        "TEMPLATES": "Templates",
        "TEMPLATE_LIST": "Template List",
        "TEST": "Test",
        "TESTS": "Tests",
        "TEST_CASE": "Test Case",
        "TEXT": "Text",
        "TIME": "Time",
        "TIME_DATE": "Time/Date",
        "TIME_LIST": "Time List",
        "TITLE": "Title",
        "TO": "To",
        "TO_DO": "To Do",
        "TO_LIST": "To List",
        "TO_LOCATION": "To Location",
        "TO_TASK": "To Task",
        "TO_TICKET": "To Ticket",
        "TO_TYPE": "To Type",
        "TO_USER": "To User",
        "TOOL": "Tool",
        "TOOLS": "Tools",
        "TOP": "Top",
        "TOP_TASK": "Top Task",
        "TOTAL_PRICE": "Total Price",
        "TRANSFER": "Transfer",
        "TRANSFER_DATE": "Transfer Date",
        "TRANSFER_LIST": "Transfer List",
        "TRANSFER_MODE": "Transfer Mode",
        "TRANSFER_TASK": "Transfer Task",
        "TRANSACTION": "Transaction",
        "TRANSACTIONS": "Transactions",
        "TRIGGER": "Trigger",
        "TRIGGERS": "Triggers",
        "TRIGGER_ACTION": "Trigger Action",
        "TRIGGER_DATE": "Trigger Date",
        "TRIGGER_LIST": "Trigger List",
        "TYPE_LIST": "Type List",
        "UNCHECK": "Uncheck",
        "UNFINISHED": "Unfinished",
        "UNKNOWN": "Unknown",
        "UPDATE": "Update",
        "UPDATED": "Updated",
        "UPDATE_DATE": "Update Date",
        "UPDATE_SETTINGS": "Update Settings",
        "UPLOAD_DATE": "Upload Date",
        "URL": "URL",
        "URL_LIST": "URL List",
        "USERS": "Users",
        "USERNAME": "Username",
        "USER_CONFIG": "User Config",
        "USER_LIST": "User List",
        "USER_MODE": "User Mode",
        "USER_NAME": "User Name",
        "USER_ROLE": "User Role",
        "USER_SETTINGS": "User Settings",
        "USER_STATUS": "User Status",
        "USER_TYPE": "User Type",
        "VALIDATE": "Validate",
        "VALIDATED": "Validated",
        "VALIDATION": "Validation",
        "VALUES": "Values",
        "VALUE_LIST": "Value List",
        "VIEW": "View",
        "VIEWED": "Viewed",
        "VIEW_DATE": "View Date",
        "VIEW_LIST": "View List",
        "VIEW_MODE": "View Mode",
        "VISIBILITY": "Visibility",
        "VISIBLE": "Visible",
        "VISIBLE_ITEMS": "Visible Items",
        "WEIGHT": "Weight",
        "WEIGHTED": "Weighted",
        "WEIGHT_LIST": "Weight List",
        "WIDGET": "Widget",
        "WIDGETS": "Widgets",
        "WIDGET_LIST": "Widget List",
        "WORK": "Work",
        "WORKFLOW": "Workflow",
        "WORK_LIST": "Work List",
        "WORK_MODE": "Work Mode",
        "WORK_STATUS": "Work Status",
        "WRONG_DATA": "Wrong Data",
        "YEAR": "Year",
        "YES": "Yes",
        "ZIP": "Zip",
        "ZIP_CODE": "Zip Code",
        "ZIP_FILE": "Zip File",
        "RECHARGE":"Recharge",
        "USAGE":"Usage",
        "ACTIVATION":"Activation",

        "SMARTBI": "SmartBI",
        "ENABLE_SUPERSET": "Abilita SmartBI Business Intelligence",
        "SUPERSET_DASHBOARD_ID": "SmartBI Dashboard Id",
        "SUPERSET_USERNAME": "SmartBI Username",
        "SUPERSET_PASSWORD": "SmartBI Password",

        "MASK_SHOULD_CONTAIN_ALMOST_1_VARIABLE": "Mask should contain almost 1 Variable",
        "MASK_SHOULD_CONTAIN_REGEX": "Mask should contain Regex",

        "EXTRA_FIELDS": "Extra Fields",

        "REFERENCE_SUBSTRING": "Reference Substring",
        "USE_REF_SUBSTRING": "Use Reference Substring",

        "KEY": "Key",
        "TYPE": "Type",
        "CLEAR_FILTERS": "Clear Filters",
        "NO_FILTERS_USED": "No Filters Used",

        "USE_LOCAL_ENDPOINT": "Use Local Endpoint",
        "LOCAL_ENDPOINT": "Local Endpoint",

        "VERIFY": "Verify Item",

        "PROD_EXTRA_FIELDS": "Items Extra Fields",

        "GENERAL_INFO": "Detail",

        "USE_PACK_SUBSTRING": "Use Pack Substring",
        "SUBSTRING_START": "Substring Start",
        "SUBSTRING_LENGTH": "Substring Length",
        "SUBSTRING_TYPE": "Substring Type",

        "TEMPLATE_ASSIGMENT": "Template Assigment",
        "TEMPLATE_ASSIGMENT_TYPE": "Template Assigment Type",

        "USE_FILTERS_AS_EXPECTED": "Use Filters as Expected",

        "TASK_REFERENCE": "Task Reference",
        "CREATE_DOC_REF_SEQUENCE": "Create Task Reference Sequence",
        "SEQ_PREFIX": "Sequence Prefix",
        "SEQ_LENGTH": "Sequence Length",
        "SEQ_BREAK": "Sequence Break",
        "SEQ_RESET": "Sequence Reset",

        "SNAPSHOT": "Snapshot",
        "STARTUP_SNAPSHOT_PROD": "Execute Snapshot on Mobile Startup for Products",
        "STARTUP_SNAPSHOT_TASK": "Execute Snapshot on Mobile Startup for Tasks",
        "STARTUP_SNAPSHOT_STOCK": "Execute Snapshot on Mobile Startup for Stock",

        "ENABLE_MQTT_PRODUCT": "Enable MQTT Product Message",
        "MQTT_PRODUCT_LABEL": "MQTT Product Label",
        "MESSAGE_SENT": "Message Sent",

        "EPC_UNITS": "EPC Units",



    }
}
