import { Routes } from '@angular/router';
export const APP_LAYOUT_ROUTES: Routes = [
    //Dashboard
    {
        path: 'dashboard',
        loadChildren: () => import('../views/dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    //Tasks
    {
        path: 'tasks',
        loadChildren: () => import('../views/tasks/tasks.module').then(m => m.TasksModule)
    },
    //TasksTest
    {
      path: 'tasksTest',
      loadChildren: () => import('../views/tasks/tasks.module').then(m => m.TasksModule)
    },
    //Calendar
    {
      path: 'planner',
      loadChildren: () => import('../views/calendar/calendar.module').then(m => m.CalendarAppModule)
    },

    //Stock
    {
        path: 'stock',
        loadChildren: () => import('../views/stock/stock.module').then(m => m.StockModule)
    },
    //StockZone
    {
      path: 'stockZone',
      loadChildren: () => import('../views/stockZone/stock.module').then(m => m.StockModule)
  },
    //Report
    {
      path: 'report',
      loadChildren: () => import('../views/report/report.module').then(m => m.ReportModule)
    },

    //Clients
    {
        path: 'clients',
        loadChildren: () => import('../views/clients/clients.module').then(m => m.ClientsModule)
    },
    //Departments
    {
        path: 'departments',
        loadChildren: () => import('../views/departments/departments.module').then(m => m.DepartmentsModule),
    },
    //Printers
    {
        path: 'printers',
        loadChildren: () => import('../views/printers/printers.module').then(m => m.PrintersModule),
    },
    //Templates
    {
        path: 'templates',
        loadChildren: () => import('../views/templates/templates.module').then(m => m.TemplatesModule),
    },
    //Locations
    {
        path: 'departments/locations/:departmentId',
        loadChildren: () => import('../views/locations/locations.module').then(m => m.LocationsModule)
    },
    //DepartmentRelations
    {
      path: 'departments/relations/:departmentId',
      loadChildren: () => import('../views/departmentRelation/departmentRelation.module').then(m => m.DepartmentRelationModule)
    },
    //Zones
    {
      path: 'departments/zones/:departmentId',
      loadChildren: () => import('../views/zones/zones.module').then(m => m.ZonesModule)
    },
    //PointOfSales
    {
        path: 'departments/pointOfSales/:departmentId',
        loadChildren: () => import('../views/pointOfSales/pointOfSales.module').then(m => m.PointOfSalesModule)
    },
    //LocationTypes
    {
        path: 'locationTypes',
        loadChildren: () => import('../views/locationTypes/locationTypes.module').then(m => m.LocationTypesModule)
    },
    //Reasons
    {
        path: 'reasons',
        loadChildren: () => import('../views/reasons/reasons.module').then(m => m.ReasonsModule)
   },
    //DocumentTypes
    {
        path: 'documentTypes',
        loadChildren: () => import('../views/documentTypes/documentTypes.module').then(m => m.DocumentTypesModule)
    },
    {
        path: 'products',
        loadChildren: () => import('../views/products/products.module').then(m => m.ProductsModule)
    },
    //Products
    {
        path: 'products',
        loadChildren: () => import('../views/products/products.module').then(m => m.ProductsModule)
    },
     //Products-serials
     {
      path: 'products-details',
      loadChildren: () => import('../views/product-details/productDetails.module').then(m => m.ProductDetailsModule)
  },


    //Group
    {
      path: 'groups',
      loadChildren: () => import('../views/groups/groups.module').then(m => m.GroupsModule)
    },
    //Customers
    {
        path: 'customers',
        loadChildren: () => import('../views/customers/customers.module').then(m => m.CustomersModule)
    },
    //Customer Roles List
    {
        path: 'customers/customerRoles/:customerId',
        loadChildren: () => import('../views/customerRoles/customerRoles.module').then(m => m.CustomerRolesModule)
    },
    //Black List
    {
        path: 'customers/blacklist/:customerId',
        loadChildren: () => import('../views/black/black.module').then(m => m.BlackModule)
    },
    //Images
    {
        path: 'customers/images/:customerId',
        loadChildren: () => import('../views/images/images.module').then(m => m.ImagesModule)
    },
    //Filters
    {
        path: 'customers/filters/:customerId',
        loadChildren: () => import('../views/filter/filter.module').then(m => m.FilterModule)
    },
    //Status List
    {
      path: 'customers/status/:customerId',
      loadChildren: () => import('../views/status/status.module').then(m => m.StatusModule)
    },
    //Fileformat
    {
        path: 'customers/fileformat/:customerId',
        loadChildren: () => import('../views/fileformat/fileformat.module').then(m => m.FileformatModule)
    },
    //Users
    {
        path: 'users',
        loadChildren: () => import('../views/users/users.module').then(m => m.UsersModule)
    },

    //Bi
    {
      path: 'bi',
      loadChildren: () => import('../views/bi/bi.module').then(m => m.BiModule)
    },

    //Superset
    {
      path: 'superset',
      loadChildren: () => import('../views/superset/superset.module').then(m => m.SupersetModule)
    },





    ////////////////////
    // POS
    ////////////////////
    {
      path: 'tiles',
      loadChildren: () => import('../views/tiles/tiles.module').then(m => m.TilesModule)
    },
    {
      path: 'pricelist',
      loadChildren: () => import('../views/priceList/priceLists.module').then(m => m.PriceListsModule)
    },
    //Customers
    {
      path: 'pos-config',
      loadChildren: () => import('../views/pos/pos.module').then(m => m.PosModule)
  },
];
