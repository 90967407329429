import { Injectable } from "@angular/core";
import { NavMenu } from '@app/shared/types/nav-menu.interface';


const dashboard: NavMenu[] = [
  {
      path: '/dashboard',
      title: 'Dashboard',
      translateKey: 'NAV.DASHBOARD',
      type: 'item',
      iconType: 'feather',
      icon: 'icon-home',
      key: 'dashboard',
      submenu: []
  },
]

const tasks: NavMenu[] = [
{
    path: '/tasks',
    title: 'Tasks',
    translateKey: 'NAV.TASKS',
    type: 'item',
    iconType: 'feather',
    icon: 'icon-clipboard',
    key: 'tasks',
    submenu: []
},
]

const planner: NavMenu[] = [
{
    path: '/planner',
    title: 'Planner',
    translateKey: 'NAV.PLANNER',
    type: 'item',
    iconType: 'feather',
    icon: 'icon-calendar',
    key: 'planner',
    submenu: []
},
]

const stock: NavMenu[] = [
{
    path: '/stock',
    title: 'Stock',
    translateKey: 'NAV.STOCK',
    type: 'item',
    iconType: 'line-awesome',
    icon: 'la-warehouse',
    key: 'tasks',
    submenu: []
},
]

const stockZone: NavMenu[] = [
{
    path: '/stockZone',
    title: 'Zone Stock',
    translateKey: 'NAV.STOCKZONE',
    type: 'item',
    iconType: 'feather',
    icon: 'icon-map-pin',
    key: 'stockZone',
    submenu: []
},
]

const report: NavMenu[] = [
{
    path: '/report',
    title: 'Report',
    translateKey: 'NAV.REPORT',
    type: 'item',
    iconType: 'feather',
    icon: 'icon-file-text',
    key: 'report',
    submenu: []
},
]

const items: NavMenu[] = [
{
    path: '/products',
    title: 'Products',
    translateKey: 'NAV.PRODUCTS',
    type: 'item',
    iconType: 'feather',
    icon: 'icon-tag',
    key: 'products',
    submenu: []
},
]

const bi: NavMenu[] = [
{
    path: '/bi',
    title: 'Business Intelligence',
    translateKey: 'APP.BUSINESS_INTELLIGENCE',
    type: 'item',
    iconType: 'feather',
    icon: 'icon-bar-chart-2',
    key: 'bi',
    submenu: []
},
]

const supersetMenu: NavMenu[] = [
  {
      path: '/superset',
      title: 'SmartBi',
      translateKey: 'APP.SMARTBI',
      type: 'item',
      iconType: 'feather',
      icon: 'icon-pie-chart',
      key: 'superset',
      submenu: []
  },
  ]

const printersMenu: NavMenu[] = [
{
    path: '',
    title: 'Printers',
    translateKey: 'NAV.PRINTER_SETTINGS',
    type: 'title',
    iconType: 'feather',
    icon: 'icon-printer',
    key: 'pages',
    submenu: [
        {
            path: '',
            title: 'Printers',
            translateKey: 'NAV.PRINTERS_AND_TEMPLATES',
            type: 'collapse',
            iconType: 'feather',
            icon: 'icon-printer',
            key: 'main.printers',
            submenu: [
                {
                    path: '/printers',
                    title: 'Printers',
                    translateKey: 'NAV.PRINTERS',
                    type: 'item',
                    iconType: '',
                    icon: '',
                    key: 'printers',
                    submenu: []
                },
                {
                    path: '/templates',
                    title: 'ZPL Templates',
                    translateKey: 'NAV.ZPL_TEMPLATES',
                    type: 'item',
                    iconType: '',
                    icon: '',
                    key: 'templates',
                    submenu: []
                }
            ]
        }
    ]
}
]


const customerConfigMenu: NavMenu[] = [
  {
      path: '',
      title: 'Customer Config',
      translateKey: 'NAV.CUSTOMER_CONFIG_MENU',
      type: 'title',
      iconType: 'feather',
      icon: 'icon-file',
      key: 'customer-config',
      submenu: [
          {
              path: '/clients',
              title: 'Clients',
              translateKey: 'NAV.CLIENTS',
              type: 'item',
              iconType: 'feather',
              icon: 'icon-smartphone',
              key: 'clients',
              submenu: []
          },
          {
              path: '/departments',
              title: 'Departments',
              translateKey: 'NAV.DEPARTMENTS',
              type: 'item',
              iconType: 'line-awesome',
              icon: 'la-store-alt',
              key: 'departments',
              submenu: []
          },
          {
              path: '/locationTypes',
              title: 'Location Types',
              translateKey: 'NAV.LOCATIONTYPES',
              type: 'item',
              iconType: 'feather',
              icon: 'icon-download',
              key: 'locationTypes',
              submenu: []
          },
          {
              path: '/documentTypes',
              title: 'Document Types',
              translateKey: 'NAV.DOCUMENTTYPES',
              type: 'item',
              iconType: 'feather',
              icon: 'icon-file-text',
              key: 'documentTypes',
              submenu: []
          },
          {
              path: '/reasons',
              title: 'Reasons',
              translateKey: 'APP.REASONS',
              type: 'item',
              iconType: 'feather',
              icon: 'icon-disc',
              key: 'reasons',
              submenu: []
          },
          /*
          {
              path: '/products',
              title: 'Products',
              translateKey: 'NAV.PRODUCTS',
              type: 'item',
              iconType: 'feather',
              icon: 'icon-tag',
              key: 'products',
              submenu: []
          },
          */
      ]
  }
]


const groupConfigMenu: NavMenu[] = [
{
    path: '',
    title: 'Admin Config',
    translateKey: 'NAV.ADMIN_CONFIG_MENU',
    type: 'title',
    iconType: 'feather',
    icon: 'icon-file',
    key: 'admin-config',
    submenu: [

        {
            path: '/users',
            title: 'Users',
            translateKey: 'NAV.USERS',
            type: 'item',
            iconType: 'feather',
            icon: 'icon-lock',
            key: 'users',
            submenu: []
        },

    ]
}
]


const adminConfigMenu: NavMenu[] = [
{
    path: '',
    title: 'Admin Config',
    translateKey: 'NAV.ADMIN_CONFIG_MENU',
    type: 'title',
    iconType: 'feather',
    icon: 'icon-file',
    key: 'admin-config',
    submenu: [
        {
            path: '/groups',
            title: 'Groups',
            translateKey: 'NAV.GROUPS',
            type: 'item',
            iconType: 'feather',
            icon: 'icon-layers',
            key: 'customers',
            submenu: []
        },
        {
            path: '/customers',
            title: 'Customers',
            translateKey: 'NAV.CUSTOMERS',
            type: 'item',
            iconType: 'feather',
            icon: 'icon-users',
            key: 'customers',
            submenu: []
        },
        {
            path: '/users',
            title: 'Users',
            translateKey: 'NAV.USERS',
            type: 'item',
            iconType: 'feather',
            icon: 'icon-lock',
            key: 'users',
            submenu: []
        },

    ]
}
]

const securityMenu: NavMenu[] = [
{
    path: '',
    title: 'Security',
    translateKey: 'NAV.SECURITY',
    type: 'title',
    iconType: 'feather',
    icon: 'icon-alert-triangle',
    key: 'security',
    submenu: [
        {
            path: '/eas-events',
            title: 'Events',
            translateKey: 'NAV.EVENTS_DASHBOARD',
            type: 'item',
            iconType: 'feather',
            icon: 'icon-alert-triangle',
            key: 'events',
            submenu: []
        }
    ]
}
]

///////////////////////
/*
const posMenu: NavMenu[] = [
{
    path: '',
    title: 'Point of Sales',
    translateKey: 'NAV.POINT_OF_SALES',
    type: 'title',
    iconType: 'feather',
    icon: 'icon-monitor',
    key: 'pages',
    submenu: [
        {
            path: '',
            title: 'Point of Sales Settings',
            translateKey: 'NAV.POINT_OF_SALES_CONFIG',
            type: 'collapse',
            iconType: 'feather',
            icon: 'icon-sliders',
            key: 'main.pos',
            submenu: [
                {
                    path: '/tiles',
                    title: 'Tiles',
                    translateKey: 'NAV.TILES',
                    type: 'item',
                    iconType: '',
                    icon: '',
                    key: 'tiles',
                    submenu: []
                },
                {
                    path: '/pricelist',
                    title: 'Price List',
                    translateKey: 'NAV.PRICE_LIST',
                    type: 'item',
                    iconType: '',
                    icon: '',
                    key: 'pricelist',
                    submenu: []
                },
                {
                  path: '/taxes',
                  title: 'Taxes',
                  translateKey: 'NAV.TAXES',
                  type: 'item',
                  iconType: '',
                  icon: '',
                  key: 'taxes',
                  submenu: []
                },
                {
                  path: '/discounts',
                  title: 'Discounts',
                  translateKey: 'NAV.DISCOUNTS',
                  type: 'item',
                  iconType: '',
                  icon: '',
                  key: 'discounts',
                  submenu: []
                },
                {
                  path: '/payments',
                  title: 'Payments',
                  translateKey: 'NAV.PAYMENTS',
                  type: 'item',
                  iconType: '',
                  icon: '',
                  key: 'payments',
                  submenu: []
                }
            ]
        }
    ]
}
]
*/

@Injectable({
  providedIn: 'root'
})

export class NavService {

  menu : NavMenu[] = []

  getNavbar() {
    let userstr = window.sessionStorage.getItem('auth-user');

    let c = JSON.parse(window.sessionStorage.getItem('c'));
    if(c===undefined)
      c=null;

    let userNavConfiguration: NavMenu[];

    const posMenu: NavMenu[] = [
    {
        path: '',
        title: 'Point of Sales',
        translateKey: 'NAV.POINT_OF_SALES',
        type: 'title',
        iconType: 'feather',
        icon: 'icon-monitor',
        key: 'pages',
        submenu: [
            {
                path: '/pos-config/detail/'+(c!=null?c.id:''),
                title: 'PoS Config',
                translateKey: 'NAV.POS_CONFIG',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-sliders',
                key: 'posConfig',
                submenu: []
            }
        ]
    }
    ]


    if(userstr) {
      const user = JSON.parse(userstr);
      if(user.roles[0]==='ROLE_USER') {
        console.log('user...');
        userNavConfiguration = [
          ...dashboard,
          ...tasks,
          ...planner,
          ...stock,
          ...stockZone,
          ...report,
          ...items,
          //...((c&&c.enablePos==true )?(posMenu):[]),
        ]
      }
      if(user.roles[0]==='ROLE_GROUP_ADMIN') {
        console.log('admin...');
        userNavConfiguration = [
          ...dashboard,
          ...tasks,
          ...planner,
          ...stock,
          ...stockZone,
          ...report,
          ...items,
          ...bi,
          ...((c&&c.enableSuperset==true )?(supersetMenu):[]),
          ...customerConfigMenu,
          ...((c&&c.enablePos==true )?(posMenu):[]),
          //...posMenu,
          ...printersMenu,
          ...groupConfigMenu,

        ]
      }
      if(user.roles[0]==='ROLE_CUSTOMER_ADMIN') {
        console.log('customer_admin, group_admin...');
        userNavConfiguration = [
          ...dashboard,
          ...tasks,
          ...planner,
          ...stock,
          ...stockZone,
          ...report,
          ...items,
          ...bi,
          ...((c&&c.enableSuperset==true )?(supersetMenu):[]),
          ...customerConfigMenu,
          ...((c&&c.enablePos==true )?(posMenu):[]),
          //...posMenu,
          ...printersMenu,

        ]
      }
      if(user.roles[0]==='ROLE_ADMIN') {
        console.log('admin...');
        userNavConfiguration = [
          ...dashboard,
          ...tasks,
          ...planner,
          ...stock,
          ...stockZone,
          ...report,
          ...items,
          ...bi,
          ...((c&&c.enableSuperset==true )?(supersetMenu):[]),
          ...customerConfigMenu,
          ...((c&&c.enablePos==true )?(posMenu):[]),
          //...posMenu,
          ...printersMenu,
          ...adminConfigMenu,

        ]
      }




    }

    return userNavConfiguration;
  }
}
