
<div class="header-nav-item-select" (click)="openQRcode()" *ngIf="c&&customMenuView('QRCode')"
  tooltip="QRCode"
  placement="bottom">
  <div class="toggle-wrapper">
      <i class="nav-icon la la-qrcode"></i>
  </div>
</div>

<!--
<div class="header-nav-item-select" (click)="openQuickPanel()">
    <div class="toggle-wrapper">
        <i class="nav-icon feather icon-settings"></i>
    </div>
</div>
-->

<div class="header-nav-item-select" routerLink="/customers/detail/{{customer}}"
  *ngIf="customMenuView('editCustomer')"
  tooltip="Edit Customer"
  placement="bottom">
  <div class="toggle-wrapper">
      <i class="nav-icon feather icon-settings"></i>
  </div>
</div>



<div class="header-nav-item-select" (click)="security()" *ngIf="c&&c.security===true&&user&&(user.customerRole==null||user.customerRole.viewType==='')&&customMenuView('security')"
  tooltip="Security Dashboard"
  placement="bottom">
  <div class="toggle-wrapper">
      <i class="nav-icon feather icon-alert-triangle"></i>
  </div>
</div>

<div class="header-nav-item-select" (click)="pos()" *ngIf="c&&c.enablePos===true&&user&&(user.customerRole==null||user.customerRole.viewType==='')&&customMenuView('pos')"
  tooltip="Point of Sales"
  placement="bottom">
  <div class="toggle-wrapper">
      <i class="nav-icon feather icon-monitor"></i>
  </div>
</div>

<div class="header-nav-item-select" (click)="bi()" *ngIf="c&&c.powerbiEmbedded===true&&user&&(user.roles[0]!=='ROLE_USER')&&customMenuView('bi')"
  tooltip="T-Insight Business Intelligence"
  placement="bottom">
  <div class="toggle-wrapper">
      <i class="nav-icon feather icon-bar-chart-2"></i>
  </div>
</div>

<div class="header-nav-item-select" (click)="superset()" *ngIf="c&&c.enableSuperset===true&&user&&(user.roles[0]!=='ROLE_USER')&&customMenuView('superset')"
  tooltip="SmartBI Business Intelligence"
  placement="bottom">
  <div class="toggle-wrapper">
      <i class="nav-icon feather icon-pie-chart"></i>
  </div>
</div>




<div class="header-nav-item-select" (click)="selectCustomer()" *ngIf="(user.roles[0]==='ROLE_ADMIN'||user.roles[0]==='ROLE_GROUP_ADMIN')&&customMenuView('switchCustomer')"
  tooltip="Change Customer"
  placement="bottom">
  <div class="toggle-wrapper">
      <i class="nav-icon feather icon-users"></i>
  </div>
</div>




<nav-profile></nav-profile>
