export const nav = {
    'NAV': {
        'DASHBOARD': 'Dashboard',
        'TASKS': 'Tasks',
        'PLANNER': 'Planner',
        'STOCK': 'Giacenza',
        'STOCKZONE': 'Giacenza Zoning',
        'CUSTOMER_CONFIG_MENU': 'Settings Cliente',
        'CLIENTS': 'Clients',
        'DEPARTMENTS': 'Dipartimenti',
        'LOCATIONTYPES': 'Tipologia Magazzini',
        'DOCUMENTTYPES': 'Tipologia Tasks',
        'PRODUCTS': 'Prodotti',
        'ADMIN_CONFIG_MENU': 'Settings Admin',
        'CUSTOMERS': 'Clienti',
        'USERS': 'Utenti',

        'MENU': 'Menu',
        'REPORT': 'Report',

        'GROUPS': 'Gruppi Clienti',


        'PRINTER_SETTINGS': 'Settings Stampe',
        'PRINTERS_AND_TEMPLATES': 'Stampanti e Modelli',
        'PRINTERS': 'Stampanti',
        'ZPL_TEMPLATES': 'Modelli ZPL',

        'SECURITY': 'Sicurezza',
        'EVENTS_DASHBOARD': 'Dashboard Eventi EAS',

        'EXPORT_MODULE': 'Modulo di Export',
        'ENABLE_EXPORT': 'Abilita Export:',
        'CONFIGURED_EXPORT': 'Export Configurati',
        'ADD': 'Aggiungi',
        'TYPE': 'Tipo',
        'STS': 'Sts',
        'EXPORT_ENABLED': 'Export Abilitati',
        'EXPORT_ENABLED_SINGLE': 'Export Abilitato',
        'EXPORT_TYPE': 'Tipo Export',
        'ADDRESS': 'Indirizzo',
        'PORT': 'Porta',
        'USERNAME': 'Username',
        'PASSWORD': 'Password',
        'SERVICE_NAME': 'Nome Servizio/Cartella',
        'FILE_TYPE': 'Tipo File',
        'INTERVAL': 'Intervallo',
        'EDIT_EXPORT': 'Modifica Export',
        'SAVE': 'Salva',
        'PROFILE': 'Profilo',
        'SIGNOUT': 'Esci',

        'SIGNIN': 'Entra',
        'SIGNIN_MESSAGE': 'Inserisci le credenziali di accesso per continuare',
        'FORGET_PASSWORD': 'Password dimenticata?',
        'NO_ACCOUNT': 'Non hai ancora un account?',
        'SIGNUP': 'Registrati',
        'EMAIL': 'Email',

        'SIGNUP_MESSAGE': 'Registrati per creare un nuovo Account!',
        'NAME_SURNAME': 'Nome e Cognome',
        'CONFIRM_PASSWORD': 'Conferma Password',
        'ALREADY_ACCOUNT': 'Hai già un account?',

        'USER': 'Utente',
        'NO_CUSTOMER': 'Non ha un Cliente associato',
        'CONTACT_ADMIN': 'Si prega di contattare il proprio Amministratore per configurare l\'utente.',

        'SETPASSWORD': 'Imposta Password',
        'SETPASSWORD_MESSAGE': 'Imposta una Password per abilitare il tuo account',

        'CHANGEEXPIREDPASSWORD': 'Password Scaduta',
        'CHANGEEXPIREDPASSWORD_MESSAGE': 'La tua password è scaduta. Cambiala per accedere',

        'CHANGEPASSWORD': 'Cambia Password',
        'CHANGEPASSWORD_MESSAGE': 'Crea una nuova password sicura',

        "POS_CONFIG": "PoS Config"


    },
    'APP': {
      "USE_PACK_SUBSTRING": "Usa Sottostringa",
      "SUBSTRING_START": "Inizio",
      "SUBSTRING_LENGTH": "Lunghezza",
      "SUBSTRING_TYPE": "Tipo",

      "EXTRA_FIELDS": "Campi Extra",
      "KEY": "Chiave",

      'SELECT_CUSTOMER': 'Seleziona Cliente',
      'SELECT_CUSTOMER_TO_CONTINUE': 'Seleziona un cliente per continuare',
      'CUSTOMER': 'Cliente',
      'NO_CHOOSE': 'Non sai cosa scegliere?',
      'SIGN_OUT': 'Esci',
      'INCOMING': 'Tasks Ricezione',
      'OUTGOING': 'Tasks Invio',
      'FILTER_DATA': 'Digita qualcosa per filtrare i dati...',
      'ALL_TASKS': 'Tutti i Tasks',
      'PAST_7': 'Ultimi 7 Giorni',
      'PAST_30': 'Ultimi 30 Giorni',
      'PAST_60': 'Ultimi 60 Giorni',
      'PAST': 'Ultimi',
      'DAYS': 'Giorni',

      'LIST_OF': 'Lista di',
      'CLOSED': 'Task Chiusi',
      'OPEN': 'Task Aperti',
      'TASKS': '',
      'OPEN_TASKS': 'Task Aperti',
      'CLOSED_TASKS': 'Task Chiusi',
      'PENDING_TASKS': 'Task In Attesa',
      'COPY_TASKS': 'Copia Task',
      'EXCEL_EXPORT': 'Esporta in Excel',
      'REFERENCE': 'Riferimento',
      'STS': 'STS',
      'DEADLINE': 'Scadenza',
      'DEPARTMENT': 'Dipartimento',
      'TASK_TYPE': 'Tipo Task',
      'PROGRESSIVE': 'Progressivo',
      'EXPECTED': 'Atteso',
      'READ': 'Letto',
      'ACTIONS': 'Azioni',
      'SELL_PRICE': 'Prezzo di vendita',
      'EAN': 'EAN',

      'CLOSE': 'Chiudi',
      'SAVE': 'Salva',
      'DOCUMENT_FILTER': 'Filtro Documento',
      'DESTINATION_LOCATION': 'Magazzino di destinazione',
      'DESTINATION': 'Destinazione',
      'DEADLINE_DATE': 'Data di Scadenza',
      'LOCATION_FILTER': 'Filtro Magazzino',

      'WARNING': 'Attenzione!',
      'SELECT2': 'Seleziona almeno 1 Task per usare la funzione di copia.',
      'SELECT1': 'Seleziona almeno 1 riga .',

      'DELETE': 'Cancella',
      'CANNOT_UNDONE': 'Questa operazione non può essere annullata',

      'COPY_EXPECTED_DATA': 'Copia Dati di Atteso',
      'COPY_READ_DATA': 'Copia Dati di Lettura',
      'COPY_OPTIONS': 'Opzioni di Copia',
      'CLOSE_COPIED_TASKS': 'Chiudi Task Copiati',
      'NEW_TASK_ATTRIBUTES': 'Attributi del nuovo Task Creato',

      'ONLY_DIFFERENCE': 'Solo Differenze',
      'BACK': 'Indietro',
      'IMPORT_EXPECTED': 'Importa Atteso',
      'DELETE_SELECTED_READ': 'Cancella Letture Selezionate ',
      'CLOSE_TASK': 'Chiudi Task',
      'STATUS': 'Status',
      'PACKAGE_CODE': 'Codice Collo',
      'DESCRIPTION': 'Descrizione',
      'DIFFERENCE': 'Differenza',

      'DELETE_READ_FOR REFERNCE': 'Cancella letture dell\'Item ',
      'DELETE_READ_FOR REFERNCE2': 'Item già movimentato.<br/>Solo le letture per differenza saranno cancellate per l\'Item ',
      'CONFIRM': 'Confermi',

      'CONFIRM_DELETE': 'Conferma Cancellazione',
      'ROWS': 'Righe',
      'DELETE_SELECTED': 'Cancella Selezionati',
      'CONFIRM_CLOSE': 'Conferma Chiusura',
      'CLOSE_SELECTED': 'Chiudi Selezionato',
      'TASK': '',

      'IMPORT_TYPE': 'Tipo File di Importazione',
      'CHOOSE_A_FILE': 'Seleziona un File',

      'SEARCH': 'Cerca',
      'SELECT_A_TAG': 'Seleziona un Tag',
      'SELECT_A_TAG_TO_CONTINUE': 'Seleziona un Tag per Continuare',

      'SELECT_A_DEPARTMENT': 'Seleziona un Dipartimento',
      'SELECT_A_DEPARTMENT_TO_CONTINUE': 'Seleziona un Dipartimento per Continuare',
      'STOCK_DETAIL': 'Dettaglio Giacenza',
      'LOCATION': 'Magazzino',
      'UNITS': 'Unità',

      'LIST_OF_CLIENTS': 'Lista Clients Mobile',
      'SHOW_INACTIVE_CLIENTS': 'Mostra Clients non attivi',
      'SHOW_CUSTOMER_CLIENTS': 'Mostra Clients del Cliente',
      'NAME': 'Nome',
      'ENABLED': 'Attivo',
      'DELETE_CLIENT': 'Cancella Client',
      'CLIENT_ENABLED': 'Client Attivo',
      'DOCUMENT_TYPES': 'Tipi Task da abilitare (Vuoto = Tutti)',


      'LIST_OF_DEPARTMENTS': 'Lista Dipartimenti',
      'DELETE_DEPARTMENT': 'Cancella Dipartimento',
      'CODE': 'Codice',
      'ADD_LOCATIONS': 'Aggiungi Magazzino',
      'LOCATION_TYPES': 'Tipo Magazzino',

      'BLACK_LIST': 'Black List',
      'DELETE_BLACK': 'Cancella Record',

      'LIST_OF_CUSTOMERS': 'Lista Clienti',
      'EPC_ENCODE_TYPE': 'Tipo Encoding EPC',
      'DELETE_CUSTOMER': 'Cancella Cliente',
      'BLACK_START': 'Inizio Sottostringa EPC',
      'BLACK_END': 'Fine Sottostringa EPC',

      'LIST_OF_DOCUMENT_TYPES': 'Tipologia Tasks',
      'MASTER': 'Master',
      'CAN_OVERLOAD': 'Può Eccedere Atteso',
      'AS_EXPECTED': 'Solo Conforme',
      'CAN_CHANGE_LOCATION': 'Può Cambiare Magazzino',
      'DELETE_TASK_TYPE': 'Cancella Tipo Task',

      'SELECT_TASKTYPE_TEMPLATE': 'Selezione Template Tipo Task',
      'SIGN': 'Segno',
      'AUTO_ACCEPT_TASK': 'Chiudi il Task Automaticamente',
      'CHECK_PACKAGE': 'Riscontro a Colli',
      'RFID_POWER': 'Potenza RFID',
      'TASK_HAS_DEADLINE': 'Il Task ha una Data di Scadenza',
      'IS_DEADLINE_EDITABLE': 'La Data di Scadenza è editabile',
      'DEADLINE_DAYS': 'Default Giorni Scandenza',
      'FILTERS': 'Filtri',
      'STOCK_FILTER_TYPE': 'Tipo Filtro Giacenza',
      'LOCATION_TYPE_FILTER': 'Filtro Tipi Magazzino',

      'FILE_FORMAT': 'Formato File',

      'COLUMN_LIST': 'Lista Colonne',
      'SEPARATOR': 'Separatore',
      'HEADER': 'Il File ha Intestazione',

      'IMPORT_FROM_CSV': 'Importa da CSV',

      'VALUE': 'Valore',
      'START': 'Inizio',
      'END': 'Fine',

      'LIST_OF_LOCATIONS': 'Magazzini',
      'LOCATION_TYPE': 'Tipo Magazzino',

      'LIST_OF_LOCATION_TYPES': 'Tipologia Magazzini',
      'SELECTABLE': 'Selezionabile',
      'LOCATION_TYPE_IS_SELECTABLE': 'Il Tipo Magazzino è Selezionabile',

      'LIST_OF_PRODUCTS': 'Prodotti',
      'SHORT_DESCRIPTION': 'Descrizione Breve',
      'LONG_DESCRIPTION': 'Descrizione Estesa',

      'UPLOAD': 'Upload',

      'STATUS_LIST': 'Lista Status',
      'DEFAULT': 'Default',
      'STATUS_TYPE': 'Tipo Status',
      'STATUS_WILL_BE_SET_AS_DEFAULT': 'Lo Status sarà impostato come Default',

      'LIST_OF_USERS': 'Utenti',
      'ROLE': 'Ruolo',
      'DEPARTMENTS': 'Dipartimenti',

      'TASK_TYPE_DESTINATION': 'Tipo Task Destinazione',
      'TASKTYPE_INFO': 'Informazioni Tipo Task',

      'MAX_SERIAL': 'Numero Massimo Seriali Associabili a barcode',
      'CAN_ADD_PRODUCT': 'Il Cliente può aggiungere prodotti da Web e App Mobile',
      'PRODUCT_PROPERTIES': 'Proprietà Prodotti',
      'BARCODE_MASK': 'Maschera Barcode',
      'REPLACE_MASK': 'Maschera Sostituzione Caratteri',
      'PRODUCT_DEFAULT_DESCRIPTION': 'Descrizione di Default',


      'DEPARTMENT_STOCK': 'Giacenza Dipartimenti',
      'GOTOSTOCK': 'Visualizza Giacenze',


      'SHOW_SERIALS': 'Mostra Matricole Prodotti',
      'PRODUCT_DETAILS': 'Matricole Prodotti',
      'EPC': 'Epc',

      'LOADWAREHOUSE': 'La scrittura di un EPC o l\'associazione di una Matricola alterano il magazzino',

      'TASKS_TOTAL': 'Tasks Totali',

      'SELECT_A_REPORT': 'Seleziona un Report',
      'SELECT_A_REPORT_TO_CONTINUE': 'Seleziona un Report per Continuare',

      'AVG_STAY': 'Permanenza Media',
      'TREND': 'Trend',
      'STOCK': 'Giacenza',
      'EXPIRED': 'Scaduto',

      'EXPIRATION_DATE': 'Data Scadenza',
      'DOC_REF': 'Riferimento Task',
      'ITEM_REFERENCE': 'Item',
      'ARRIVAL_DATE': 'Data Arrivo',

      'GROUPS': 'Gruppi Clienti',
      'GROUP': 'Gruppo Cliente',

      'PRINT': 'Stampa',
      'PRINTABLE': 'Il Task può essere stampato',
      'ALLOW_MULTIPLE_PRINT': 'Consenti stampe multiple',
      'ORDER_BY_FIELDS': 'Campi di ordinamento',
      'EXPORT': 'Esporta',
      'EXPORT_TO_CUSTOMER': 'Esporta il Task al Customer (Se configurato)',

      'PRINT_SELECTED': 'Stampa Selezionati',


      'GENERATE_INVENTORY': 'Genera Inventario',
      'EXPECTED_TYPE': 'Tipo Atteso',

      'PRINTERS': 'Stampanti',
      'IP': 'Ip',
      'PORT': 'Porta',
      'PRINTER_TYPES': 'Tipo Stampante',


      'LIST_OF_TEMPLATES': 'Modelli ZPL',
      'BREAKPOINT_LABEL': 'Etichetta di rottura',
      'ZPL': 'ZPL',

      'PRINTER': 'Stampante',
      'TEMPLATE': 'Modello ZPL',
      'GENERAT_ZPL_AND_DOWNLOAD': 'Genera e scarica ZPL',

      'SHOW_ZERO': 'Mostra prodotti non in Stock',

      'IMAGES': 'Immagini',

      'UNKNOWN_IMAGE': 'Immagine per Prodotti Sconosciuti',
      'DEFAULT_IMAGE': 'Immagine di Default',

      'UNKNOWN_TEXT_COLOR': 'Colore Testo per Items Sconosciuti',
      'ENABLE_KILL': 'Abilita Kill in Tag Management (Mobile)',
      'CAN_ADD_DEPARTMENT': 'Può aggiungere Dipartimenti da app Mobile',

      'GROUPBY': 'Raggruppa',
      'GROUPBY_REF': 'Raggruppa per Reference',
      'GROUPBY_PACK': 'Raggruppa per Package Code',
      'GROUPBY_EPC': 'Group By Epc',


      'CUSTOMER_DETAIL': 'Dettaglio Cliente',
      'PERSONAL': 'Dati Cliente',
      'ENCODING': 'Encoding',
      'TAG_MANAGMENT': 'Tag Management',
      'PRODUCTS': 'Prodotti',

      'SUCCESS': 'Tutto OK!',
      'SAVED': 'Tutte le modifiche sono state salvate',

      'EAN13': 'Barcode',
      'COMPANY_PREFIX': 'Lunghezza Company Prefix (0 se il barcode non è EAN13)',


      'SELECT_AN_EVENT': 'Seleziona un Evento',
      'SELECT_AN EVENT_TO_CONTINUE': 'Seleziona un Evento per visualizzarne i dettagli',
      'EPC_TIMELINE': 'EPC Timeline',

      'NO_EAS_ALARM': 'Nessun Evento da mostrare',


      'EAS_SERVER': 'Eas Server',
      'ENABLE_SECURITY': 'Abilita Dashboard Sicurezza',


      'GOTO_DASH': 'Torna alla Dashboard',

      'IMAGE': 'Immagine',
      'SECURITY_LASTHOURS': 'Mostra Allarmi della sicurezze relativi alle ultime *n ore',
      'ENABLE_QUALITY': 'Scarica Prodotti da Quality',
      'QUALITY_ENDPOINT': 'Quality Endpoint',
      'QUALITY_USERNAME': 'Quality Username',
      'QUALITY_PASSWORD': 'Quality Password',
      'ENABLE_GET_IMAGE': 'Scarica prodotti da GetImage',
      'GET_IMAGE_ENDPOINT': 'Get image Endpoint',
      'GET_IMAGE_USERNAME': 'Get image Username',
      'GET_IMAGE_PASSWORD': 'Get image Password',


      'ITEM': 'Item',
      'POE': 'Point of Exit',
      'TIMESTAMP': 'Time Stamp',


      'PASSWORD_RECOVERY': 'Recupera Password',
      'PASSWORD_RECOVERY_MESSAGE': 'Inserisci la tua email per recuperare la password',


      'TOTAL': 'Totali',
      'TOTAL_READ': 'Letture Totali',
      'TOTAL_EXP': 'Atteso Totale',
      'TOTAL_DIFF': 'Totale Differenza',

      'SEND_TO_EAS': 'Invia Dati a EAS Server',
      'EAS_TOPIC': 'EAS Server MQTT Topic',
      'CUSTOMER_EAS_WARNING': 'I dati di tutti i Dipartimenti saranno inviati su questo Topic.',

      "TEMPLATE_ASSIGMENT": "Associazione Template",
      "TEMPLATE_ASSIGMENT_TYPE": "Associazione Template a",

      'CLEAR_EVENTS': 'Nascondi tutti gli eventi',
      'CLEAR': 'Nascondi',

      'LIST_OF_REASONS': 'Lista Causali',
      'REASON': 'Causale',
      'REASONS': 'Causali',

      'ENABLE_MQTT_RESPONSE': 'Abilita invio Response MQTT',

      'USE_FILTERS_AS_EXPECTED': 'Usa i filtri come Atteso',

      "TASK_REFERENCE": "Riferimento Task",
      "CREATE_DOC_REF_SEQUENCE": "Crea Sequenza Riferimento Task",
      "SEQ_PREFIX": "Prefisso Sequenza",
      "SEQ_LENGTH": "Lunghezza Sequenza",
      "SEQ_BREAK": "Rottura Sequenza",
      "SEQ_RESET": "Reset Sequenza",



      'REQUIRED': 'Richiesta Selezione',
      'REASON_MESSAGE': 'Devi specificare una Causale',

      'CUSTOMER_ROLES': 'Ruoli Cliente',
      'ROLE_AUTH': 'Autorizzazioni Ruolo',
      'ENABLE_WRITE': 'Abilita Scrittura/Associazione in Tag Management (Mobile)',
      'ENABLE_STATUS': 'Abilita Cambio Status in Tag Management (Mobile)',
      'ENABLE_ZONE': 'Abilita Cambio Zona in Tag Management (Mobile)',

      'ENABLE_PRINT': 'Abilita Stampa (Web / Mobile)',
      'ENABLE_ADDTASK': 'Abilita Aggiunta Nuovo Task (Web / Mobile)',
      'ENABLE_CLOSETASK': 'Abilita Chiusura Task (Web / Mobile)',
      'ENABLE_DELETETASK': 'Abilita Cancellazione Task (Web)',
      'ENABLE_COPYTASK': 'Abilita Copia Task / Unione Task (Web / Mobile)',
      'ENABLE_CHANGEFILTER': 'Abilita Cambio Filtri e Opzioni (Mobile)',

      'LOGIN_IS_MANDATORY': 'Login Obbligatorio su Mobile',
      'CUSTOMER_ROLE': 'Ruolo Cliente',

      'CANNOT_PERFORM_ACTION': 'Il tuo Ruolo non può eseguire quest\'azione',

      'DOC_REFERENCE': 'Riferimento Documento',

      'DISABLE_PARTIAL_MOVEMENT': 'Disabilita Movimentazione Parziale',
      'AUTOMATIC_PARTIAL_MOVEMENT': 'La Movimentazione Parziale è Automatica',

      'MOVED': 'Mov.',

      'ENABLE_SCANPRINT': 'Abilita ScanPrint e ScanScanWrite',

      'USE_SENDER_RECIPENT': 'Il Task usa Mittente/Destinatario',

      'TYPES': 'Tipo',
      'TIMEZONE': 'Timezone',
      'WORKING_DEPARTMENT': 'Il Dipartimento è Operativo',


      'SENDER': 'Mittente',
      'RECIPIENT': 'Destinatario',

      'CHECK_PACKAGE_MANDATORY': 'Il Riscontro a Colli è obbligatorio',
      'SEND_SNAPSHOT': 'Invia comando di Snapshot al device ',

      "SNAPSHOT": "Snapshot",
      "STARTUP_SNAPSHOT_PROD": "Esegui Snapshot all'avvio dell'App Mobile per Prodotti",
      "STARTUP_SNAPSHOT_TASK":  "Esegui Snapshot all'avvio dell'App Mobile per Tasks",
      "STARTUP_SNAPSHOT_STOCK": "Esegui Snapshot all'avvio dell'App Mobile per Giacenze",

      "ENABLE_MQTT_PRODUCT": "Abilita Messaggio MQTT per Prodotti",
      "MQTT_PRODUCT_LABEL": "Label per Messaggio MQTT Prodotti",
      "MESSAGE_SENT": "Messaggio Inviato",

      'WAITING_FOR_EXPECTED': 'Attende Atteso da Interfaccia esterna',

      'PENDING_MESSAGE': 'Questo Task è In Attesa. Sarà disponibile a breve.',

      'TASK_PENDING': 'Task In Attesa',

      "EPC_UNITS": "Unità EPC",


      'NOTE': 'Note',

      'SELECTED_PRODUCTS': 'Items Selezionati',
      'SHOW_HIDE_PRODUCTS': 'Mostra / Nascondi Items',
      'ADVANCED_SEARCH': 'Ricerca Avanzata',


      'MODEL': 'Modello',
      'PART': 'Parte',
      'VARIANT': 'Variante',
      'BRAND': 'Brand',
      'GENDER': 'Genere',
      'OUTLINE': 'Categoria',
      'TARGET': 'Target',
      'SEASON': 'Stagione',
      'MATERIAL': 'Materiale',
      'COLOUR': 'Colore',
      'SIZE': 'Taglia',

      'SHOW_OTHERS': 'Mostra / Nascondi altri...',

      'CANNOT_ADD': 'Il Task non può essere aggiunto da web',
      'CANNOT_ADD_MOBILE': 'Il Task non può essere aggiunto da Mobile',


      'VIEW_TYPE': 'Tipo Visualizzazione',

      'MAIN': 'Main',


      'SECONDARY_BARCODES': 'Barcode Secondari',


      'SHOW_HIDE_MORE': 'Mostra/Nascondi altri parametri di configurazione',

      'CERT_PATH': 'Certificate Path',
      'KEY_PATH': 'Private Key Path',
      'MQTT_TYPE': 'MQTT SDK Utilizzato',

      'FROM_DATE': 'Da Data',
      'TO_DATE': 'A Data',
      'DATE_RANGE': 'Intervallo Date',

      'STARTUP_SNAPSHOT': 'Esegui uno Snapshot all\'avvio dell\'App Mobile',


      'ENABLE_POOLING': 'Abilita Pooling per le Notifiche in Dashboard',
      'POOLING_INTERVAL': 'Intervallo in Secondi per Pooling',

      'LOCTYPE_FILTER_MANDATORY': 'Il Filtro Tipi Magazzino è obbligatorio',

      'CAN_REMOVE_READ': 'E\' possibile rimuovere le letture con il Reader RFID',

      'LIST_OF_ZONES': 'Lista Zone',
      'ZONE_WILL_BE_SET_AS_DEFAULT': 'Imposta la Zona come Default',

      'BARCODE': 'Barcode',
      'ZONE': 'Zona',
      'ZONE_DETAIL': 'Dettaglio Zona',

      'NOTE_MANDATORY': 'Le note sono obbligatorie',
      'SHOW_TASK_DETAIL': 'Mostra Dettaglio Task',
      'DOC_DATE': 'Data Task',
      'EDIT_NOTE': 'Modifica Note',
      'LIST_OF_RELATIONS': 'Lista Relazioni',
      'RELATION': 'Relazioni',
      'ALL': 'Tutti',

      'DEPARTMENT_TYPES': 'Gruppi Dipartimenti',
      'DEPARTMENT_TYPE': 'Gruppo Dipartimento',

      'DEPARTMENT_CATEGORY': 'Categoria Dipartimento',

      'DEPARTMENT_CATEGORIES': 'Categorie Dipartimenti',
      'PREVIOUS': 'Torna alla prima',


      'INVENTORY_ADJUSTMENT': 'Rettifica Giacenza Inventario',

      'PACK_COMPLIANCE': 'Il nome del collo deve essere presente in atteso',
      'DOC_DATE_EDITABLE': 'La data del documento è editabile',

      'PASSWORD_POLICIES': 'Password Policy',
      'EXPIRATION_DAYS': 'Scadenza Password in giorni',
      'FAILED_ATTEMPTS': 'Numero Massimo di Tentativi Falliti',
      'MIN_LENGTH': 'Lunghezza Minima Password',
      'MAX_LENGTH': 'Lunghezza Massima Password',
      'MIN_PASSWORD_AGE': 'Durata Minima Password ',
      'MASK': 'Maschera Password',

      'MUST_HAVE_SMALLCASE': 'Deve contenere almeno una lettera Minuscola',
      'MUST_HAVE_CAPITALCASE': 'Deve contenere almeno una lettera Maiuscola',
      'MUST_HAVE_NUMBER': 'Deve contenere almeno un Numero',
      'MUST_HAVE_SPECIAL_CHARACTER': 'Deve contenere almeno un Carattere Speciale',


      'ENABLE_CEGID': 'Abilita Funzionalità Cegid',

      'CEGID_EXPORT': 'Esporta per Cegid',

      'ACTIVEDIRECTORY': 'Active Directory',

      'PACKAGE': 'Configurazione Colli',
      'PACKAGE_CHECK_TYPE': 'Tipo Controllo su numero di colli Attesi/Riscontrati',

      'INVERTED_DIFFERENCES': 'Differenze Invertite su Tasks (Atteso-Letto --> Letto-Atteso)',

      'FLOW_CONFIG': 'Configurazione Task Flow',
      'TASK_TYPE_TO_CREATE': 'Nuovo Task Type da creare sul Dipartimento alla chiusura del Task',
      'CREATE_NEWDOC_WITH_ONLY_DIFF': 'Crea il nuovo Task con sole differenze',


      'ASKCLOSE_TASK': 'Chiedi Letture al Device',


      'ASK4READ': 'Chiedi Letture al Device per il Task ',
      'ASK4READ_BUTTON': 'Chiedi Letture',

      'SAVED_PASSWORD': 'Numero di Password savate per Utente',

      'RESET': 'Resetta Filtri',

      'PLAY_SOUND': 'Fai Suonare il Device ',


      'VERSION': 'Release',

      'PLAN_TASK': 'Pianifica Task',
      'ALL_DAY': 'Task Pianificato l\'intera giornata',
      'TASK_DATE': 'Data Inizio Task',

      'REPEAT': 'Ripetizione',

      'DISABLE_SORT': 'Disabilita "Sort List by Power" su Mobile',
      'DISABLE_CUSEPC': 'Disabilita "Read only Customer EPC" su Mobile',
      'DISABLE_ENTER': 'Disabilita "Automatic \'Enter\' send when Regex Match" su Mobile',
      'DEFAULT_VALUE': 'Valore di Default',


      'SETTINGS': 'Settings',

      'UNIQUE_REFERENCE': 'Il Riferimento Task è chiave univoca (Solo API)',
      'DOC_EXCLUSIVE': 'Il Task non può essere lavorato da più di un client',
      'SEND_START': 'Invia messaggio MQTT quando entra in un Task',
      'SEND_END': 'Invia messaggio MQTT quando esce da un Task',
      'SEND_UNLOCK': 'Blocca Tunnel quando riceve le letture',


      'SHOW_STOCK': 'Mostra Giacenza in Dettaglio Prodotto',

      'TASK_TYPE_CATEGORY': 'Categoria Tipologia Task',

      'COUNTRY': 'Paese',
      'STATE': 'Regione',
      'DISTRICT': 'Provincia',
      'CITY': 'Città',
      'ADDRESS': 'Indirizzo completo',


      'SHOW_PIVOT': 'Mostra Tabella Pivot',

      'SIZE_GROUPS': 'Piano Taglie',
      'SELECT_SIZE_GROUP': 'Seleziona Piano Taglie',

      'EXPORT_ENVIRONMENTS': 'Export Environments',

      'EXPORT_ENVIRTMENT': 'Export Environment',

      'BUSINESS_INTELLIGENCE': 'T-Insight BI',
      'ENABLE_POWERBI_EMBEDDED': 'Abilita T-Insight PowerBi Embedded',
      'POWERBI_ENDPOINT': ' T-Insight PowerBi Endpoint',
      'POWERBI_APIKEY': 'T-Insight PowerBi ApiKey',
      'POWERBI_REPORTID': 'T-Insight PowerBi ReportId',

      'DIFFERENCE_TYPE': 'Tipo Differenza',
      'POSITIVE': 'Positiva',
      'NEGATIVE': 'Negativa',

      'ENABLE_COPY': 'Abilita Copia',

      'GENERAL': 'Info Tipo Task',
      'CONFIGURATION': 'Configurazione Tipo Task',
      'TUNNEL': 'Configurazione Tunnel',

      'ENABLE_PENDING_IMPORT': 'Abilita Importazione Task In Attesa',

      'SELECT_MAX_REACHED': 'Impossibile selezionare più di 30000 righe.',

      'SEND_COMPRESSED_REQUEST': 'Invia le richieste Compresse quando richiede l\'atteso da interfaccia esterna',

      'ENABLE_CLOSE_PACKAGE': 'Abilta Chiusura Collo (Una volta chiuso un collo non sarà più possibile aggiungere pezzi al suo interno)',

      'SCANNER_LOCATION_MANDATORY': 'Il Magazzino di Destinazione è obbligatorio',

      'DOC_REF_AUTO': 'Riferimento Task Automatico',


      'ZONE_FILTERS': 'Filtro Zone',
      'USE_ZONE': 'Il Tipo Task usa le Zone come filtro',

      'STATUS_FILTERS': 'Filtro Status',
      'USE_STATUS': 'Il Tipo Task usa gli Status come filtro',


      'ZONE_FILTER_TYPE': 'Tipo Filtro Zone',
      'STATUS_FILTER_TYPE': 'Tipo Filtro Status',
      'STATUS_FILTER': 'Status da Filtrare',

      'ZONE_TYPES': 'Tipo Zone',

      'ZONE_TYPE': 'Tipo Zona',

      'SHOW_EPC': 'Mostra Epc',

      'GET_TASKS': 'Richiedi Tasks',
      'DATE': 'Data',

      'ENABLE_GET_TASKS': 'Abilita Richiedi Tasks',

      'ENABLE_CONNECT_PREVIOUS_TASK': 'Enable Connect Previous Task',


      'ZONE_FILTER': 'Filtro Zone',
      'EDIT_TASK': 'Modifica Task',

      'ENABLE_CLOSE_FROM_SCANNER': 'Abilita la Chiusura del Task dalla Maschera di Lettura',

      'DEFAULT_STATUS': 'Status di default quando un Task viene chiuso',


      'POINT_OF_SALE': 'Point of Sale',
      'POS': 'PoS',
      'ENABLE_POS': 'Abilita PoS',
      'CAN_SELL': 'Può Effettuare Vendite o Resi',

      "REPRINT_FISCAL": "Ristampa dello scontrino fiscale",
      "REPRINT_GIFT": "Ristampa dello scontrino regalo",
      "CANCEL_TICKET": "Annulla ticket",
      "REFUND_TICKET": "Rimborsa ticket",

      "PARTIAL_REFUND_TICKET": "Rimborsa ticket parzialmente",
      "TOTAL_REFUND_TICKET": "Rimborsa ticket completamente",

      "EAS_ALARM": "Allarme EAS",
      "SELECT_AN_EVENT_TO_CONTINUE": "Seleziona un evento per continuare",
      "CHECKOUT": "Check-out",
      "POS_CART": "Carrello POS",
      "LOGOUT_POS": "Logout POS",
      "PRICE_LIST": "Listino prezzi",
      "DISCOUNT_PRICE": "Prezzo scontato",
      "PAY": "Paga",
      "DIFF": "Differenza",
      "CHANGE": "Resto",
      "GIFT_CARD": "Carta regalo",
      "CONFIRM_CHECKOUT": "Conferma check-out",
      "SUM": "Somma",
      "GIFT_NUMBER": "Numero regalo",
      "GIFT_NOT_FOUND": "Regalo non trovato",
      "NUMBER": "Numero",
      "CLIENT": "Cliente",
      "IMPORT": "Importa",
      "CREATE_DATE": "Data di creazione",
      "EXPIRE_DATE": "Data di scadenza",
      "GIFT_IN_LIST": "Regalo nella lista",
      "USE": "Usa",
      "SELECT_A_PAYMENT_TYPE": "Seleziona un tipo di pagamento",
      "ITEM_INSERT": "Inserisci elemento",
      "NEW_TICKET": "Nuovo scontrino",
      "SHARED_TICKET": "Scontrini condivisi",
      "DELETE_TICKET": "Rimuovi scontrino",
      "SELECT_DEPARTMENT_AND POS": "Seleziona reparto e POS",
      "SELECT_DEPARTMENT_AND_POS_TO_CONTINUE": "Seleziona reparto e POS per continuare",
      "SURNAME": "Cognome",
      "PHONE": "Telefono",
      "FISCAL": "Fiscale",
      "PERSON_COMPANY": "Persona/Giuridica",
      "COMPANY_NAME": "Nome azienda",
      "COMPANY_VAT": "Partita IVA aziendale",
      "COMPANY_FISCAL": "Codice fiscale aziendale",
      "CANCEL": "Annulla",
      "CANCELLED": "Annullato",
      "REMNANT": "Residuo",
      "TICKET_DATE": "Data dello scontrino",
      "GRAND_TOTAL": "Totale generale",
      "REPRINT": "Ristampa",
      "REFUND": "Rimborso",
      "EDIT_TICKET": "Modifica scontrino",
      "FISCAL_CODE": "Codice fiscale",
      "USER": "Utente",
      "PERSON": "Persona",
      "TICKET_REF": "Riferimento biglietto",
      "TICKET_NUMBER": "Numero scontrino",
      "TICKET_TYPE_ABORTED": "Ticket di annullo",
      "CHANGE_SALES": "Cambia venditore",

      "FROM": "Da",
      "GOTO_POS": "Vai al POS",
      "DESELECT_CUSTOMER": "Rimuovi cliente",
      "TOTAL_AMOUNT": "Importo totale",
      "SEARCH_PLACEHOLDER": "Ricerca...",
      "NEW_TICKET_CONFIRM": "Conferma nuovo scontrino",
      "SELECT_SHARED_TICKET": "Seleziona scontrini condivisi",
      "DELETE_TICKET_CONFIRM": "Conferma eliminazione scontrino",
      "INFO_PRODUCTS": "Informazione prodotti",

      "TOTALINCOMING": "Totale incassato",
      "CASH": "Contanti",
      "CARD": "Carta",
      "GIFT": "Buoni",

      "CASH_CLOSING": "Chiudi cassa",
      "GOTO_CASH_CLOSING": "Vai alla chiusura cassa",
      "OPEN_FISCAL_DRAWER": "Apri cassetto",
      "CASH_CLOSING_SEARCH": "Cerca chiusure",
      "BALANCE": "Giacenza",
      "ADD_MOVEMENT": "Aggiungi Movimento",

      "REQUEST_CLOSED_CASH": "Confermi di voler chiudere la cassa?",
      "CLOSED_CASH_OLD_TITLE": "Attenzione",
      "CLOSED_CASH_OLD_LABEL": "Procedere con la chiusura di cassa dei giorni precedenti prima di iniziare.",

      "SELECT_A_CLOSEDCASH_TO_CONTINUE": "Seleziona una chiusura di cassa.",
      "REFUND_ITEM": "Cambio",

      "SEND_DELTA": "Invio DELTA in chiusura parziale",
      "AVERAGE_ITEM_TICKET": "Media oggetti per scontrino",
      "AVERAGE_TICKET": "Importo medio scontrino",
      "AVERAGE_ITEM": "Prezzo medio oggetto",
      "TICKETS": "Scontrini",
      "TICKETS_ABORTED": "Scontrini annullati",
      "TOTAL_TICKETS": "Totale scontrini",
      "TOTAL_ITEMS": "Totale oggetti",
      "ITEMS_REFUND": "Oggetti rimborsati",
      "ITEMS": "Oggetti",
      "GIFTOUT": "Gift emessa",
      "GIFTIN": "Gift incassata",
      "OTHER": "Altro",
      "AVERAGE": "media",
      "AMOUNT": "importo",
      "AMOUNT_AVERAGE": "importo medio",
      "TICKET": {
        "TOTAL": "totale",
        "T": "scontrino",
        "SALE": "vendita",
        "REFUND": "reso",
        "ABORT": "annullo",
        "ABORTED": "annullato",
        "ITEM": "voce",
        "ITEMS": "voci"
      },
      "PRINTER_PENDING":{
        "PRINTER_PENDING": "stampe scontrini in sospeso",
        "PRINTER_LEFT": "scontrini da stampare"
      },

      "SEARCH_A_PRODUCT_TO_CONTINUE": "Cerca un Prodotto per Continuare",

      "POS_CONFIG": "PoS Config",
      "JASPER_REPORT": "Jasper Report",
      "PRINT_TYPE": "Tipo Stampa",
      "POSITIONAL_INVENTORY": "Inventario Posizionale",
      "USE_POSITIONAL_SEPARATOR": "Usa Separatore in Inventario Posizionale",
      "POSITION_SIZE": "Numero Elementi per 'Ubicazione'",
      "POSITION_SEPARATOR": "Separatore 'Ubicazione'",
      "TASK_IS_NOT_INVENTORY": "Il Task Type non è un Inventario",

      "USE_PACK_GENERATOR": "Usa generatore Collo",
      "PACK_MASK": "Maschera Collo",


      "SEND_STOCK_ONCLOSE": "Invia Giacenza alla Chiusura",
      "SEND_EXPECTED_ONCLOSE": "Invia Atteso alla Chiusura",
      "SEND_URL_ONCLOSE": "Invia Url alla Chiusura",


      "NO_CUSTOMER_SELECTED": "Nessun Cliente Selezionato",
      "STATUS_PRINTER": "Status Stampante",
      "RESET_PRINTER": "Resetta Stampante",
      "SELECT_DEPARTMENT_AND_POS": "Seleziona Dipartimento e PoS",
      "PRINTER_STATUS_OK": "Status Stampante OK",
      "PRINTER_PAPER_EMPTY": "carta Stampante Vuota",
      "PRINTER_PAPER_NEAR_EMPTY": "Carta Stampante in Esaurimento",
      "PRINTER_COVER_OPEN": "Coperchio Stampante Aperto",


      "MOVEMENT_DETAIL": "Dettaglio Movimenti",
      "MOVEMENT_INCOMING": "Movimenti in entrata",
      "ANALYSIS": "Analisi",
      "CLOSE_CASH": "Chiudi Cassa",
      "SELECT_A_TICKET": "Seleziona uno Scontrino",
      "HISTORY": "Storico",
      "CHANGE_ITEM": "Cambia Item",
      "PARTIAL_REFUND": "Rimborso Parziale",

      "PAYMENT": "Pagamenti",

      "SCAN": "Scansiona",

      "GIFT_NOT_VALID": "Gift non Valida",

      "GIFT_VALID": "Gift Valida",

      "GIFT_NOT_EXPENDABLE_HERE": "Gift non Spendibile in Negozio",

      "LOCAL": "Locale",
      "TYPE": "Tipo",
      "DISCOUNTS": "Sconti",
      "PRINTER_TEMPLATES": "Tempate Scontrini",
      "REMOVE_DEFAULT": "Rimuovi Default",
      "SET_DEFAULT": "Imposta come Default",
      "CONFIG": "Configurazione",

      "BLOCKING": "Blocca",
      "NOTIFY": "Notifica",
      "UNRESTRICTED": "Nessuna restrizione",


        "ABORT": "Annulla",
        "ADVANCED": "Avanzato",
        "AMOUNT_MONEY": "Importo Denaro",
        "BREAK_FIELDS": "Campi di Interruzione",
        "CAN_BE_USED_ON_DISCOUNT": "Può Essere Utilizzato su Sconto",
        "CAN_PRINT_PRODUCT": "Può Stampare Prodotto",
        "CASHIER": "Cassiere",
        "SALESPERSON": "Venditore",
        "SALESPERSONS": "Venditori",
        "CHECKOUT_NO_GIFT": "Pagamento senza Regalo",
        "CHECKOUT_NO_PRINTER": "Pagamento senza Stampante",
        "CONFIRM_CHECKOUT_MONEY": "Conferma Pagamento Denaro",
        "COUNT": "Conteggio",
        "CREATE_GIFT": "Crea Regalo",
        "CREATE_TIME": "Crea Tempo",
        "CURRENCY": "Valuta",
        "CUSTOM_ACTION": "Azione Personalizzata",
        "CUSTOM_ACTION_URL": "URL Azione Personalizzata",
        "CUSTOM_VALUE": "Valore Personalizzato",
        "DATA_OPERATION": "Operazione Dati",
        "DEFAULT_LABEL": "Etichetta Predefinita",
        "DELETE_READ_FOR": "Elimina Lettura per",
        "ENABLE_CUSFILTER": "Abilita Filtro Cliente",
        "ENABLE_DEFAULTSEARCH": "Abilita Ricerca Predefinita",
        "ENABLE_FILTER": "Abilita Filtro",
        "ENABLE_GIFTLIST": "Abilita Lista Regali",
        "ENABLE_KB_KEYBOARD": "Abilita Tastiera KB",
        "ENABLE_LISTSELECT": "Abilita Selezione Lista",
        "ENABLE_PIECHART": "Abilita Grafico a Torta",
        "ENABLE_READ": "Abilita Lettura",
        "ENABLE_RESEND": "Abilita Reinvio",
        "ENABLE_ROBOT": "Abilita Robot",
        "ENABLE_SUSPEND": "Abilita Sospendi",
        "ENABLE_TASKLIST": "Abilita Lista Compiti",
        "ENABLE_USERFILTER": "Abilita Filtro Utente",
        "ENABLE_VISIBILITYFILTER": "Abilita Filtro Visibilità",
        "END_DATE": "Data di Fine",
        "END_TIME": "Ora di Fine",
        "ERROR": "Errore",
        "ESTIMATED": "Stimato",
        "ESTIMATED_DATE": "Data Stimata",
        "ESTIMATED_TIME": "Ora Stimata",
        "ESTIMATION": "Stima",
        "ETD": "ETD",
        "EVENTS": "Eventi",
        "EXCEPTION": "Eccezione",
        "EXCLUDE_PARTIAL": "Escludi Parziale",
        "EXPECTED_DATE": "Data Prevista",
        "EXPECTED_END": "Fine Prevista",
        "EXPECTED_EVENT": "Evento Previsto",
        "EXPECTED_TIME": "Ora Prevista",
        "EXPORT_ENABLED": "Esportazione Abilitata",
        "EXPORT_LABEL": "Etichetta Esportazione",
        "EXPORT_PATH": "Percorso Esportazione",
        "EXPORT_TYPE": "Tipo di Esportazione",
        "EXTERNAL_REFERENCE": "Riferimento Esterno",
        "EXTRACTION_ERROR": "Errore di Estrazione",
        "FILTER": "Filtro",
        "FILTER_ENABLED": "Filtro Abilitato",
        "FILTER_LIST": "Lista Filtri",
        "FILTER_REQUIRED": "Filtro Richiesto",
        "FILTER_SELECT": "Selezione Filtro",
        "FINISHED": "Finito",
        "FLAGGED": "Contrassegnato",
        "FLUSH": "Svuota",
        "FOOTER": "Piè di Pagina",
        "FORMAT": "Formato",
        "FROM_LOCATION": "Da Posizione",
        "FROM_TASK": "Da Compito",
        "FULL_TEXT_SEARCH": "Ricerca Testo Completo",
        "GENERATE": "Genera",
        "GENERATE_DOC": "Genera Documento",
        "GENERATE_NEW": "Genera Nuovo",
        "GENERATE_REPORT": "Genera Rapporto",
        "GENERATE_SELECTED": "Genera Selezionato",
        "GIFT_ID": "ID Regalo",
        "GIFT_LABEL": "Etichetta Regalo",
        "GIFT_LIST": "Lista Regali",
        "GIFT_REPORT": "Rapporto Regali",
        "GIFT_TYPE": "Tipo di Regalo",
        "GO": "Vai",
        "GOAL": "Obiettivo",
        "GROUP_LIST": "Lista Gruppi",
        "GROUP_TYPE": "Tipo di Gruppo",
        "HISTORY_LOG": "Registro Storico",
        "HOUR": "Ora",
        "HOURS": "Ore",
        "IMPORT_CONFIG": "Importa Configurazione",
        "INCLUDE_PARTIAL": "Includi Parziale",
        "INCOMPLETE": "Incompleto",
        "INCORRECT_DATA": "Dati Errati",
        "INDIVIDUAL": "Individuale",
        "INDIVIDUAL_TASKS": "Compiti Individuali",
        "INFO": "Info",
        "INFORMATION": "Informazione",
        "INSERT": "Inserisci",
        "INSERT_GIFT": "Inserisci Regalo",
        "INSERT_TASK": "Inserisci Compito",
        "INSERT_TICKET": "Inserisci Biglietto",
        "INTELLIGENCE": "Intelligenza",
        "ITEM_ID": "ID Elemento",
        "ITEM_NAME": "Nome Elemento",
        "ITEM_PRICE": "Prezzo Elemento",
        "ITEMS_LIST": "Lista Elementi",
        "ITEMS_SELECTED": "Elementi Selezionati",
        "ITEM_SELECT": "Selezione Elemento",
        "JOB": "Lavoro",
        "JOB_LIST": "Lista Lavori",
        "JOB_TYPE": "Tipo di Lavoro",
        "KEYBOARD": "Tastiera",
        "KEYBOARD_BUTTON": "Pulsante Tastiera",
        "KNOWLEDGE": "Conoscenza",
        "LABEL": "Etichetta",
        "LABELS": "Etichette",
        "LANGUAGE": "Lingua",
        "LANGUAGE_LIST": "Lista Lingue",
        "LAST_CHANGE": "Ultima Modifica",
        "LAST_EVENT": "Ultimo Evento",
        "LAST_GENERATED": "Ultimo Generato",
        "LAST_MODIFIED": "Ultima Modifica",
        "LAST_STATUS": "Ultimo Stato",
        "LATENCY": "Latenza",
        "LATEST": "Ultimo",
        "LEVEL": "Livello",
        "LIMIT": "Limite",
        "LIMITED": "Limitato",
        "LINK": "Collegamento",
        "LIST": "Lista",
        "LIST_DATE": "Data Lista",
        "LIST_MODE": "Modalità Lista",
        "LOCATION_LIST": "Lista Posizioni",
        "LOG": "Registro",
        "LOGS": "Registri",
        "LOG_LIST": "Lista Registri",
        "LOGIN": "Accesso",
        "LOGOUT": "Disconnessione",
        "MANAGE": "Gestisci",
        "MANAGER": "Manager",
        "MANUAL": "Manuale",
        "MARK_AS_READ": "Segna come Letto",
        "MAX": "Max",
        "MAX_ITEMS": "Max Elementi",
        "MAX_PRICE": "Prezzo Massimo",
        "MEASURE": "Misura",
        "MEASURES": "Misure",
        "MEMBERS": "Membri",
        "MENU": "Menu",
        "MESSAGE": "Messaggio",
        "MESSAGE_CENTER": "Centro Messaggi",
        "MESSAGE_LIST": "Lista Messaggi",
        "MESSAGE_TYPE": "Tipo di Messaggio",
        "MESSAGES": "Messaggi",
        "MESSAGES_LIST": "Lista Messaggi",
        "MESSAGE_TYPE_LIST": "Lista Tipi di Messaggio",
        "MIN": "Min",
        "MIN_ITEMS": "Min Elementi",
        "MIN_PRICE": "Prezzo Minimo",
        "MINUTES": "Minuti",
        "MODE": "Modalità",
        "MODIFY": "Modifica",
        "MONTH": "Mese",
        "MONTHS": "Mesi",
        "MORE": "Altro",
        "MOVE": "Sposta",
        "MOVE_DOWN": "Sposta Giù",
        "MOVE_ITEM": "Sposta Elemento",
        "MOVE_UP": "Sposta Su",
        "MULTIPLE": "Multiplo",
        "NAMES": "Nomi",
        "NEW": "Nuovo",
        "NEW_TASK": "Nuovo Compito",
        "NO": "No",
        "NOTES": "Note",
        "NOTIFICATIONS": "Notifiche",
        "NUMBERS": "Numeri",
        "NUMBER_LIST": "Lista Numeri",
        "OBJECTS": "Oggetti",
        "OBJECT_LIST": "Lista Oggetti",
        "OK": "OK",
        "ON": "Acceso",
        "ONLY": "Solo",
        "OPTIONS": "Opzioni",
        "ORDERS": "Ordini",
        "ORDER_DATE": "Data Ordine",
        "ORDER_ID": "ID Ordine",
        "ORDER_LIST": "Lista Ordini",
        "ORDER_STATUS": "Stato Ordine",
        "ORGANIZATION": "Organizzazione",
        "OUTPUT": "Output",
        "PAGE": "Pagina",
        "PAGES": "Pagine",
        "PAID": "Pagato",
        "PERCENTAGE": "Percentuale",
        "PERIOD": "Periodo",
        "PERIODS": "Periodi",
        "PLACE": "Luogo",
        "PLACES": "Luoghi",
        "PLANNING": "Pianificazione",
        "PLANNING_LIST": "Lista Pianificazione",
        "PLANS": "Piani",
        "POSITION": "Posizione",
        "POSITIONS": "Posizioni",
        "POST": "Posta",
        "POST_LIST": "Lista Posta",
        "PREFERENCE": "Preferenza",
        "PREFERENCES": "Preferenze",
        "PREVIEW": "Anteprima",
        "PRICE": "Prezzo",
        "PRIORITY": "Priorità",
        "PRIVACY": "Privacy",
        "PROBLEM": "Problema",
        "PROBLEMS": "Problemi",
        "PROCEDURE": "Procedura",
        "PROCEDURES": "Procedure",
        "PROCESS": "Processo",
        "PROCESSING": "Elaborazione",
        "PROCESSES": "Processi",
        "PRODUCT": "Prodotto",
        "PROGRESS": "Progresso",
        "PROJECT": "Progetto",
        "PROJECTS": "Progetti",
        "PROTOCOL": "Protocollo",
        "PROXY": "Proxy",
        "PURCHASE": "Acquisto",
        "QUANTITY": "Quantità",
        "RANGE": "Intervallo",
        "RANGES": "Intervalli",
        "RATE": "Tasso",
        "RATES": "Tassi",
        "READ_ONLY": "Sola Lettura",
        "READ_WRITE": "Lettura e Scrittura",
        "REAL_TIME": "Tempo Reale",
        "RECEIPT": "Ricevuta",
        "RECEIPTS": "Ricevute",
        "RECENT": "Recente",
        "RECORD": "Record",
        "RECORDS": "Record",
        "RECURRING": "Ricorrente",
        "REGISTER": "Registro",
        "REGISTRATION": "Registrazione",
        "REJECT": "Rifiuta",
        "REJECTED": "Rifiutato",
        "RELEASE": "Rilascia",
        "REMOVE": "Rimuovi",
        "REPORT": "Rapporto",
        "REPORTS": "Rapporti",
        "REQUEST": "Richiesta",
        "REQUESTS": "Richieste",
        "RESOLUTION": "Risoluzione",
        "RESOURCE": "Risorsa",
        "RESOURCES": "Risorse",
        "RESPONSE": "Risposta",
        "RESPONSES": "Risposte",
        "RESTART": "Riavvia",
        "RESTORE": "Ripristina",
        "RESULT": "Risultato",
        "RESULTS": "Risultati",
        "RESUME": "Riprendi",
        "RETRY": "Riprova",
        "RETURN": "Ritorno",
        "REVIEW": "Recensione",
        "REVIEWS": "Recensioni",
        "ROLES": "Ruoli",
        "ROUTE": "Percorso",
        "ROUTES": "Percorsi",
        "ROW": "Riga",
        "SCHEDULE": "Programma",
        "SCHEDULES": "Programmi",
        "SECTION": "Sezione",
        "SECTIONS": "Sezioni",
        "SECURITY": "Sicurezza",
        "SELECT": "Seleziona",
        "SELECTION": "Selezione",
        "SELECT_OPTION": "Seleziona Opzione",
        "SEND": "Invia",
        "SENT": "Inviato",
        "SERVICE": "Servizio",
        "SERVICES": "Servizi",
        "SESSION": "Sessione",
        "SESSIONS": "Sessioni",
        "SETUP": "Configurazione",
        "SHOW": "Mostra",
        "SHOW_LIST": "Mostra Lista",
        "SINGLE": "Singolo",
        "SIZES": "Dimensioni",
        "SKIP": "Salta",
        "SMS": "SMS",
        "SOLUTION": "Soluzione",
        "SOLUTIONS": "Soluzioni",
        "SOURCE": "Fonte",
        "SOURCES": "Fonti",
        "SPECIFIC": "Specifico",
        "SPEED": "Velocità",
        "START_DATE": "Data di Inizio",
        "START_TIME": "Ora di Inizio",
        "STATISTICS": "Statistiche",
        "STEP": "Passo",
        "STEPS": "Passi",
        "STOP": "Ferma",
        "STORE": "Negozio",
        "STORES": "Negozi",
        "STORAGE": "Archiviazione",
        "STRATEGY": "Strategia",
        "STRATEGIES": "Strategie",
        "SUBMIT": "Invia",
        "SUMMARY": "Sommario",
        "SUPPORT": "Supporto",
        "SUSPEND": "Sospendi",
        "SYSTEM": "Sistema",
        "SYSTEMS": "Sistemi",
        "TEMPLATES": "Modelli",
        "TEST": "Test",
        "TESTS": "Test",
        "TEXT": "Testo",
        "TIME": "Tempo",
        "TIMEOUT": "Timeout",
        "TIMES": "Tempi",
        "TITLE": "Titolo",
        "TO": "A",
        "TODAY": "Oggi",
        "TOOL": "Strumento",
        "TOOLS": "Strumenti",
        "TOTALS": "Totali",
        "TRACK": "Traccia",
        "TRACKING": "Tracciamento",
        "TRANSACTION": "Transazione",
        "TRANSACTIONS": "Transazioni",
        "TRANSFER": "Trasferisci",
        "TRANSLATE": "Traduci",
        "TRANSLATION": "Traduzione",
        "TRANSLATIONS": "Traduzioni",
        "UNREAD": "Non Letto",
        "UPDATE": "Aggiorna",
        "UPDATES": "Aggiornamenti",
        "UPGRADE": "Aggiorna",
        "URL": "URL",
        "USERS": "Utenti",
        "USERNAME": "Nome Utente",
        "VALIDATE": "Convalida",
        "VALIDATION": "Validazione",
        "VALUES": "Valori",
        "VERSIONS": "Versioni",
        "VIEW": "Visualizza",
        "VIEWS": "Visualizzazioni",
        "VISIBILITY": "Visibilità",
        "VISIBLE": "Visibile",
        "WAIT": "Attendi",
        "WAITING": "In Attesa",
        "WARNINGS": "Avvisi",
        "WEB": "Web",
        "WEEK": "Settimana",
        "WEEKS": "Settimane",
        "WELCOME": "Benvenuto",
        "WIN": "Vinci",
        "WINDOW": "Finestra",
        "WINDOWS": "Finestre",
        "WORK": "Lavoro",
        "WORKFLOW": "Flusso di Lavoro",
        "WORKS": "Lavori",
        "WRITE": "Scrivi",
        "WRITING": "Scrittura",
        "YEAR": "Anno",
        "YEARS": "Anni",
        "YES": "Sì",
        "ZIP": "ZIP",
        "RECHARGE":"Ricarica",
        "USAGE":"Utilizzo",
        "ACTIVATION":"Attivazione",

        "SMARTBI": "SmartBI",
        "ENABLE_SUPERSET": "Abilita SmartBI Business Intelligence",
        "SUPERSET_DASHBOARD_ID": "SmartBI Dashboard Id",
        "SUPERSET_USERNAME": "SmartBI Username",
        "SUPERSET_PASSWORD": "SmartBI Password",

        "MASK_SHOULD_CONTAIN_ALMOST_1_VARIABLE": "La Maschera deve contenere almeno una Variabile",
        "MASK_SHOULD_CONTAIN_REGEX": "La Maschera deve contenere una Regex",
        "PIECES": "Pezzi",
        "INTERNAL_SERVER_ERROR": "Errore interno",
        "TICKET_NOT_ABORTED": "Scontrino non annullato",

        "CLEAR_FILTERS": "Cancella Filtri",
        "NO_FILTERS_USED": "Nessun Filtro Impostato",

        "USE_LOCAL_ENDPOINT": "Usa Endpoint Locale",
        "LOCAL_ENDPOINT": "Endpoint Locale",

        "VERIFY": "Verifica Item",

        "PROD_EXTRA_FIELDS": "Items Extra Fields",

        "REFERENCE_SUBSTRING": "Reference Substring",
        "USE_REF_SUBSTRING": "Use Reference Substring",

        "GENERAL_INFO": "Dettagli",

        // New pos GUI
        "EXIT": "Esci",
        "NEW_RECEIPT": "Inizia nuovo scontrino",
        "SUSPEND_RECEIPT": "Elimina Scontrino",
        "SUSPENDED_RECEIPT": "Scontrini sospesi",
        "SEARCH_PRODUCT": "Ricerca prodotto",
        "CHECK_STOCK": "Verifica magazzino",
        "TICKETSLIST": "Lista scontrini",
        "PRINTER_STATUS": "Stato Stampante",
        "CASHDRAWER_STATUS": "Stato Cassetto",
        "LINEDISPLAY_STATUS": "Stato Display",
        "SEARCH_CLOSED_CASH": "Ricerca chiusure di cassa",
        "LOADING": "Caricamento",

        "REMOVE_ITEM": "Rimuovi articolo",
        "DELETE_ITEM": "Rimuovi articolo",
        "CHANGE_VIEW_MODE": "Cambia modalità di visualizzazione",
        "STOCK_REPORT": "Report giacenza",
        "SURE_LOGOUT": "Sicuro di voler fare il logout",
        "PRINT_GIFT_TICKET": "Stampa scontrino regalo",
        "GENERATE_INVOICE": "Genera fattura",
        "MIN_TICKET_VALUE": "Spesa min.",
        "AVAIBLE_COUPON": "Coupon disponibili",

        // Tastierino numerico
        "openCashDrawer": "Apri cassetto cassa",
        "voidPrinter": "Resetta stampante",
        "getStatusPrinter": "Stato stampante",
        "ticketDiscount": "Applica sconto allo scontrino",
        "changeMode": "Cambia modalità di visualizzazione",
    },







    "PRINTER": {
      "PAPER_NEAR_EMPTY": "Carta stampante quasi esaurita",
      "PAPER_EMPTY": "Carta stampante esaurita",
      "STATUS_OK": "Stato ok stampante",
      "TIMEOUT": "Stampante non risponde",
      "RESET": "Resetta stampa",
      "WAIT": "Attesa stampante"
    },



}
