import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { TokenStorageService } from '@app/shared/auth/token-storage.service';
import { AuthService } from '@auth0/auth0-angular';

@Component({
    selector: 'nav-profile',
    templateUrl: './nav-profile.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class.header-nav-item]': 'true'
    }
})
export class NavProfileComponent implements OnInit {
    constructor(private tokenStorage: TokenStorageService,public auth: AuthService) { }

    user = this.tokenStorage.getUser();

    ngOnInit(): void { }

    logout(): void {
      this.tokenStorage.signOut();

      /*
      try {
        this.auth.logout({ logoutParams: { returnTo: document.location.origin } })
      }
      catch(err) {}
      */

      window.location.reload();
    }
}
