import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'postest-layout',
    templateUrl: './postest-layout.component.html',
})
export class PostestLayoutComponent implements OnInit {
    constructor() { }

    ngOnInit(): void { }
}
