import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_ENDPOINT } from '@app/configs/app.config';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
    providedIn: 'root'
})
export class CustomerConfigService {
    constructor(private http: HttpClient) {}

    getList(customerId:string): Observable<any[]> {
      return this.http.get<any[]>(`${API_ENDPOINT}api/v1/exportConfig/${customerId}`, httpOptions);
    }

    getFileTypes(customerId:string): Observable<any[]> {
      return this.http.get<any[]>(`${API_ENDPOINT}api/v1/fileFormat/${customerId}`, httpOptions);
    }

    insert(exportConfig:any): Observable<any> {
      return this.http.post(`${API_ENDPOINT}api/v1/exportConfig`, exportConfig, httpOptions);
    }

    update(id:string, exportConfig:any): Observable<any> {
      return this.http.put(`${API_ENDPOINT}api/v1/exportConfig/${id}`, exportConfig, httpOptions);
    }

    delete(id:string): Observable<any> {
      return this.http.delete(`${API_ENDPOINT}api/v1/exportConfig/${id}`, httpOptions);
    }

    getServerConfig(): Observable<any[]> {
      return this.http.get<any[]>(`${API_ENDPOINT}api/v1/config`, httpOptions);
    }


}
