import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { AppConfig } from '@app/shared/types/app-config.interface';
import { Observable, Subscription } from 'rxjs';
import { en_US } from './i18n/en/index';
import { fr_FR } from './i18n/fr/index';
import { it_IT } from './i18n/it/index';
import { jp_JP } from './i18n/jp/index';

import { webSocket } from 'rxjs/webSocket';

import { WS_ENDPOINT } from '@app/configs/app.config';
import { ActivatedRoute } from '@angular/router';
import { TokenStorageService } from './shared/auth/token-storage.service';
import { NavbarService } from './layout/components/header-navbar/header-navbar.service';
import { NavService } from './configs/nav.service';
import moment from 'moment';
import { SwUpdate } from '@angular/service-worker';

const storageKey = 'lang';
var subject;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  @Select((state: { app: AppConfig }) => state.app) app$: Observable<AppConfig>;
  private langChangeSubscription!: Subscription;
  currentLang: string;

  websocketMessage: any;

  oktaCode: string;

  constructor(
    //private swUpdate: SwUpdate, 
    private translateService: TranslateService, private route: ActivatedRoute, private tokenStorage: TokenStorageService, private navbarService: NavbarService, private navService: NavService) {
    translateService.setTranslation('en_US', en_US);
    translateService.setTranslation('fr_FR', fr_FR);
    translateService.setTranslation('it_IT', it_IT);
    translateService.setTranslation('jp_JP', jp_JP);
    // if (this.swUpdate.isEnabled) {
    //   this.swUpdate.versionUpdates.subscribe((event) => {
    //     if (event.type === 'VERSION_READY') {
    //       if (confirm('New version available. Load New Version?')) {
    //         window.location.reload();
    //       }
    //     }
    //   });
    // }
  }

  ngOnInit() {
    this.app$.subscribe((app) => {
      this.currentLang = localStorage.getItem(storageKey) || app.lang || this.translateService.getBrowserCultureLang();
      console.log(this.translateService.getBrowserCultureLang());
      this.translateService.use(this.currentLang);
      moment.locale(this.currentLang);

    });
    this.langChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      localStorage.setItem(storageKey, event.lang);
    });

    if (this.tokenStorage.getC()) {
      this.navbarService.getNavbar(this.navService.getNavbar());
    }

    subject = webSocket(`${WS_ENDPOINT}websocket/notify`);
    this.websocket();

    this.route.queryParams.subscribe((params) => {
      console.log(params);
      this.oktaCode = params.code;
      console.log(this.oktaCode);

      //alert(this.oktaCode)
    });
  }

  ngOnDestroy() {
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }

  websocket() {
    subject.subscribe(
      (msg) => {
        //console.log('message received: ' + JSON.stringify(msg));
        this.websocketMessage = msg;
        //alert(JSON.stringify(msg));
      },
      (err) => {
        console.log('Error with websocket connection:', err);
        this.websocketReconnect();
      },
      () => {
        console.log('disconnect... try to reconnect');
        this.websocketReconnect();
      }
    );
  }

  websocketReconnect() {
    setTimeout(() => {
      console.log('sleep');
      this.websocket();
    }, 5000);
  }
}
