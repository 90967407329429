import { Component, OnInit, ChangeDetectionStrategy, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TokenStorageService } from '@app/shared/auth/token-storage.service';
import { Router } from '@angular/router';


import { CustomerConfigService } from './nav-config.service';


@Component({
    selector: 'server-config',
    templateUrl: './server-config.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class.header-nav-item]': 'true'
    },
    providers: [
      CustomerConfigService
    ],
})
export class ServerConfigComponent implements OnInit {
    modalRef: BsModalRef;

    user: any;
    customer: string;
    customerName: string;

    config: any;
    sConfig: string;


    constructor(
      private modalService: BsModalService,
      public bsModalRef: BsModalRef,

      private tokenStorage: TokenStorageService,
      private router: Router,
      private ccService: CustomerConfigService,
      private ref: ChangeDetectorRef

    ) {

    }
    ngOnInit () {

      this.user=this.tokenStorage.getUser();
      this.customer=this.tokenStorage.getCustomer();
      this.customerName=this.tokenStorage.getCustomerName();

      this.fetch();

    }

    fetch() {

      this.ccService.getServerConfig().subscribe(data => {

        this.config = data;
        this.sConfig = JSON.stringify(data);
        this.ref.detectChanges();
      },
      err => {
          if(err.error.message=='Error: Unauthorized') {
              window.sessionStorage.clear();
              window.location.reload();
          }
      });



    }



}
