<div class="container">
  <div class="row style-fiscalprinter">
    <div class="col-12 center stretch">DOCUMENTO COMMERCIALE</div>
    <div class="col-12"></div>
    <div class="col-12 center bold">DOCUMENTO COMMERCIALE</div>
    <ng-container [ngSwitch]="ticketType">
      <div class="col-12 center bold" *ngSwitchCase="'SALE'">di vendita o prestazione</div>
      <div class="col-12 center bold" *ngSwitchCase="'RETURN'">emesso per RESO</div>
    </ng-container>

    <ng-container *ngIf="ticketType === 'RETURN'">
      <div class="col-12"></div>
      <div class="col-12 center bold">Documento di riferimento</div>
      <div class="col-12 center bold">N.{{ fiscalCode }} del {{ date | momentPipe : "DD-MM-YYYY" }}</div>
    </ng-container>

    <div class="col-12"></div>
    <div class="col-5 bold">Descrizione</div>
    <div class="col-3 bold right">IVA</div>
    <div class="col-4 bold right">Prezzo(€)</div>

    <ng-container *ngFor="let item of line">
      <div class="col-5 noWhiteSpace dinamicMultiLine">{{ item.shortDescription.addSpaceEveryXChars(20) }}</div>
      <div class="col-3 right dinamicMultiLine">{{ item.tax.toCurrency() }}%</div>
      <div class="col-4 right dinamicMultiLine">{{ item.discountPrice.toNumberMoney().toCurrency() }}</div>
    </ng-container>

    <div class="col-12"></div>
    <div class="col-6 bold stretch">TOTALE COMPLESSIVO</div>
    <div class="col-6 bold right stretch">{{ total.fromFiscalPrinter().toNumberMoney().toCurrency() }}</div>
    <div class="col-6 bold stretch">di cui IVA</div>
    <div class="col-6 bold right stretch">{{ vat.fromFiscalPrinter().toNumberMoney().toCurrency() }}</div>
    <div class="col-12"></div>

    <ng-container *ngIf="ticketType === 'SALE'">
      <ng-container *ngFor="let item of paymentMethodGroup">
        <div class="col-6">{{ item.group === "CASH" ? "Pagamento contante" : item.group === "CARD" ? "Pagamento elettronico" : "Pagamento gift" }}</div>
        <div class="col-6 right">{{ item.total.toNumberMoney().toCurrency() }}</div>
      </ng-container>
      <ng-container *ngIf="cashback != 0">
        <div class="col-6">Resto</div>
        <div class="col-6 right">{{ cashback.toNumberMoney().toCurrency() }}</div>
      </ng-container>
      <ng-container>
        <div class="col-6">Importo pagato</div>
        <div class="col-6 right">{{ total.fromFiscalPrinter().toNumberMoney().toCurrency() }}</div>
      </ng-container>
    </ng-container>

    <div class="col-12"></div>
    <div class="col-12 center">{{ date | momentPipe : "DD-MM-YYYY HH:mm" }}</div>
    <div class="col-12 center">DOCUMENTO N. {{ fiscalCode }}</div>
    <ng-container *ngIf="giftRefund">
      <div class="col-12"></div>
      <ticket-preview-line [item]="{text: '{{SPLITLINE|*}}'}" [extra]="{}" [length]="46"></ticket-preview-line>
      <div class="col-12">Gift di reso generata:</div>
      <div class="col-12">Importo GIFT: {{ giftRefund.amount.toNumberMoney().toCurrency() }}</div>
      <ticket-preview-line [item]="{text: '{{SPLITLINE|*}}'}" [extra]="{}" [length]="46"></ticket-preview-line>
    </ng-container>
    <div class="col-12"></div>
    <div class="col-12 center">{{ fiscalPrinter }}</div>

    <ng-container *ngIf="ticketType === 'SALE'">
      <div class="col-12 center bold">DETTAGLIO FORME di PAGAMENTO</div>

      <ng-container *ngFor="let item of paymentMethod">
        <ng-container *ngIf="!item.refund">
          <div class="col-6">{{ item.paymentType.name }}</div>
          <div class="col-6 right">{{ item.amount.toNumberMoney().toCurrency() }}</div>
        </ng-container>
      </ng-container>
      <div class="col-12"></div>
    </ng-container>
  </div>
</div>
