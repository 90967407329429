<div
    class="header-nav-wrap"
    [ngClass]="{'container-fluid': layoutType === 'horizontal'}"
>
    <ng-container *ngTemplateOutlet="layoutType === 'vertical' ? vertical : horizontal"></ng-container>
</div>

<ng-template #vertical>
    <div class="header-nav-left">
        <nav-toggle [isMobile]="isMobile"></nav-toggle>


      <div class="header-nav-item cursor-pointer" style="margin-left:20px" *ngIf="!isMobile">
        {{customerName}}{{posDepartment?(' - Point of Sale: '+posDepartment.name):''}}
      </div>

      </div>

    <ng-container *ngTemplateOutlet="headerNavRight"></ng-container>
</ng-template>

<ng-template #horizontal>
    <div class="header-nav-left">
        <!-- <nav-toggle [isMobile]="isMobile"></nav-toggle> -->
        <logo [white]= "logoColor !== 'dark'" height="50" style="margin-top: 10px;margin-bottom: 10px;"></logo>
    </div>

    <ng-container *ngTemplateOutlet="headerNavRight"></ng-container>
</ng-template>

<ng-template #headerNavRight>
    <div class="header-nav-right">
      <nav-i18n></nav-i18n>
      <nav-config></nav-config>

    </div>
</ng-template>
