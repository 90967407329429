import { AppConfig } from '@app/shared/types/app-config.interface';
import { defaultLanguge } from './i18n.config';

export type NavMenuColor = 'light' | 'dark';

var hostname = window.location.hostname;
var menuColor: NavMenuColor = 'dark';
if (hostname.includes('smartrfid') || hostname.includes('liujotest') || hostname.includes('localhost') || hostname.includes('azurewebsites.net')) {
  menuColor = 'dark';
} else {
  menuColor = 'light';
}
export const posUrl = '/poshome';

export const AppConfiguration: AppConfig = {
  layoutType: 'vertical',
  sideNavCollapse: true,
  mobileNavCollapse: false,
  lang: defaultLanguge,
  navMenuColor: menuColor,
  headerNavColor: '#ffffff',
};

// Superset Domain
export const SUPERSET_DOMAIN = 'https://superset.localtest.cloud';

// Change your API endpoint here
export const API_ENDPOINT = 'http://localhost:10000/';
export const WS_ENDPOINT = 'ws://localhost:10000/';

// export const API_ENDPOINT = 'http://gestionecampionario.temera.it/smartrfidbe/'
// export const WS_ENDPOINT = 'ws://gestionecampionario.temera.it/smartrfidbe/'

//export const API_ENDPOINT = 'http://13.94.141.15:8080/smartrfidbe/'
//export const WS_ENDPOINT = 'ws://13.94.141.15:8080/smartrfidbe/'

// LIUJO PROD
// export const API_ENDPOINT = 'https://campionario.azurewebsites.net/smartrfidbe/'
// export const WS_ENDPOINT = 'wss://campionario.azurewebsites.net/smartrfidbe/'

// LIUJO PROD NEW
//export const API_ENDPOINT = 'https://smartrfidmc.azurewebsites.net/smartrfidbe/'
//export const WS_ENDPOINT = 'wss://smartrfidmc.azurewebsites.net/smartrfidbe/'

// LIUJO TEST
// export const API_ENDPOINT = 'https://smartrfidtest.azurewebsites.net/smartrfidbe/'
// export const WS_ENDPOINT = 'wss://smartrfidtest.azurewebsites.net/smartrfidbe/'

// AZURE TEST (DEV)
// export const API_ENDPOINT = 'https://smartrfid2.azurewebsites.net/smartrfidbe/'
// export const WS_ENDPOINT = 'wss://smartrfid2.azurewebsites.net/smartrfidbe/'

//AZURE TEMERA TMRRFID-TEST (Dolce&Gabbana/Boggi/Moncler TEST)
// export const API_ENDPOINT = 'https://tmrrfidtest.azurewebsites.net/smartrfidbe/'
// export const WS_ENDPOINT = 'wss://tmrrfidtest.azurewebsites.net/smartrfidbe/'

//AZURE TEMERA TMRRFID (Dolce&Gabbana/Boggi/Moncler PROD)
// export const API_ENDPOINT = 'https://tmrrfid.azurewebsites.net/smartrfidbe/'
// export const WS_ENDPOINT = 'wss://tmrrfid.azurewebsites.net/smartrfidbe/'

//AZURE TEMERA TMRRFID-GUCCI-TEST (Gucci TEST)
// export const API_ENDPOINT = 'https://gestionecampionario-test.globaltech.gucci/smartrfidbe/'
// export const WS_ENDPOINT = 'wss://gestionecampionario-test.globaltech.gucci/smartrfidbe/'

//AZURE TEMERA TMRRFID-GUCCI (Gucci PROD)
// export const API_ENDPOINT = 'https://gestionecampionario.globaltech.gucci/smartrfidbe/'
// export const WS_ENDPOINT = 'wss://gestionecampionario.globaltech.gucci/smartrfidbe/'

//ENABLE USER REGISTRATION (default true)
export const USER_CAN_REGISTER = true;
//ENABLE OKTA LOGIN (default false)
export const OKTA_LOGIN = false;
export const VERSION = 'Version 1.52.3';

// Gucci
/*
export const ACTIVEDIRECTORIES = [
  { id: 'LOCAL', name: 'Local Active Directory'},
  { id: 'OKTA', name: 'Kering OKTA Active Directory'}
]
*/

// Others
export const ACTIVEDIRECTORIES = [{ id: 'LOCAL', name: 'Local Active Directory' }];
