import { NgModule, ModuleWithProviders } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { UploadService } from './upload.service';
import { UploadComponent } from './upload.component';
import { UploadListComponent } from './upload-list.component';
import { UploadDraggerComponent } from './upload-dragger.component';
import {HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from '@app/shared/interceptor/token.interceptor';


@NgModule({
    declarations: [UploadComponent, UploadListComponent, UploadDraggerComponent],
    exports: [UploadComponent],
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
    ],
    //entryComponents: [UploadComponent],
    providers: [{
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    }]

})
export class UploadModule {
    static forRoot(): ModuleWithProviders<UploadModule> {
        return { ngModule: UploadModule, providers: [UploadService,{
          provide: HTTP_INTERCEPTORS,
          useClass: JwtInterceptor,
          multi: true
      },] }
    }
}
