import { Component, OnInit, Input } from '@angular/core';
import { NavMenu } from '@app/shared/types/nav-menu.interface';
import { navConfiguration } from '@app/configs/nav.config'
import { NavMenuColor } from '@app/shared/types/app-config.interface';
import { NavbarService } from './header-navbar.service';
import { Subscription } from 'rxjs';
import { NavService } from '@app/configs/nav.service';

@Component({
    selector: 'header-navbar',
    templateUrl: './header-navbar.component.html',
    host: {
        '[class.header-navbar]': 'true',
        '[class.nav-menu-light]': "color === 'light'",
        '[class.nav-menu-dark]': "color === 'dark'"
    }
})
export class HeaderNavbarComponent implements OnInit {

    menu : NavMenu[] = []
    @Input() color: NavMenuColor = 'light';
    private subscription: Subscription;

    constructor(
      private navbarService: NavbarService,
      private navService: NavService
    ) { }

    ngOnInit(): void {
        //this.menu = navConfiguration
        this.menu = this.navService.getNavbar();

        this.subscription = this.navbarService.navbar$.subscribe((newMenu) => {
          alert(JSON.stringify(newMenu))
          this.menu = newMenu;
        });
    }
    ngOnDestroy() {
      this.subscription.unsubscribe();
    }
}
