import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'error-1',
    templateUrl: './error-1.component.html'
})
export class Error1Component implements OnInit {
    constructor() { }

    ngOnInit(): void { }
}
