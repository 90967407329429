import { BrowserModule } from '@angular/platform-browser';
import { NgModule, isDevMode } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { PathLocationStrategy, LocationStrategy } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { AppConfigState } from './store/app-config/app-config.state';
import { TranslateModule } from '@ngx-translate/core';
import { AppComponent } from './app.component';
import { ReactiveFormsModule } from '@angular/forms';
//import { NgBootstrapFormValidationModule} from '@mingleats/ng-bootstrap-form-validation'
import { NgBootstrapFormValidationModule } from '@mingleats/ng-bootstrap-form-validation';

import { NgSelectModule } from '@ng-select/ng-select';
import { CheckboxModule } from '@app/shared/components/checkbox/checkbox.module';
import { FormsModule } from '@angular/forms';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { UploadModule } from '@app/shared/components/upload/upload.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from '@app/shared/interceptor/token.interceptor';

import { NgxEchartsModule } from 'ngx-echarts';

import { ProgressbarModule } from 'ngx-bootstrap/progressbar';

import { NgxSpinnerModule } from 'ngx-spinner';

import { NgxBarcodeModule } from 'ngx-ivy-barcode';

import { TimeagoModule, TimeagoIntl, TimeagoFormatter, TimeagoCustomFormatter } from 'ngx-timeago';

import { QRCodeModule } from 'angularx-qrcode';

import { AuthModule } from '@auth0/auth0-angular';

import { PopoverModule } from 'ngx-bootstrap/popover';

import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { TreeviewModule } from '@laijuthomas/ngx-treeview';

import { PaginationModule } from 'ngx-bootstrap/pagination';

import { NgxBarcode6Module } from 'ngx-barcode6';
import { ScreenSizeService } from './shared/services/screen-size.service';
import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    TranslateModule.forRoot(),
    LayoutModule,
    NgxsModule.forRoot([AppConfigState]),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot(),
    NgBootstrapFormValidationModule.forRoot(),
    ReactiveFormsModule,
    NgSelectModule,
    CheckboxModule,
    FormsModule,
    SweetAlert2Module.forRoot(),
    UploadModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ProgressbarModule.forRoot(),
    NgxEchartsModule.forRoot({
      /**
       * This will import all modules from echarts.
       * If you only need custom modules,
       * please refer to [Custom Build] section.
       */
      echarts: () => import('echarts'), // or import('./path-to-my-custom-echarts')
    }),
    BsDropdownModule.forRoot(),

    NgxSpinnerModule,

    NgxBarcodeModule.forRoot(),

    TreeviewModule.forRoot(),

    TimeagoModule.forRoot({ formatter: { provide: TimeagoFormatter, useClass: TimeagoCustomFormatter } }),

    //QRCodeModule

    // Import the module into the application, with configuration

    AuthModule.forRoot({
      domain: 'dev-w78d00wjk66chj3v.us.auth0.com',
      clientId: 'uN75EzaOYjWylyQxTKc6LoHhaThzAyGQ',
      authorizationParams: {
        redirect_uri: window.location.origin + '/login',
      },
    }),

    /*
        AuthModule.forRoot({
          domain: 'https://signin.kering.com/oauth2/default/v1',
          clientId: 'DYguPpe6Pb50PYKbQTVPBsTEmOhsMhCEJ1-2HX9FROI',
          authorizationParams: {
            redirect_uri: window.location.origin+'/login'
            //redirect_uri: 'https://gestionecampionario.globaltech.gucci/login'
          }
        }),
        */

    TimepickerModule.forRoot(),

    PopoverModule.forRoot(),

    TooltipModule.forRoot(),
    PaginationModule.forRoot(),

    NgxBarcode6Module,
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: isDevMode(),
    //   // Register the ServiceWorker as soon as the application is stable
    //   // or after 30 seconds (whichever comes first).
    //   registrationStrategy: 'registerWhenStable:30000',
    // }),
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    TimeagoIntl,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
