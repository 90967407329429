export const nav = {
    'NAV': {
        'DASHBOARD': 'Tableau de bord',
        'TASKS': 'Tasks',
        'PLANNER': 'Planner',
        'STOCK': 'Stock',
        'STOCKZONE': 'Stock Zoning',
        'CUSTOMER_CONFIG_MENU': 'Customer Config',
        'CLIENTS': 'Clients',
        'DEPARTMENTS': 'Departments',
        'LOCATIONTYPES': 'Location Types',
        'DOCUMENTTYPES': 'Document Types',
        'PRODUCTS': 'Products',
        'ADMIN_CONFIG_MENU': 'Admin Config',
        'CUSTOMERS': 'Customers',
        'USERS': 'Users',

        'MENU': 'Menu',
        'REPORT': 'Report',
        'GROUPS': 'Groups',


        'PRINTER_SETTINGS': 'Printer Settings',
        'PRINTERS_AND_TEMPLATES': 'Printers and Templates',
        'PRINTERS': 'Printers',
        'ZPL_TEMPLATES': 'ZPL Templates',

        'SECURITY': 'Security',
        'EVENTS_DASHBOARD': 'Events Dashboard',

        'EXPORT_MODULE': 'Export module',
        'ENABLE_EXPORT': 'Enable Export:',
        'CONFIGURED_EXPORT': 'Configured Exports',
        'ADD': 'Add New',
        'TYPE': 'Type',
        'STS': 'Sts',
        'EXPORT_ENABLED': 'Export Enabled',
        'EXPORT_ENABLED_SINGLE': 'Export Enabled',
        'EXPORT_TYPE': 'Export Type',
        'ADDRESS': 'Address',
        'PORT': 'Port',
        'USERNAME': 'Username',
        'PASSWORD': 'Password',
        'SERVICE_NAME': 'ServiceName/Folder',
        'FILE_TYPE': 'File Type',
        'INTERVAL': 'Interval',
        'EDIT_EXPORT': 'Edit Export',
        'SAVE': 'Save',
        'PROFILE': 'Profile',
        'SIGNOUT': 'Sign Out',

        'SIGNIN': 'Sign In',
        'SIGNIN_MESSAGE': 'Sign in your account to continue',
        'FORGET_PASSWORD': 'Forget Password?',
        'NO_ACCOUNT': 'don\'t have an account?',
        'SIGNUP': 'Sign Up',
        'EMAIL': 'Email',

        'SIGNUP_MESSAGE': 'Sign up and create a new Account!',
        'NAME_SURNAME': 'Name and Surname',
        'CONFIRM_PASSWORD': 'Confirm Password',
        'ALREADY_ACCOUNT': 'Already have an account?',

        'USER': 'User',
        'NO_CUSTOMER': 'has no Customer',
        'CONTACT_ADMIN': 'Please contact your Administrator to configure your user.',

        'SETPASSWORD': 'Set Password',
        'SETPASSWORD_MESSAGE': 'Set a Password to enable your account',

        'CHANGEEXPIREDPASSWORD': 'Password Expired',
        'CHANGEEXPIREDPASSWORD_MESSAGE': 'Your Password is Expired. Please change it to logon',

        'CHANGEPASSWORD': 'Change Password',
        'CHANGEPASSWORD_MESSAGE': 'Create a new secure password'


    },
    'APP': {
      'SELECT_CUSTOMER': 'Select Customer',
      'SELECT_CUSTOMER_TO_CONTINUE': 'Select a Customer to continue',
      'CUSTOMER': 'Customer',
      'NO_CHOOSE': 'don\'t want to choose?',
      'SIGN_OUT': 'Sign Out',
      'INCOMING': 'Incoming Tasks',
      'OUTGOING': 'Outgoing Tasks',
      'FILTER_DATA': 'Type to filter data...',
      'ALL_TASKS': 'All Tasks',
      'PAST_7': 'Past 7 Days',
      'PAST_30': 'Past 30 Days',
      'PAST_60': 'Past 60 Days',
      'PAST': 'Past',
      'DAYS': 'Days',

      'LIST_OF': 'List of',
      'CLOSED': 'Closed',
      'OPEN': 'Open',
      'TASKS': 'Tasks',
      'OPEN_TASKS': 'Open Tasks',
      'CLOSED_TASKS': 'Closed Tasks',
      'PENDING_TASKS': 'Pending Tasks',
      'COPY_TASKS': 'Copy Tasks',
      'EXCEL_EXPORT': 'Excel Export',
      'REFERENCE': 'Reference',
      'STS': 'STS',
      'DEADLINE': 'Deadline',
      'DEPARTMENT': 'Department',
      'TASK_TYPE': 'Task Type',
      'PROGRESSIVE': 'Progressive',
      'EXPECTED': 'Expected',
      'READ': 'Read',
      'ACTIONS': 'Actions',

      'CLOSE': 'Close',
      'SAVE': 'Save',
      'DOCUMENT_FILTER': 'Document Filter',
      'DESTINATION_LOCATION': 'Destination Location',
      'DESTINATION': 'Destination',
      'DEADLINE_DATE': 'Deadline Date',
      'LOCATION_FILTER': 'Location Filter',

      'WARNING': 'Warning!',
      'SELECT2': 'Please select almost 1 documents to use copy utility.',
      'SELECT1': 'Please select almost 1 row.',

      'DELETE': 'Delete',
      'CANNOT_UNDONE': 'This cannot be undone',

      'COPY_EXPECTED_DATA': 'Copy Expected Data',
      'COPY_READ_DATA': 'Copy Read Data',
      'COPY_OPTIONS': 'Copy Options',
      'CLOSE_COPIED_TASKS': 'Close Copied Tasks',
      'NEW_TASK_ATTRIBUTES': 'New Task Attributes',

      'ONLY_DIFFERENCE': 'Only Difference',
      'BACK': 'Back',
      'IMPORT_EXPECTED': 'Import Expected',
      'DELETE_SELECTED_READ': 'Delete Selected Read',
      'CLOSE_TASK': 'Close Task',
      'STATUS': 'Status',
      'PACKAGE_CODE': 'Package Code',
      'DESCRIPTION': 'Description',
      'DIFFERENCE': 'Difference',

      'DELETE_READ_FOR REFERNCE': 'Delete Read for Reference',
      'DELETE_READ_FOR REFERNCE2': 'Item still moved.<br/>Only the read difference will be deleted for Item ',
      'CONFIRM': 'Confirm',

      'CONFIRM_DELETE': 'Confirm Delete',
      'ROWS': 'Rows',
      'DELETE_SELECTED': 'Delete Selected',
      'CONFIRM_CLOSE': 'Confirm Close',
      'CLOSE_SELECTED': 'Close Selected',
      'TASK': 'Task',

      'IMPORT_TYPE': 'Import Type',
      'CHOOSE_A_FILE': 'Choose a File',

      'SEARCH': 'Search',
      'SELECT_A_TAG': 'Select a Tag',
      'SELECT_A_TAG_TO_CONTINUE': 'Select a Tag to Continue',

      'SELECT_A_DEPARTMENT': 'Select a Department',
      'SELECT_A_DEPARTMENT_TO_CONTINUE': 'Select a Department to Continue',
      'STOCK_DETAIL': 'Stock Detail',
      'LOCATION': 'Location',
      'UNITS': 'Units',

      'LIST_OF_CLIENTS': 'List of Clients',
      'SHOW_INACTIVE_CLIENTS': 'Show Inactive Clients',
      'SHOW_CUSTOMER_CLIENTS': 'Show Customer Clients',
      'NAME': 'Name',
      'ENABLED': 'Enabled',
      'DELETE_CLIENT': 'Delete Client',
      'CLIENT_ENABLED': 'Client Enabled',
      'DOCUMENT_TYPES': 'Document Types',

      'LIST_OF_DEPARTMENTS': 'List of Departments',
      'DELETE_DEPARTMENT': 'Delete Department',
      'CODE': 'Code',
      'ADD_LOCATIONS': 'Add Locations',
      'LOCATION_TYPES': 'Location Types',

      'BLACK_LIST': 'Black List',
      'DELETE_BLACK': 'Delete Black',

      'LIST_OF_CUSTOMERS': 'List of Customers',
      'EPC_ENCODE_TYPE': 'Epc Encode Type',
      'DELETE_CUSTOMER': 'Delete Customer',
      'BLACK_START': 'Black Start',
      'BLACK_END': 'Black End',

      'LIST_OF_DOCUMENT_TYPES': 'List of Task Types',
      'MASTER': 'Master',
      'CAN_OVERLOAD': 'Can Overload',
      'AS_EXPECTED': 'As Expected',
      'CAN_CHANGE_LOCATION': 'Can Change Location',
      'DELETE_TASK_TYPE': 'Delete task Type',

      'SELECT_TASKTYPE_TEMPLATE': 'Select Task Type Template',
      'SIGN': 'Sign',
      'AUTO_ACCEPT_TASK': 'Auto Accept Task',
      'CHECK_PACKAGE': 'Check Package',
      'RFID_POWER': 'RFID Power',
      'TASK_HAS_DEADLINE': 'Task has a Deadline',
      'IS_DEADLINE_EDITABLE': 'Is Deadline editable',
      'DEADLINE_DAYS': 'Deadline Days',
      'FILTERS': 'Filters',
      'STOCK_FILTER_TYPE': 'Stock Filter Type',
      'LOCATION_TYPE_FILTER': 'Location Types Filter',

      'FILE_FORMAT': 'File Format',

      'COLUMN_LIST': 'Column List',
      'SEPARATOR': 'Separator',
      'HEADER': 'File has Header',

      'IMPORT_FROM_CSV': 'Import From CSV',

      'VALUE': 'Value',
      'START': 'Start',
      'END': 'End',

      'LIST_OF_LOCATIONS': 'List of Locations',
      'LOCATION_TYPE': 'Location Type',

      'LIST_OF_LOCATION_TYPES': 'List of Location Types',
      'SELECTABLE': 'Selectable',
      'LOCATION_TYPE_IS_SELECTABLE': 'Location Type is Selectable',

      'LIST_OF_PRODUCTS': 'list of Products',
      'SHORT_DESCRIPTION': 'Short Description',
      'LONG_DESCRIPTION': 'Long Description',

      'UPLOAD': 'Upload',

      'STATUS_LIST': 'Status List',
      'DEFAULT': 'Default',
      'STATUS_TYPE': 'Status Type',
      'STATUS_WILL_BE_SET_AS_DEFAULT': 'Status will be set as Default',

      'LIST_OF_USERS': 'List of Users',
      'ROLE': 'Role',
      'DEPARTMENTS': 'Departments',

      'TASK_TYPE_DESTINATION': 'Task Type Destination',
      'TASKTYPE_INFO': 'Task Type Info',

      'MAX_SERIAL': 'Max Serial allowed',
      'CAN_ADD_PRODUCT': 'Customer can add products from Web and Mobile',
      'PRODUCT_PROPERTIES': 'Products Properties',
      'BARCODE_MASK': 'Barcode Mask',
      'REPLACE_MASK': 'Replace Mask',
      'PRODUCT_DEFAULT_DESCRIPTION': 'Default Description',


      'DEPARTMENT_STOCK': 'Department Stock',
      'GOTOSTOCK': 'Go to Stock',


      'SHOW_SERIALS': 'Show Product Serials',
      'PRODUCT_DETAILS': 'Product Serials',
      'EPC': 'Epc',

      'LOADWAREHOUSE': 'Write EPC or Match Serial add in Warehouse',


      'TASKS_TOTAL': 'Total Tasks',

      'SELECT_A_REPORT': 'Select a Report',
      'SELECT_A_REPORT_TO_CONTINUE': 'Select a Report to Continue',

      'AVG_STAY': 'Avrage Stay',
      'TREND': 'Trend',
      'STOCK': 'Stock',
      'EXPIRED': 'Expired',

      'EXPIRATION_DATE': 'Expiration Date',
      'DOC_REF': 'Task Reference',
      'ITEM_REFERENCE': 'Item Reference',
      'ARRIVAL_DATE': 'Arrival Date',

      'GROUPS': 'Groups',
      'GROUP': 'Group',

      'PRINT': 'Print',
      'PRINTABLE': 'Task is Printable',
      'ALLOW_MULTIPLE_PRINT': 'Allow Multiple Print',
      'ORDER_BY_FIELDS': 'Order By Fields',
      'EXPORT': 'Export',
      'EXPORT_TO_CUSTOMER': 'Export to Customer',

      'PRINT_SELECTED': 'Print Selected',


      'GENERATE_INVENTORY': 'Generate Inventory',
      'EXPECTED_TYPE': 'Expected Type',

      'PRINTERS': 'Printers',
      'IP': 'Ip',
      'PORT': 'Port',
      'PRINTER_TYPES': 'Printer Type',


      'LIST_OF_TEMPLATES': 'ZPL Templates',
      'BREAKPOINT_LABEL': 'Breakpoint Label',
      'ZPL': 'ZPL',

      'PRINTER': 'Printer',
      'TEMPLATE': 'ZPL Template',
      'GENERAT_ZPL_AND_DOWNLOAD': 'Generate ZPL and Download',

      'SHOW_ZERO': 'Show not in Stock Items',

      'IMAGES': 'Images',

      'UNKNOWN_IMAGE': 'Image for Unknown Items',
      'DEFAULT_IMAGE': 'Default Image',

      'UNKNOWN_TEXT_COLOR': 'Text Color for Unknown Items',
      'ENABLE_KILL': 'Enable Kill in Tag Management (Mobile)',
      'CAN_ADD_DEPARTMENT': 'Can Add Department from Mobile App',

      'GROUPBY': 'Group By',
      'GROUPBY_REF': 'Group By Reference',
      'GROUPBY_PACK': 'Group By Package Code',
      'GROUPBY_EPC': 'Group By Epc',


      'CUSTOMER_DETAIL': 'Customer Detail',
      'PERSONAL': 'Personal Data',
      'ENCODING': 'Encoding',
      'TAG_MANAGMENT': 'Tag Management',
      'PRODUCTS': 'Products',

      'SUCCESS': 'Success',
      'SAVED': 'All changes have been saved',

      'EAN13': 'Barcode',
      'COMPANY_PREFIX': 'Company Prefix Length (0 if item\'s barcode is not EAN13)',


      'SELECT_AN_EVENT': 'Select Event',
      'SELECT_AN EVENT_TO_CONTINUE': 'Select Event to Continue',
      'EPC_TIMELINE': 'EPC Timeline',

      'NO_EAS_ALARM': 'No Events to show',


      'EAS_SERVER': 'Eas Server',
      'ENABLE_SECURITY': 'Enable Security Dashboard',


      'GOTO_DASH': 'Back to Dashboard',

      'IMAGE': 'Image',
      'SECURITY_LASTHOURS': 'Show Security Alarms from last *n hours',
      'ENABLE_QUALITY': 'Download Products from Quality',
      'QUALITY_ENDPOINT': 'Quality Endpoint',
      'QUALITY_USERNAME': 'Quality Username',
      'QUALITY_PASSWORD': 'Quality Password',


      'ITEM': 'Item',
      'POE': 'Point of Exit',
      'TIMESTAMP': 'Time Stamp',


      'PASSWORD_RECOVERY': 'Password Recovery',
      'PASSWORD_RECOVERY_MESSAGE': 'Insert your Email to recover your password',


      'TOTAL': 'Totals',
      'TOTAL_READ': 'Read Totals',
      'TOTAL_EXP': 'Expected Totals',
      'TOTAL_DIFF': 'Difference Totals',

      'SEND_TO_EAS': 'Send data to EAS Server',
      'EAS_TOPIC': 'EAS Server MQTT Topic',
      'CUSTOMER_EAS_WARNING': 'All Departments data will be sent to this Topic.',



      'CLEAR_EVENTS': 'Clear All Events',
      'CLEAR': 'Clear',

      'LIST_OF_REASONS': 'List of Reasons',
      'REASON': 'Reason',
      'REASONS': 'Reasons',

      'ENABLE_MQTT_RESPONSE': 'Enable MQTT Response',


      'REQUIRED': 'Required',
      'REASON_MESSAGE': 'You need to select a Reason',

      'CUSTOMER_ROLES': 'Customer Roles',
      'ROLE_AUTH': 'Role Authorization',
      'ENABLE_WRITE': 'Enable Write/Match in Tag Management (Mobile)',
      'ENABLE_STATUS': 'Enable Change Status in Tag Management (Mobile)',
      'ENABLE_ZONE': 'Enable Change Zone in Tag Management (Mobile)',

      'ENABLE_PRINT': 'Enable Print (Web / Mobile)',
      'ENABLE_ADDTASK': 'Enable Add New Task (Web / Mobile)',
      'ENABLE_CLOSETASK': 'Enable Close Task (Web / Mobile)',
      'ENABLE_DELETETASK': 'Enable Delete Task (Web)',
      'ENABLE_COPYTASK': 'Enable Copy Task / Merge Task (Web / Mobile)',
      'ENABLE_CHANGEFILTER': 'Enable Change Filters and Options (Mobile)',

      'LOGIN_IS_MANDATORY': 'Login is Mandatory',
      'CUSTOMER_ROLE': 'Customer Role',

      'CANNOT_PERFORM_ACTION': 'Your Role cannot perform this Action',


      'DOC_REFERENCE': 'Document Reference',

      'DISABLE_PARTIAL_MOVEMENT': 'Disable Partial Movements',
      'AUTOMATIC_PARTIAL_MOVEMENT': 'Partial Movements is Automatic',


      'MOVED': 'Moved',

      'ENABLE_SCANPRINT': 'Enable ScanPrint and ScanScanWrite',

      'USE_SENDER_RECIPENT': 'Task use Sender/Recipient',

      'TYPES': 'Type',
      'TIMEZONE': 'Timezone',
      'WORKING_DEPARTMENT': 'Working Department',


      'SENDER': 'Sender',
      'RECIPIENT': 'Recipient',

      'CHECK_PACKAGE_MANDATORY': 'Check Package is Mandatory',
      'SEND_SNAPSHOT': 'Send Snapshot to ',

      'WAITING_FOR_EXPECTED': 'Waiting for Expected',

      'PENDING_MESSAGE': 'This Task is in Pending Status. It will be avaiable soon.',

      'TASK_PENDING': 'Task in Pending',


      'NOTE': 'Notes',

      'SELECTED_PRODUCTS': 'Selected Items',
      'SHOW_HIDE_PRODUCTS': 'Show / Hide Items',
      'ADVANCED_SEARCH': 'Advanced Search',


      'MODEL': 'Model',
      'PART': 'Part',
      'VARIANT': 'Variant',
      'BRAND': 'Brand',
      'GENDER': 'Gender',
      'OUTLINE': 'Outline',
      'TARGET': 'Target',
      'SEASON': 'Season',
      'MATERIAL': 'Material',
      'COLOUR': 'Colour',
      'SIZE': 'Size',

      'SHOW_OTHERS': 'Show / Hide others...',

      'CANNOT_ADD': 'Cannot Add Task from Web',
      'CANNOT_ADD_MOBILE': 'Cannot Add Task from Mobile',


      'VIEW_TYPE': 'View Type',

      'MAIN': 'Main',


      'SECONDARY_BARCODES': 'Secondary Barcodes',


      'SHOW_HIDE_MORE': 'Show/Hide more options',

      'CERT_PATH': 'Certificate Path',
      'KEY_PATH': 'Private Key Path',
      'MQTT_TYPE': 'MQTT SDK Used',

      'FROM_DATE': 'From Date',
      'TO_DATE': 'To Date',
      'DATE_RANGE': 'Date Range',

      'STARTUP_SNAPSHOT': 'Execute Snapshot on Mobile Startup',


      'ENABLE_POOLING': 'Enable Pooling',
      'POOLING_INTERVAL': 'Pooling Interval in Seconds',

      'LOCTYPE_FILTER_MANDATORY': 'Location Type Filter is Mandatory',

      'CAN_REMOVE_READ': 'Can Remove Read with RFID Reader',

      'LIST_OF_ZONES': 'Zones List',
      'ZONE_WILL_BE_SET_AS_DEFAULT': 'Zone will be set as Default',

      'BARCODE': 'Barcode',
      'ZONE': 'Zone',
      'ZONE_DETAIL': 'Zone Detail',

      'NOTE_MANDATORY': 'Notes are mandatory',
      'SHOW_TASK_DETAIL': 'Show Task Detail',
      'DOC_DATE': 'Task Date',
      'EDIT_NOTE': 'Edit Note',
      'LIST_OF_RELATIONS': 'Relations List',
      'RELATION': 'Relation',
      'ALL': 'All',

      'DEPARTMENT_TYPES': 'Department Groups',
      'DEPARTMENT_TYPE': 'Department Group',

      'DEPARTMENT_CATEGORY': 'Department Category',

      'DEPARTMENT_CATEGORIES': 'Department Categpries',
      'PREVIOUS': 'To First Category',


      'INVENTORY_ADJUSTMENT': 'Inventory Adjustment',

      'PACK_COMPLIANCE': 'Package Name must be compliance',
      'DOC_DATE_EDITABLE': 'Doc Date is Editable',

      'PASSWORD_POLICIES': 'Password Policies',
      'EXPIRATION_DAYS': 'Expiration Days',
      'FAILED_ATTEMPTS': 'Failed Attempts',
      'MIN_LENGTH': 'Min length',
      'MAX_LENGTH': 'Max length',
      'MIN_PASSWORD_AGE': 'Minimum Password Age',
      'MASK': 'Mask',

      'MUST_HAVE_SMALLCASE': 'Must contain at least 1 Letter in Small Case',
      'MUST_HAVE_CAPITALCASE': 'Must contain at least 1 in Capital Case',
      'MUST_HAVE_NUMBER': 'Must contain at least 1 number',
      'MUST_HAVE_SPECIAL_CHARACTER': 'Must contain at least 1 Special Character',


      'ENABLE_CEGID': 'Enable Cegid Features',

      'CEGID_EXPORT': 'Cegid Export',

      'ACTIVEDIRECTORY': 'Active Directory',

      'PACKAGE': 'Package Config',
      'PACKAGE_CHECK_TYPE': 'Check Type on Expected/Read Package Count',

      'INVERTED_DIFFERENCES': 'Inverted Differences on Tasks (Expected-Read --> Read-Expected)',

      'FLOW_CONFIG': 'Task Flow Config',
      'TASK_TYPE_TO_CREATE': 'New Task Type to create on Task Close',
      'CREATE_NEWDOC_WITH_ONLY_DIFF': 'Create New task with Only Difference',


      'ASKCLOSE_TASK': 'Ask for Reading from Mobile',


      'ASK4READ': 'Ask for Reading from Mobile for Task ',
      'ASK4READ_BUTTON': 'Ask for Reading',

      'SAVED_PASSWORD': 'Number of Password saved for User',


      'RESET': 'Reset Filters',

      'PLAY_SOUND': 'Play Sound on Device ',


      'VERSION': 'Release',

      'PLAN_TASK': 'Plan Task',
      'ALL_DAY': 'All Day Task',
      'TASK_DATE': 'Start Date',

      'REPEAT': 'Repeat',

      'DISABLE_SORT': 'Disable "Sort List by Power" on Mobile',
      'DISABLE_CUSEPC': 'Disable "Read only Customer EPC" on Mobile',
      'DISABLE_ENTER': 'Disable "Automatic \'Enter\' send when Regex Match" on Mobile',
      'DEFAULT_VALUE': 'Default Value',


      'SETTINGS': 'Settings',

      'UNIQUE_REFERENCE': 'Task Reference is Unique Key (Only API)',
      'DOC_EXCLUSIVE': 'Task cannot be worked from more than 1 client',
      'SEND_START': 'Send Message when enter in Task',
      'SEND_END': 'Send Message when exit from Task',


      'SHOW_STOCK': 'Show Stock in Product Detail',

      'TASK_TYPE_CATEGORY': 'Task Type Category',

      'COUNTRY': 'Country',
      'STATE': 'State',
      'DISTRICT': 'District',
      'CITY': 'City',
      'ADDRESS': 'Complete Address',

      'SHOW_PIVOT': 'Show Pivot Table',

      'SIZE_GROUPS': 'Size Groups',
      'SELECT_SIZE_GROUP': 'Select Size Group',

      'SEND_UNLOCK': 'Send Unlock',

      'EXPORT_ENVIRONMENTS': 'Export Environments',

      'EXPORT_ENVIRTMENT': 'Export Environment',

      'BUSINESS_INTELLIGENCE': 'Business Intelligence',
      'ENABLE_POWERBI_EMBEDDED': 'Enable PowerBi Embedded',
      'POWERBI_ENDPOINT': 'PowerBi Endpoint',
      'POWERBI_APIKEY': 'PowerBi ApiKey',
      'POWERBI_REPORTID': 'PowerBi ReportId',

      'DIFFERENCE_TYPE': 'Difference Type',
      'POSITIVE': 'Positive',
      'NEGATIVE': 'Negative',

      'ENABLE_COPY': 'Enable Copy',

      'GENERAL': 'General Information',
      'CONFIGURATION': 'Configuration',
      'TUNNEL': 'Tunnel Configuration',

      'ENABLE_PENDING_IMPORT': 'Enable Pending Import',

      'SELECT_MAX_REACHED': 'Could not select more than 30000 rows.',

      'SEND_COMPRESSED_REQUEST': 'Send Compressed Request when Asking External Expected',

      'ENABLE_CLOSE_PACKAGE': 'Enable Close Package',

      'SCANNER_LOCATION_MANDATORY': 'Destination Location is mandatory',

      'DOC_REF_AUTO': 'Automatic Task Reference',


      'ZONE_FILTERS': 'Zone Filters',
      'USE_ZONE': 'Task Type use Zone Filters',


      'STATUS_FILTERS': 'Status Filters',
      'USE_STATUS': 'Task Type use Status Filters',

      'ZONE_FILTER_TYPE': 'Zone Filter Type',
      'STATUS_FILTER_TYPE': 'Status Filter Type',
      'STATUS_FILTER': 'Status Filter',

      'ZONE_TYPES': 'Zone Types',

      'ZONE_TYPE': 'Zone Type',

      'SHOW_EPC': 'Show Epc',

      'GET_TASKS': 'Get Tasks',
      'DATE': 'Date',

      'ENABLE_GET_TASKS': 'Enable Get Tasks',

      'ENABLE_CONNECT_PREVIOUS_TASK': 'Enable Connect Previous Task',


      'ZONE_FILTER': 'Zone Filter',
      'EDIT_TASK': 'Edit Task',

      'ENABLE_CLOSE_FROM_SCANNER': 'Enable Close Task from Scanner View',

      'DEFAULT_STATUS': 'Default Status when Task is closed',


      'POINT_OF_SALE': 'Point of Sale',
      'POS': 'PoS',
      'ENABLE_POS': 'Enable PoS',
      'CAN_SELL': 'Can Sell',

       "USE_LOCAL_ENDPOINT": "Use Local Endpoint",
        "LOCAL_ENDPOINT": "Local Endpoint"







































    }
}
