import { NgModule } from '@angular/core';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar-portable';
import { RouterModule } from "@angular/router";
import { SharedModule } from '@app/shared/shared.module';
import { LogoModule } from '@app/shared/components/logo/logo.module';
import { NavMenuModule } from '@app/shared/components/nav-menu/nav-menu.module';
import { AvatarModule } from '@app/shared/components/avatar/avatar.module';
import { DropdownModule } from '@app/shared/components/dropdown/dropdown.module';
import { SwitchModule } from '@app/shared/components/switch/switch.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { AppLayoutComponent } from './app-layout/app-layout-component';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { HorizontalLayoutComponent } from './components/horizontal-layout/horizontal-layout.component';
import { VerticalLayoutComponent } from './components/vertical-layout/vertical-layout.component';
import { SideNavComponent } from './components/side-nav/side-nav.components';
import { HeaderNavComponent } from './components/header-nav/header-nav.component';
import { HeaderNavbarComponent } from './components/header-navbar/header-navbar.component';
import { NavToggleComponent } from './components/nav-toggle/nav-toggle.component';
import { ContentComponent } from './components/content/content.component';
import { FooterComponent } from './components/footer/footer.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { NavNotificationComponent } from './components/nav-notification/nav-notification.component';
import { NavI18NComponent } from './components/nav-i18n/nav-i18n.component';
import { NavProfileComponent } from './components/nav-profile/nav-profile.component';
import { NavConfigComponent } from './components/nav-config/nav-config.component';
import { ThemeConfiguratorComponent } from './components/theme-configurator/theme-configurator.component';
import { VerticalMenuContentComponent } from './components/vertical-menu-content/vertical-menu-content.component';
import { MobileNavComponent } from './components/mobile-nav/mobile-nav.component';
import { CustomerConfigComponent } from './components/nav-config/customer-config.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgBootstrapFormValidationModule} from '@mingleats/ng-bootstrap-form-validation'
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { QRCodeModule } from 'angularx-qrcode';
import { ServerConfigComponent } from './components/nav-config/server-config.component';
import { OktaLogoutComponent } from './components/nav-profile/okta-logout.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NavConfigPosComponent } from './components/nav-config/nav-config-pos.component';
import { PostestLayoutComponent } from './postest-layout/postest-layout.component';


const mandatoryComponents = [
    AppLayoutComponent,
    AuthLayoutComponent,
    PostestLayoutComponent,
    VerticalLayoutComponent,
    HorizontalLayoutComponent,
    SideNavComponent,
    HeaderNavComponent,
    HeaderNavbarComponent,
    NavToggleComponent,
    ContentComponent,
    FooterComponent,
    PageHeaderComponent,

    VerticalMenuContentComponent,
    MobileNavComponent,
    OktaLogoutComponent
]

const optionalComponents = [
    NavNotificationComponent,
    NavProfileComponent,
    NavI18NComponent,
    NavConfigComponent,
    NavConfigPosComponent,
    ThemeConfiguratorComponent,
    CustomerConfigComponent,
    ServerConfigComponent

]

@NgModule({
    declarations: [
        ...mandatoryComponents,
        ...optionalComponents
    ],
    imports: [
        SharedModule,
        RouterModule,
        LogoModule,
        NavMenuModule,
        AvatarModule,
        ModalModule.forRoot(),
        ButtonsModule.forRoot(),
        SwitchModule,
        DropdownModule,
        PerfectScrollbarModule,
        NgSelectModule,
       NgBootstrapFormValidationModule,

        ReactiveFormsModule,
        FormsModule,
        QRCodeModule,
        TooltipModule
    ],
    exports: [
        AppLayoutComponent,
        AuthLayoutComponent,
        PostestLayoutComponent,
        HeaderNavComponent,
        ...optionalComponents

    ],
    providers: [],
})
export class LayoutModule {}
