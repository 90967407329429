import {
    Component,
    ChangeDetectionStrategy,
    ViewEncapsulation,
    OnInit,
    Input
} from '@angular/core';
import { Router } from '@angular/router';
import { NavMenu } from '@app/shared/types/nav-menu.interface';
import { navConfiguration } from '@app/configs/nav.config'
import { NavMenuColor } from '@app/shared/types/app-config.interface';
import { Subscription } from 'rxjs';
import { NavService } from '@app/configs/nav.service';
import { NavbarService } from '../header-navbar/header-navbar.service';

@Component({
    selector: 'side-nav',
    templateUrl: './side-nav.component.html',
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class.side-nav]': 'true',
        '[class.nav-menu-collapse]': 'collapse',
        '[class.nav-menu-quick-expand]': 'quickExpand',
        '[class.nav-menu-light]': "color === 'light'",
        '[class.nav-menu-dark]': "color === 'dark'"
    }
})
export class SideNavComponent implements OnInit {
    private subscription: Subscription;

    constructor(private router: Router,
      private navbarService: NavbarService,
      private navService: NavService) { }

    menu : NavMenu[] = [];
    @Input() collapse: boolean;
    @Input() quickExpand: boolean;
    @Input() color: NavMenuColor = 'light';

    ngOnInit(): void {
        //this.menu = navConfiguration
        this.menu = this.navService.getNavbar();

        this.subscription = this.navbarService.navbar$.subscribe((newMenu) => {
          this.menu = newMenu;
        });
    }
    ngOnDestroy() {
      this.subscription.unsubscribe();
    }
}
